import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { environment } from '../../../../environments/environment';

export interface DialogData {
  tooBig: boolean;
  absMin: boolean;
}

@Component({
  selector: 'fc-dialog-site-size',
  templateUrl: './dialog-site-size.component.html',
  styleUrls: ['./dialog-site-size.component.scss'],
})
export class SiteSizeDialogComponent implements OnInit {
  title: string = null;
  body1: string = null;
  body2: string = null;

  allowSubmit = true;

  constructor(
    public dialogRef: MatDialogRef<SiteSizeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    if (this.data.absMin === undefined) {
      this.title = `You have selected ${
        this.data.tooBig ? `more than ${environment.site.maxSize}` : `less than ${environment.site.minSize}`
      } acres`;
      this.body1 = `This site is ${
        this.data.tooBig ? 'above' : 'below'
      } the recommended size for a program and may be a potential issue during review.`;
      this.body2 = `Return to the map ${
        this.data.tooBig ? 'and remove some' : 'to add additional'
      } acreage. If you'd prefer to continue the process, you may have to adjust your acreage later.`;
    } else {
      this.title = `You have selected less than ${this.data.absMin} acres`;
      this.body1 = 'Sorry; this site is below the mandatory size for a program.';
      this.body2 =
        'If you made this selection in error, you may return to the map to add additional acreage and submit again.';
      this.allowSubmit = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
