<form [formGroup]="newSiteForm" fcFormUnloadWarning>
  <div class="form-step-container">
    <div class="form-step-title">Interested in starting a carbon site?</div>
    <div class="input-label">In what county is the land you wish to enroll for carbon offsets?</div>
    <mat-form-field class="full-width">
      <input
        matInput
        type="text"
        placeholder="Type county name..."
        aria-label="county"
        [matAutocomplete]="auto"
        data-cy="county-autocomplete-input"
        formControlName="county" />
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn">
      <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
      <ng-container *ngIf="filteredCounties?.length === 0">
        <mat-option [disabled]="true" class="is-loading">County not found</mat-option>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <mat-option *ngFor="let item of filteredCounties" [value]="item" data-cy="county-autocomplete-option">
          <span>{{ item.county }} - {{ item.state }}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
    <div class="validation-error-on-blue" *ngIf="county.invalid && (county.dirty || county.touched)">
      <div *ngIf="county.errors">Please select a county from the dropdown list.</div>
    </div>

    <div class="select-container">
      <div class="main-cell1">
        <div class="input-label acreage-select">How many acres are you considering to enroll?</div>
        <mat-form-field class="full-width">
          <mat-select formControlName="acres" placeholder="Select acres..." required>
            <mat-option
              *ngFor="let option of ACREAGE_OPTIONS"
              data-cy="acres-autocomplete-option"
              [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="validation-error-on-blue" *ngIf="acres.invalid && (acres.dirty || acres.touched)">
          <div *ngIf="acres.errors.required">Acreage is required.</div>
        </div>
      </div>
    </div>

    <input formControlName="hiddenCheck" class="hidden-check" autocomplete="current-password" />

    <div class="step-button-container">
      <button
        (click)="checkAcreageAndProceed()"
        [disabled]="!newSiteForm.valid"
        color="primary"
        data-cy="next-button"
        mat-raised-button
        type="button">
        Next
      </button>
    </div>
  </div>
</form>
