import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Program } from 'src/app/shared/models/program.model';

import { environment } from '../../../environments/environment';
import { deserialize, serialize } from '../../shared/models/base-helper';

interface QueryResponse {
  results: any[];
  total: number;
}

interface DeserializedResponse {
  results: Program[];
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  apiUrl = environment.apiUrl + '/programs/';

  constructor(readonly http: HttpClient) {}

  getAllPrograms(regionId: number = null): Observable<Program[]> {
    let regionQuery = '';
    if (regionId) {
      regionQuery = `?region_id=${regionId}`;
    }
    return this.http.get<QueryResponse>(`${this.apiUrl}${regionQuery}`).pipe(
      map(response =>
        response.results
          .map((serializedProgram: any) => deserialize(serializedProgram, Program))
          .sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
      )
    );
  }

  getFilteredPrograms(filterString: string): Observable<DeserializedResponse> {
    const filterUrl = this.apiUrl + filterString;

    return this.http.get<QueryResponse>(filterUrl).pipe(
      map(response => {
        const deserializedResponse: DeserializedResponse = {
          results: [],
          total: response.total,
        };
        for (const serializedProgram of response.results) {
          deserializedResponse.results.push(deserialize(serializedProgram, Program));
        }
        return deserializedResponse;
      })
    );
  }

  getProgram(id: number): Observable<Program> {
    return this.http
      .get<any>(`${this.apiUrl}${id}`)
      .pipe(map(serializedProgram => (serializedProgram ? deserialize(serializedProgram, Program) : null)));
  }

  saveProgram(program: Program, regions: number[]): Observable<Program> {
    const body = { program: serialize(program), regions: regions || [] };
    if (program.id) {
      return this.http
        .put<any>(`${this.apiUrl}${program.id}`, body)
        .pipe(map(serializedProgram => (serializedProgram ? deserialize(serializedProgram, Program) : null)));
    } else {
      return this.http
        .post<any>(this.apiUrl, body)
        .pipe(map(serializedProgram => (serializedProgram ? deserialize(serializedProgram, Program) : null)));
    }
  }
}
