import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CreateAccountComponent } from './create-account-page/create-account.component';

import { SharedModule } from '../../shared';

@NgModule({
  declarations: [CreateAccountComponent],
  imports: [CommonModule, SharedModule, RouterModule, FormsModule, ReactiveFormsModule],
})
export class CreateAccountModule {}
