interface Option {
  value: string;
  viewValue: string;
}

interface CauseGroup {
  name: string;
  cause: Option[];
}

export const DISTURBANCE_CAUSES: CauseGroup[] = [
  {
    name: 'Natural',
    cause: [
      { value: 'wildfire', viewValue: 'Wildfire' },
      { value: 'wind-storm', viewValue: 'Wind/Storm' },
      { value: 'insect', viewValue: 'Insect' },
      { value: 'landslide', viewValue: 'Landslide' },
      { value: 'earthquake', viewValue: 'Earthquake' },
      { value: 'drought', viewValue: 'Drought' },
      { value: 'freezing-ice', viewValue: 'Freezing-Ice Storm/Damage' },
      { value: 'volcano', viewValue: 'Volcano' },
      { value: 'other', viewValue: 'Other' },
    ],
  },
  {
    name: 'Human Activity',
    cause: [
      { value: 'harvesting', viewValue: 'Harvesting' },
      { value: 'fire-arson', viewValue: 'Fire-Arson' },
      { value: 'livestock', viewValue: 'Livestock' },
    ],
  },
];

export const IMPACT_PERCENTAGES: Option[] = [
  { value: '0-25', viewValue: '0 - 25%' },
  { value: '26-50', viewValue: '26 - 50%' },
  { value: '51-75', viewValue: '51 - 75%' },
  { value: '76-100', viewValue: '76 - 100%' },
];
