<div
  class="parcel-details"
  (click)="zoomToParcel()"
  matTooltip="Click to zoom to parcel"
  matTooltipClass="fc-tooltip-map">
  <div class="parcel-svg-container" #dataContainer></div>
  <div class="parcel-info-table">
    <table aria-describedby="Parcel Info Table" role="presentation">
      <tr>
        <td>County</td>
        <td>{{ parcel.county }}</td>
      </tr>
      <tr>
        <td>State</td>
        <td>{{ parcel.state }}</td>
      </tr>
      <tr>
        <td>Parcel Number</td>
        <td>{{ parcel.parcelNumber }}</td>
      </tr>
    </table>
  </div>
</div>
