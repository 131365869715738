import { Base } from './base.model';
import { Parcel } from './parcel.model';
import { PaymentPeriod } from './payment-period.model';
import { Stand } from './stand.model';

import { Type } from '../decorators/type.decorator';

export class Version extends Base {
  public siteId: number;
  public version: number;
  public activeDate: Date;
  public inactiveDate: Date;
  @Type(Parcel) public parcels: Parcel[];
  @Type(Stand) public stands: Stand[];
  @Type(PaymentPeriod) public paymentPeriods: PaymentPeriod[];
}
