import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { DiagnosticsService } from '../../../core/api/diagnostics.service';

@Component({
  selector: 'fc-connectivity',
  templateUrl: './connectivity.component.html',
  styleUrls: ['./connectivity.component.scss'],
})
export class ConnectivityComponent implements OnInit {
  constructor(readonly diagnosticsService: DiagnosticsService) {}

  healthCheck$ = new Observable<any>();

  ngOnInit() {
    this.healthCheck$ = this.diagnosticsService.getBackendHealthcheck();
  }
}
