import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as fromApp from '../../../store';
import { getReportList } from '../../../store/report/report.actions';
import { Report, Site } from '../../models';

@Component({
  selector: 'fc-site-detail-reports',
  templateUrl: './site-detail-reports.component.html',
  styleUrls: ['./site-detail-reports.component.scss'],
})
export class SiteDetailReportsComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  reportsSubscription: Subscription;
  displayedColumns: string[] = ['created', 'name', 'view'];
  reports: Report[] = null;

  @Input() site: Site;
  @Input() isAdmin = false;

  constructor(readonly store: Store<fromApp.AppState>, private router: Router) {}

  ngOnInit() {
    this.store.dispatch(getReportList({ siteId: this.site.id }));

    //TODO: This is likely on the site already.  Confirm, but shouldn't need to re-grab.

    this.store
      .select('report', 'overview')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(overview => (this.reports = overview));
  }

  viewReport(element) {
    let reportType = element.name.toLowerCase().replace(' ', '-');
    if (reportType === 'eligibility-report') {
      reportType = 'periodic-report';
    }
    this.router.navigate([`${this.router.url}/${reportType}/${element.id}`]);
  }
}
