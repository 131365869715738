import { Base } from './base.model';
import { User } from './user.model';

import { Type } from '../decorators/type.decorator';

export class PeriodicReport extends Base {
  public siteId: number;
  public stillOwnLand: boolean;
  public inComplianceContract: boolean;
  public notInComplianceDesc: string;
  public inComplianceLaw: boolean;
  public disturbances: boolean;
  public disturbancesDesc: string;
  public activityLeakage: boolean;
  public leakageInfo: string;
  public salvageCut: boolean;
  public hasDrained: boolean;
  public reviewedDate: Date;
  public reviewedById: number;
  @Type(User) public reviewedBy: User;
  public createDate: Date;
  public feedback: string;
  public firewoodCutting: boolean;
  public isSmallClearing: boolean;
  @Type(User) public submittedBy: User;
}
