import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ErrorHelperService {
  getEmailErrorMessage(emailFormControl: UntypedFormControl | AbstractControl): string | undefined {
    let errorMessage: string = undefined;
    if (emailFormControl.hasError('pattern')) {
      errorMessage = 'Please enter a valid email address.';
    } else if (emailFormControl.hasError('maxlength')) {
      errorMessage = 'An email address can have no more than 254 characters.';
    } else if (emailFormControl.hasError('required')) {
      errorMessage = 'A valid email address is required.';
    }
    return errorMessage;
  }
}
