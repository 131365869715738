<div class="content-wrapper">
  <div class="header-wrapper w-100-1">
    <h2 class="header-title">
      @if (id) {
      {{ userType }} Account:
      {{ userFullName }}
      } @else { Account }
    </h2>
    <div class="profile-actions-container">
      @if (profileUser?.isLandowner && isSuperAdmin) {
      <button (click)="navigateSendEmail()" *ngIf="!isLimitedAdmin" color="primary" mat-stroked-button>
        Send Email
      </button>
      <button (click)="deleteUser()" color="warn" mat-raised-button>Delete User</button>
      }
    </div>
  </div>
  <div class="column">
    <div *ngIf="!editingProfile && !editingEmail; else isEditing" class="card">
      <div class="card-header">
        <span>Profile Information</span>
      </div>
      <div class="card-content">
        <div class="full-name">
          Full Name:
          <span class="value">{{ userFullName }}</span>
          <ng-container *ngIf="id && profileUser && !profileUser.isVerified; else userVerified">
            <ng-container *ngIf="profileUser.rejectedBy !== null">
              <fa-icon [icon]="['far', 'xmark']" class="red size-20" title="Is Denied"></fa-icon>
              <span class="denied">Denied</span>
            </ng-container>
            <div (click)="verifyAccount()" *ngIf="isAdmin" class="fc-link">Verify User</div>
          </ng-container>
          <ng-template #userVerified>
            <fa-icon [icon]="['fas', 'check']" class="green size-20" title="Is Verified"></fa-icon>
            <span class="verified">Verified</span>
          </ng-template>
        </div>
        <ng-container *ngIf="id && profileUser && !profileUser.isVerified">
          <div class="not-verified-warning">
            <div class="warning-text">
              <fa-icon [icon]="['far', 'triangle-exclamation']" class="orange size-20"></fa-icon>
              <span>Not Verified By Trulioo</span>
            </div>
            <span *ngIf="profileUser.truliooAttempts > 1">
              This user has previously submitted Trulioo
              {{ profileUser.truliooAttempts }} times
            </span>
          </div>
        </ng-container>
        <div>
          Company Name:
          <span class="value">{{ company }}</span>
        </div>
        <div>
          Company Title:
          <span class="value">{{ title }}</span>
        </div>
        <div>
          Email:
          <span class="value">{{ email }}</span>
        </div>
        <div>
          Address:
          <span class="value">{{ streetAddress }}</span>
        </div>
        <div>
          City:
          <span class="value">{{ city }}</span>
        </div>
        <div>
          State:
          <span class="value">{{ state }}</span>
        </div>
        <div>
          Zip:
          <span class="value">{{ zip }}</span>
        </div>
        <div>
          Phone #:
          <span class="value">
            {{ phone }}
            <ng-container *ngIf="profileUser?.mfaEnabled">
              <fa-icon [icon]="['fas', 'check']" class="green size-20" title="MFA Enabled"></fa-icon>
              <span class="mfa-enabled">MFA Enabled</span>
            </ng-container>
          </span>
        </div>
        <div *ngIf="!this.isLimitedAdmin" class="command">
          <div (click)="toggleProfileEdit()" class="fc-link" data-cy="button-edit-profile">Edit Profile</div>
          <div (click)="toggleEmailEdit()" class="fc-link">Change Email</div>
          <div
            (click)="openTrulioo()"
            *ngIf="this.isAdmin && this.profileUser && this.profileUser.truliooRecordId"
            class="fc-link">
            <fa-icon [icon]="['fas', 'arrow-up-right-from-square']" class="blue"></fa-icon>
            Trulioo Submission
          </div>
          <div *ngIf="this.isAdmin && this.profileUser && !this.profileUser.truliooRecordId" class="warning-text">
            <fa-icon [icon]="['far', 'triangle-exclamation']" class="orange size-20"></fa-icon>
            <span>No Trulioo Submission Found</span>
          </div>
        </div>
      </div>
    </div>
    <ng-template #isEditing>
      <div *ngIf="editingProfile; else isEditingEmail" class="card">
        <div class="card-header">
          <span>Profile Information</span>
        </div>
        <div class="card-content">
          <form [formGroup]="updateProfileForm" fcFormUnloadWarning>
            <input formControlName="address_id" type="hidden" />
            <mat-form-field class="w-50-2">
              <mat-label class="profile-label">First Name</mat-label>
              <input
                data-cy="profile-first-name-input"
                formControlName="firstName"
                id="firstName"
                matInput
                required
                type="text" />
              <mat-error>First Name is Required</mat-error>
            </mat-form-field>
            <mat-form-field class="w-50-2">
              <mat-label class="profile-label">Last Name</mat-label>
              <input
                data-cy="profile-last-name-input"
                formControlName="lastName"
                id="lastName"
                matInput
                required
                type="text" />
              <mat-error>Last name is required</mat-error>
            </mat-form-field>
            <mat-form-field class="w-50-2">
              <mat-label class="profile-label">Company Name</mat-label>
              <input data-cy="profile-company-name-input" formControlName="company" id="company" matInput type="text" />
            </mat-form-field>
            <mat-form-field class="w-50-2">
              <mat-label class="profile-label">Company Title</mat-label>
              <input data-cy="profile-company-title-input" formControlName="title" id="title" matInput type="text" />
            </mat-form-field>
            <mat-form-field class="w-100-1">
              <mat-label>Address 1</mat-label>
              <input
                data-cy="profile-address-1-input"
                formControlName="street"
                id="street"
                matInput
                required
                type="text" />
              <mat-error>Address 1 is required</mat-error>
            </mat-form-field>
            <mat-form-field class="w-100-1">
              <mat-label>Address 2</mat-label>
              <input data-cy="profile-address-2-input" formControlName="street2" id="street2" matInput type="text" />
            </mat-form-field>

            <mat-form-field class="w-100-1">
              <mat-label>City</mat-label>
              <input data-cy="profile-city-input" formControlName="city" id="city" matInput required type="text" />
              <mat-error>City is required</mat-error>
            </mat-form-field>

            <mat-form-field class="w-50-2">
              <mat-label class="profile-label">State</mat-label>
              <mat-select class="state-select" formControlName="state" id="state">
                <mat-option *ngFor="let state of stateList" [value]="state.value">
                  {{ state.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error>State is required</mat-error>
            </mat-form-field>

            <mat-form-field class="w-50-2">
              <mat-label class="profile-label">Zip</mat-label>
              <input
                class="profile-input"
                data-cy="profile-zip-input"
                formControlName="zip"
                id="zip"
                matInput
                maxlength="5"
                type="text" />
              <mat-error *ngIf="updateProfileForm?.controls?.zip?.errors?.required">Zip is required</mat-error>
            </mat-form-field>

            <mat-form-field [floatLabel]="getFloatLabelValue()" class="w-100-1">
              <mat-label>Phone #</mat-label>
              <input
                data-cy="profile-phone-input"
                formControlName="phone"
                id="phone"
                matInput
                placeholder="(XXX) XXX-XXXX"
                type="text" />
            </mat-form-field>
            <div class="profile-data mfa-checkbox w-100-1">
              <mat-checkbox (change)="confirmMFA($event)" data-cy="mfa-checkbox" formControlName="mfaEnabled">
                Enable Multi-Factor Authentication
              </mat-checkbox>
            </div>
          </form>
          <div class="footer-edit-buttons">
            <button
              (click)="toggleProfileEdit()"
              class="button-margin-right"
              color="primary"
              data-cy="profile-cancel-button"
              mat-stroked-button>
              Cancel
            </button>
            <button
              (click)="updateProfile()"
              [disabled]="!isUpdateProfileFormValid()"
              color="primary"
              data-cy="profile-save-button"
              mat-raised-button>
              Save
            </button>
          </div>
        </div>
      </div>
      <ng-template #isEditingEmail>
        <div class="card">
          <div class="card-header">
            <span>Profile Information</span>
          </div>
          <div class="card-content">
            <form [formGroup]="updateEmailForm" fcFormUnloadWarning>
              <mat-form-field class="w-100-1">
                <mat-label>Email</mat-label>
                <input email formControlName="email" id="email" matInput required type="text" />
                <mat-error>{{ profileEmailErrorMessage }}</mat-error>
              </mat-form-field>
            </form>
            <div class="footer-edit-buttons">
              <button (click)="toggleEmailEdit()" color="primary" mat-stroked-button>Cancel</button>
              <button (click)="updateEmail()" [disabled]="!updateEmailForm.valid" color="primary" mat-raised-button>
                Save
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-template>
    <div *ngIf="!this.isLimitedAdmin" class="card">
      <div class="card-header">Password</div>
      <div class="card-content">
        <div class="command password-command">
          <a
            *ngIf="!isAdmin; else adminPasswordReset"
            [queryParams]="{ mode: authModeEnum.changePassword }"
            [routerLink]="'/auth'"
            class="fc-link">
            Change / Reset Password
          </a>
          <ng-template #adminPasswordReset>
            <div (click)="sendAdminPasswordReset()" class="fc-link">Change / Reset Password</div>
            <div>{{ resetMessage }}</div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">Delegate</div>
      <div *ngIf="!showAddDelegate; else addingDelegate" class="card-content">
        <div>
          {{ delegateUser ? delegateUser.getDisplayName({ includeEmail: true }) : 'No Delegate Set' }}
        </div>
        <div *ngIf="!this.isLimitedAdmin && !this.isAdmin" class="command">
          <div (click)="toggleAddDelegateControls(true)" class="fc-link" data-cy="button-change-add-delegate">
            {{ delegateUser ? 'Change Delegate' : 'Add a Delegate' }}
          </div>
          <div (click)="deleteDelegate()" class="fc-link" data-cy="link-remove-delegate">
            {{ delegateUser ? 'Remove Delegate' : '' }}
          </div>
        </div>
      </div>
      <ng-template #addingDelegate>
        <div class="card-content">
          <form [formGroup]="createDelegateForm" fcFormUnloadWarning>
            <mat-form-field class="w-100-1">
              <mat-label>Delegate Email</mat-label>
              <input formControlName="delegateEmail" id="delegateEmail" matInput type="email" />
              <mat-error>{{ delegateEmailErrorMessage }}</mat-error>
            </mat-form-field>
            <mat-label>Delegate First Name</mat-label>
            <mat-form-field class="w-100-1">
              <input formControlName="delegateFirstName" id="delegateFirstName" matInput type="text" />
            </mat-form-field>
            <label>Delegate Last Name</label>
            <mat-form-field class="w-100-1">
              <input formControlName="delegateLastName" id="delegateLastName" matInput type="text" />
            </mat-form-field>
            <div class="legal-disclaimer">
              <mat-checkbox
                class="delegateTermsClicked"
                data-cy="delegate-terms-checkbox"
                formControlName="delegateTermsClicked"
                id="delegateTermsClicked">
                By adding a Delegate to your account, you expressly authorize CORE Carbon, its affiliates, and its and
                their employees, agents, and contractors to disclose information relating to your Site(s) to the
                Delegate, whether that information is contained on the CORE Carbon website or otherwise. The Delegate
                will have the ability to view all information related to your Site(s), including scheduled and past
                payments, other than bank account details (i.e., bank account number and routing number). You will be
                responsible for signing any final contracts, but the Delegate may make any changes or submissions that
                are available to you with respect to your Site(s) without your prior consent.
              </mat-checkbox>
            </div>
            <span *ngIf="error" class="small-error-text">
              Unable to add this delegate. Please contact us at
              {{ helpPhone }} or via email at
              <a href="mailto:{{ helpEmail }}" rel="noopener noreferrer" target="_blank">
                {{ helpEmail }}
              </a>
              for assistance
            </span>
            <div class="footer-edit-buttons">
              <button
                (click)="toggleAddDelegateControls(false)"
                class="button-margin-right"
                color="primary"
                data-cy="button-cancel"
                mat-stroked-button>
                Cancel
              </button>
              <button
                (click)="onDelegateSubmit()"
                [disabled]="!createDelegateForm.valid"
                color="primary"
                data-cy="button-save-delegate"
                mat-raised-button>
                Save Delegate
              </button>
            </div>
          </form>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="column">
    <div *ngIf="profileUser && profileUser.preferredBuyer" class="card">
      <div class="card-header">Preferred Buyer</div>
      <div class="card-content">
        <img
          [alt]="profileUser.preferredBuyer.name"
          [src]="getOpenAssetURL(profileUser.preferredBuyer.shortName, 'logo.svg')"
          class="buyer-logo" />
      </div>
    </div>
    <div *ngIf="!isLimitedAdmin" class="card">
      <div class="card-header">Payments</div>
      <div class="list-content">
        <ng-container *ngIf="errorMessage">
          {{ errorMessage }}
        </ng-container>
        <ng-container>
          <div class="no-sites">
            <p>
              Payments will continue provided that reporting is completed on time, disturbances are accurately recorded,
              and the property remains in good standing. All payments are securely made through Stripe™.
            </p>
            <ng-container *ngIf="notificationState && notificationState.landOwner.stripeAccountRequiresAction">
              <p class="error-text">
                Your Stripe™ account requires action. Please click the button below to resolve issue.
              </p>
            </ng-container>
            <ng-container *ngIf="sitesLoaded && !hasActiveSites">
              <p class="error-text">You don't have any active sites.</p>
            </ng-container>
            <div class="legal-disclaimer">{{ stripeDisclaimer }}</div>
          </div>
        </ng-container>
      </div>
      <div *ngFor="let account of configuredStripeAccounts">
        <div *ngIf="buyerHasActiveSites(account.buyerId)" class="buyer-group">
          <div class="stripe-account-header">
            {{ getBuyerSiteHeader(account.buyerId) }}
          </div>
          <ng-container *ngIf="isAdmin; else accountLink">
            <div class="stripe-set-up fc-link">
              <fa-icon [icon]="['fas', 'arrow-up-right-from-square']" class="blue download-icon"></fa-icon>
              <span>View Stripe™ Account with {{ getBuyerName(account.buyerId) }}</span>
            </div>
          </ng-container>
          <ng-template #accountLink>
            <div (click)="getLoginLink(account.buyerId)" class="stripe-set-up fc-link">
              <fa-icon [icon]="['fas', 'arrow-up-right-from-square']" class="blue download-icon"></fa-icon>
              <span>
                View Stripe™ Account with
                {{ getBuyerName(account.buyerId) }}
              </span>
            </div>
          </ng-template>
        </div>
      </div>
      <div *ngFor="let account of unconfiguredStripeAccounts">
        <div *ngIf="buyerHasActiveSites(account.buyerId)" class="buyer-group">
          <p class="error-text">
            Please sign up for a Stripe™ account to start receiving payments and viewing payment history.
          </p>
          <div class="stripe-account-header">
            {{ getBuyerSiteHeader(account.buyerId) }}
          </div>
          <ng-container *ngIf="isAdmin; else setupLink">
            <div class="stripe-set-up fc-link">
              <fa-icon [icon]="['fas', 'arrow-up-right-from-square']" class="blue download-icon"></fa-icon>
              <span>
                Set Up A Payment Account with
                {{ getBuyerName(account.buyerId) }}
              </span>
            </div>
          </ng-container>
          <ng-template #setupLink>
            <div (click)="doStripeConnect(account.buyerId)" class="stripe-set-up fc-link">
              <fa-icon [icon]="['fas', 'arrow-up-right-from-square']" class="blue download-icon"></fa-icon>
              <span>
                Set Up A Payment Account with
                {{ getBuyerName(account.buyerId) }}
              </span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div *ngIf="profileUser?.landowners.length > 0" class="card">
      <div class="card-header">Landowner Access</div>
      <div class="card-content">
        <div *ngFor="let landowner of profileUser.landowners">
          <div>
            Landowner:
            <span class="value">
              {{ landowner.getDisplayName({ includeEmail: true }) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isAdmin" class="card">
      <div class="card-header">Site List</div>
      <div class="card-content">
        <div class="site-list">
          <p *ngFor="let site of createdSites">
            <span class="value">
              <a href="admin/site/{{ site.id }}" rel="noopener noreferrer" target="_blank">
                {{ site.name }}
              </a>
              | {{ site.area | number: '1.0-2' }} acres |
              {{ site.status }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
