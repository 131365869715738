<mat-expansion-panel #matExpansionPanel>
  <mat-expansion-panel-header>
    <mat-panel-title>Site Notes</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-divider></mat-divider>

  <div class="note-list">
    <div *ngFor="let siteNote of siteNotes" class="site-note">
      <div class="site-note-byline">
        <span class="site-note-added-by">
          {{ siteNote.user.getDisplayName() }}
        </span>
        <span class="site-note-create-date">
          {{ siteNote.createDate | date: 'MM/dd/yyyy' }}
        </span>
        <ng-container *ngIf="currentUser && currentUser.isAdmin">
          <fa-icon
            *ngIf="siteNote.hideFromLimitedAdmins"
            [icon]="['fas', 'eye-slash']"
            class="red"
            (click)="updateVisibility(siteNote)"
            matTooltip="Click to unhide from Limited Admins."></fa-icon>
          <fa-icon
            *ngIf="!siteNote.hideFromLimitedAdmins"
            [icon]="['fas', 'eye']"
            class="red"
            (click)="updateVisibility(siteNote)"
            matTooltip="Click to hide from Limited Admins."></fa-icon>
          <fa-icon [icon]="['far', 'trash']" class="red" (click)="deleteNote(siteNote.id)"></fa-icon>
          <span>
            {{ siteNote.hideFromLimitedAdmins ? '( Hidden from Limited Admin )' : '( Visible to Limited Admin )' }}
          </span>
        </ng-container>
      </div>
      <div class="site-note-text">{{ siteNote.note }}</div>
    </div>
    <ng-container *ngIf="currentUser && currentUser.isAdmin">
      <form [formGroup]="noteForm">
        <div class="new-note">
          <mat-form-field class="full-width">
            <input matInput formControlName="note" type="text" class="new-note-text" placeholder="Write a note..." />
          </mat-form-field>
          <mat-checkbox formControlName="hideFromLimitedAdmins">Hide from Limited Admins?</mat-checkbox>
          <button color="primary" mat-raised-button type="button" (click)="createNewNote()">Submit</button>
        </div>
      </form>
    </ng-container>
  </div>
</mat-expansion-panel>
