import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from 'aws-amplify';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: environment.cognito.userPoolId,
    userPoolWebClientId: environment.cognito.clientId,
    identityPoolId: environment.cognito.identityPoolId,
  },
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
