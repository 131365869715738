<mat-drawer-container class="sidenav-container" panelClass="sidenav-container">
  <mat-drawer #sideNav class="mobile-side-nav" mode="over" position="end">
    <div (click)="sideNav?.toggle()" class="close-link">
      <fa-icon [icon]="['far', 'xmark']" class="white"></fa-icon>
    </div>
    <div class="start-site-wrapper">
      <button
        *ngIf="!inviteOnly"
        class="start-a-site-button-navbar-solid"
        color="primary"
        mat-stroked-button
        onClick="location.href='#start-a-site'">
        START A SITE
      </button>
    </div>
    <div *ngIf="isLoggedIn" class="start-site-wrapper">
      <button
        [routerLink]="'/account/dashboard'"
        class="start-a-site-button-navbar-solid"
        color="primary"
        mat-stroked-button>
        DASHBOARD
      </button>
    </div>
    <div class="login-link-wrapper">
      <a *ngIf="!isLoggedIn" class="navlinkColorSolid" routerLink="/auth">LOG IN</a>
      <a (click)="onLogOut()" *ngIf="isLoggedIn" class="navlinkColorSolid">LOG OUT</a>
    </div>
    <div class="mobile-links">
      <div (click)="sideNav?.toggle(); onClickPartnerLogo('LandYield')">PROGRAM DETAILS</div>
      <a (click)="sideNav?.toggle()" href="#learn-more">CONTACT US</a>
    </div>
  </mat-drawer>

  <div class="white-background" id="navbar">
    <img
      [ngClass]="isTestDivScrolledIntoView ? 'logo-hidden' : 'logo-visible'"
      alt="CORE Carbon"
      class="nav-logo"
      src="../../../../assets/home/core-logo.svg" />

    <div class="right-nav-links">
      <div (click)="onClickPartnerLogo('LandYield')">PROGRAM DETAILS</div>
      <a href="#learn-more">CONTACT US</a>
      <button
        *ngIf="!inviteOnly"
        class="start-a-site-button-navbar-solid hidden-for-tablet"
        data-cy="button-navbar-start-a-site"
        color="primary"
        mat-stroked-button
        onClick="location.href='#start-a-site'">
        START A SITE
      </button>
      <button
        *ngIf="isLoggedIn"
        [routerLink]="'/account/dashboard'"
        class="start-a-site-button-navbar-solid hidden-for-tablet"
        color="primary"
        mat-stroked-button>
        DASHBOARD
      </button>
      <a *ngIf="!isLoggedIn" class="navlinkColorSolid" routerLink="/auth">LOG IN</a>
      <a (click)="onLogOut()" *ngIf="isLoggedIn" class="navlinkColorSolid">LOG OUT</a>
    </div>

    <div
      #account_la="routerLinkActive"
      *ngIf="isMobile"
      [routerLinkActiveOptions]="{ exact: true }"
      class="menu-link"
      routerLinkActive>
      <a (click)="sideNav?.toggle()">
        <ng-container>
          <div class="header-link">
            <fa-icon [icon]="['fas', 'bars']"></fa-icon>
          </div>
        </ng-container>
      </a>
    </div>
  </div>

  <main (scroll)="scrollHandler()" class="main">
    <section class="section parallax parallax-one">
      <div class="parallax-one-info-container">
        <div #introducingHeading class="introduction-heading">INTRODUCING</div>
        <div class="introduction-image-wrapper">
          <img alt="CORE Carbon" class="introduction-image" src="../../../../assets/home/core-logo.svg" />
        </div>
        <div class="introduction-container">
          <div class="introduction-header">Big opportunities for landowners</div>
          <p>
            CORE Carbon
            <sup>®</sup>
            is the only platform that can provide you with a real time carbon revenue proposal for your land.
          </p>
          <p>
            If you own between 40 and 5,000 acres, you are now eligible to receive payments for managing, restoring and
            conserving your forest land. CORE Carbon streamlines carbon site development by leveraging easy-to-use
            digital mapping technologies and simple-to-use online business solutions which provides landowners with an
            immediate carbon revenue proposal for enrolled land. Credits generated are high-integrity, underpinned by a
            rigorous methodology and verified by an independent third party.
          </p>
          <p>
            CORE Carbon and LandYield have brought together carbon technology and finance expertise to help more
            landowners access carbon markets efficiently through an easy-to-use platform.
          </p>
          <p class="bold">Click the LandYield logo to learn about program details.</p>
          <div class="single partner-logo-row">
            <div class="partner-logo-wrapper" (click)="onClickPartnerLogo('LandYield')">
              <img [src]="getOpenAssetURL('landyield', 'logo.svg')" alt="LandYield" class="partner-logo" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="!inviteOnly" class="section static static-two" id="start-a-site">
      <a class="anchor"></a>
      <div class="heading">START A SITE</div>
      <div class="subheading">
        Explore your opportunities now by getting a quote to start a carbon offset site in less than five minutes.
      </div>
      <fc-new-site-step-one></fc-new-site-step-one>
    </section>
    <section class="section static static-three">
      <a class="anchor" id="learn-more"></a>
      <div class="inner-wrapper">
        <div class="title">INTERESTED IN LEARNING MORE?</div>
        <div class="text">
          If you are a landowner, consulting forester, a land trust, or a member of the media, and want to learn more
          about CORE Carbon, please contact us. We will be sure to get in touch with you shortly.
        </div>
        <div class="button">
          <button
            class="start-a-site-button-navbar-solid"
            color="primary"
            mat-raised-button
            onClick="window.open('https://www.finitecarbon.com/contact/','_blank')">
            CONTACT US
          </button>
        </div>
      </div>
    </section>
    <section class="section static static-footer">
      <div class="footer-container">
        <div class="col1">
          <img alt="core logo" height="64" src="../../../../assets/home/core-logo.svg" width="232" />
        </div>
        <div class="col2">
          <div class="footer-heading">EMAIL</div>
          <div class="footer-text">
            <a href="mailto:{{ contactEmail }}">{{ contactEmail }}</a>
          </div>
          <div class="footer-heading footer-top-spacer">TELEPHONE</div>
          <div class="footer-text">
            <a href="tel:{{ contactPhone }}">{{ contactPhone }}</a>
          </div>
        </div>
        <div class="col3">
          <div class="footer-heading">OFFICES</div>
          <div class="footer-2-col-text">
            <div class="col1-cities">
              <div class="footer-text">Wayne, PA</div>
              <div class="footer-text">Portland, OR</div>
            </div>
          </div>
        </div>
        <div class="col3 lower-section">
          <div class="left">
            <div class="footer-heading">HEADQUARTERS</div>
            <div class="footer-text footer-text-single-spaced">
              {{ contactAddressLine1 }}
            </div>
            <div class="footer-text footer-text-single-spaced">
              {{ contactAddressLine2 }}
            </div>
            <div class="footer-text">{{ contactCity }}, {{ contactState }} {{ contactZip }}</div>
          </div>
          <div class="right">
            <div class="footer-heading footer-top-spacer">
              <a href="https://www.finitecarbon.com/news/" rel="noopener noreferrer" target="_blank">NEWS</a>
            </div>
            <div class="footer-heading">
              <a routerLink="/legal/terms-and-conditions" target="_blank">TERMS AND CONDITIONS</a>
            </div>
            <div class="footer-heading">
              <a routerLink="/legal/privacy-policy" target="_blank">PRIVACY POLICY</a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-copyright">Copyright © 2022 Finite Carbon Corporation. All Rights reserved.</div>
    </section>
  </main>
</mat-drawer-container>
