import { Base } from './base.model';
import { Buyer } from './buyer.model';

import { Type } from '../decorators/type.decorator';

export class UserAdminBuyer extends Base {
  @Type(Buyer) public buyer: Buyer;
  public buyerId: number;
  public userId: number;
}
