import { Component, OnInit } from '@angular/core';

import { ToastMessage } from '../../models/toast-message.module';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'fc-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  messages: ToastMessage[] = [];

  constructor(private ts: ToastService) {}

  ngOnInit() {
    this.messages = this.ts.getMessages();
  }

  deleteMsg(timeStamp: number) {
    this.ts.removeMessage(timeStamp);
  }
}
