import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as ReportActions from './report.actions';

import { ReportService } from '../../core/api/report.service';
import { AppState } from '../index';
import * as SiteActions from '../site/site.actions';

@Injectable()
export class ReportEffects {
  submitPeriodicReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.submitPeriodicReport),
      concatMap(action => {
        return this.reportService.submitPeriodicReport(action.siteId, action.report).pipe(
          map(data => SiteActions.getAvailableSites()),
          catchError(error => of(ReportActions.submitPeriodicReportFailure({ error })))
        );
      })
    );
  });

  getPeriodicReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.getPeriodicReport),
      concatMap(action => {
        return this.reportService.getPeriodicReport(action.id).pipe(
          map(data => ReportActions.getPeriodicReportSuccess({ data })),
          catchError(error => of(ReportActions.getPeriodicReportFailure({ error })))
        );
      })
    );
  });

  submitDisturbanceReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.submitDisturbanceReport),
      concatMap(action => {
        return this.reportService.submitDisturbanceReport(action.siteId, action.report).pipe(
          map(data => SiteActions.getAvailableSites()),
          catchError(error => of(ReportActions.submitDisturbanceReportFailure({ error })))
        );
      })
    );
  });

  getDisturbanceReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.getDisturbanceReport),
      concatMap(action => {
        return this.reportService.getDisturbanceReport(action.id).pipe(
          map(data => ReportActions.getDisturbanceReportSuccess({ data })),
          catchError(error => of(ReportActions.getDisturbanceReportFailure({ error })))
        );
      })
    );
  });

  getReportList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportActions.getReportList),
      concatMap(action => {
        return this.reportService.getReportList(action.siteId).pipe(
          map(data => ReportActions.getReportListSuccess({ data })),
          catchError(error => of(ReportActions.getReportListFailure({ error })))
        );
      })
    );
  });

  constructor(readonly actions$: Actions, readonly reportService: ReportService, readonly store: Store<AppState>) {}
}
