import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { Error404Component } from './error404/error404.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { HomeComponent } from './home/home.component';

import { SharedModule } from '../../shared';

@NgModule({
  declarations: [HomeComponent, Error404Component, HelpPageComponent],
  imports: [CommonModule, SharedModule, ReactiveFormsModule],
})
export class HomeModule {}
