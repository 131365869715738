import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'fc-email-form-errors',
  template: `
    <ng-container *ngIf="emailFormControl.touched">
      <mat-error data-cy="email-validation-error" *ngIf="emailFormControl.hasError('pattern')">
        Please enter a valid email address.
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('maxlength')" data-cy="email-length-error">
        An email address can have
        <b>no more than</b>
        254 characters.
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('required')" data-cy="email-required-error">
        A valid email address is
        <b>required</b>
        .
      </mat-error>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class EmailFormErrorComponent {
  @Input() public emailFormControl: UntypedFormControl | AbstractControl;
}
