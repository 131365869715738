import { createAction, props } from '@ngrx/store';

export const loadAdmins = createAction('[Admin] Load Admins');

export const loadAdminsSuccess = createAction('[Admin] Load Admins Success', props<{ data: any }>());

export const loadAdminsFailure = createAction('[Admin] Load Admins Failure', props<{ error: any }>());

export const addRegionKMLContent = createAction('[Admin] Add Region', props<{ data: any }>());

export const addYieldCurveContent = createAction('[Admin] Add Yield Curve', props<{ data: any }>());

export const clearRegionYieldCurveContent = createAction('[Admin] Clear Region and Yield Curve Upload Content');

export const setDashboardFilter = createAction(
  '[Admin] Set Dashboard Filter Query',
  props<{ filter: string; value: string; page: string }>()
);

export const setDashboardPage = createAction(
  '[Admin] Set Dashboard Page and Offset',
  props<{ offset: number; currentPage: number; page: string }>()
);

export const clearDashboardFilters = createAction('[Admin] Clear Dashboard Filters', props<{ page: string }>());
