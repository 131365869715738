import { createAction, props } from '@ngrx/store';

export const clearAllUsers = createAction('[User] Clear All Users');

export const createUserOpenSuccess = createAction('[User] Create New User Success', props<{ data: any }>());

export const createUserOpenFailure = createAction('[User] Create New User Failure', props<{ error: any }>());

export const updateUserProfile = createAction(
  '[User] Update User Profile',
  props<{ data: any; setCurrentUser: boolean }>()
);

export const updateUserProfileSuccess = createAction('[User] Update User Profile Success', props<{ data: any }>());

export const updateUserProfileFailure = createAction('[User] Update User Profile Failure', props<{ error: any }>());

export const createDelegate = createAction('[User] Create New Delegate User', props<{ data: any }>());

export const createDelegateSuccess = createAction('[User] Create New User Delegate Success', props<{ data: any }>());

export const createDelegateFailure = createAction('[User] Create New User Delegate Failure', props<{ error: any }>());

export const removeDelegate = createAction('[User] Remove Delegate User');

export const removeDelegateSuccess = createAction('[User] Remove Delegate User Success', props<{ data: any }>());

export const removeDelegateFailure = createAction('[User] Remove Delegate UserFailure', props<{ error: any }>());

export const getUsers = createAction('[User] Get All Users');

export const getUsersSuccess = createAction('[User] Get All Users Success', props<{ data: any }>());

export const getUsersFailure = createAction('[User] Get All Users Failure', props<{ error: any }>());

export const setUserRole = createAction('[User] Set A User Role', props<{ userId: number; role: string }>());

export const setUserRoleSuccess = createAction('[User] Set A User Role Success', props<{ data: any }>());

export const setUserRoleFailure = createAction('[User] Set A User Role Failure', props<{ error: any }>());

export const verifyUser = createAction(
  '[User] Manually Verify A User',
  props<{ userId: number; verified: boolean; clear: boolean }>()
);

export const verifyUserSuccess = createAction('[User] Manually Verify A User Success', props<{ data: any }>());

export const verifyUserFailure = createAction('[User] Manually Verify A User Failure', props<{ error: any }>());
