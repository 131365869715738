<div mat-dialog-title>
  <div>CHANGE OWNER</div>
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p>
    Current Owner:
    <span class="current-owner">{{ data.currentOwner.getDisplayName() }}</span>
  </p>
  <form [formGroup]="changeOwnerForm">
    <span class="change-to">Change to:</span>
    <mat-form-field class="change-owner-select">
      <input type="text" placeholder="New Owner" matInput [formControl]="newOwner" [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayUserName">
        <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
          {{ user.getDisplayName({ includeEmail: true }) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button data-cy="button-cancel" mat-stroked-button color="primary" [mat-dialog-close]="false">Cancel</button>
  <button
    data-cy="button-submit"
    mat-raised-button
    color="primary"
    [disabled]="!changeOwnerForm.valid"
    (click)="onSubmit()">
    Submit
  </button>
</div>
