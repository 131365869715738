import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { forkJoin, of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import { selectAuthState } from './auth.selectors';

import { AccountService } from '../../core/api/account.service';
import { UserService } from '../../core/api/user.service';
import { RoleEnum } from '../../shared/constants/role.enums';
import * as BuyerActions from '../buyer/buyer.actions';
import * as DocumentActions from '../document/document.actions';
import { AppState } from '../index';
import * as ParcelActions from '../parcel/parcel.actions';
import * as ReportActions from '../report/report.actions';
import * as SiteActions from '../site/site.actions';
import * as UserActions from '../user/user.actions';

@Injectable()
export class AuthEffects {
  constructor(
    readonly actions$: Actions,
    readonly userService: UserService,
    readonly accountService: AccountService,
    readonly router: Router,
    readonly store: Store<AppState>
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      withLatestFrom(this.store.select(selectAuthState)),
      switchMap(([action, authState]) => {
        return forkJoin([of(authState), this.userService.getMe(), this.accountService.getMyAccount()]);
      }),
      switchMap(([authState, userMe, myAccount]) => {
        const actions: any[] = [
          AuthActions.loginSuccess({
            user: userMe,
            returnUrl: authState.returnUrl,
            myAccount,
          }),
          BuyerActions.clearPreferredBuyer(),
        ];
        return actions;
      }),
      catchError(error => {
        return of(AuthActions.loginFailure({ error }));
      })
    );
  });

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(action => {
          const currentUser = action.user;
          if (action.returnUrl) {
            // Extra logic to force admin to admin routes in case they come in on the '/' route
            if (currentUser.isAdmin) {
              if (action.returnUrl.startsWith('admin') || action.returnUrl.startsWith('/admin')) {
                this.router.navigateByUrl(action.returnUrl, {
                  replaceUrl: true,
                });
              } else {
                this.router.navigateByUrl('/admin/dashboard', {
                  replaceUrl: true,
                });
              }
            } else if (currentUser.hasRole(RoleEnum.Landowner) && !action.returnUrl.startsWith('/account/profile')) {
              this.router.navigateByUrl(action.returnUrl, { replaceUrl: true });
            }
          } else if (currentUser.isAdmin || currentUser.isLimitedAdmin) {
            this.router.navigateByUrl('/admin/dashboard', { replaceUrl: true });
          } else if (currentUser.needsTruliooVerification) {
            this.router.navigateByUrl('/account/verify', { replaceUrl: true });
          } else if (currentUser.hasRole(RoleEnum.Landowner)) {
            this.router.navigateByUrl('/account/dashboard', {
              replaceUrl: true,
            });
          } else {
            throw new Error('User was not assigned a role');
          }
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(action => {
        Auth.signOut();

        // required for when the user is on the change password page and the login screen needs to refresh
        this.router.navigate(['/']).then(() => {
          this.router.navigateByUrl('/auth', {
            replaceUrl: true,
          });
        });

        return [
          AuthActions.clearAuthData(),
          SiteActions.clearActiveSite(),
          SiteActions.clearAllSites(),
          DocumentActions.clearAllDocument(),
          ReportActions.clearAllReports(),
          UserActions.clearAllUsers(),
          ParcelActions.clearAll(),
        ];
      })
    )
  );
}
