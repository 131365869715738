import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isString } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

import { UserService } from '../../../core/api/user.service';
import { UtilitiesService } from '../../../core/api/utlities.service';
import { AdminTableBase } from '../../../feature/admin/admin-table-base/admin-table-base';
import * as fromApp from '../../../store';
import { setDashboardFilter } from '../../../store/admin/admin.actions';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { User } from '../../models';
import { UserActivity } from '../../models/user-activity.model';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
  selector: 'fc-user-activity-dashboard',
  templateUrl: './user-activity-dashboard.component.html',
  styleUrls: ['./user-activity-dashboard.component.scss'],
})
export class UserActivityDashboardComponent extends AdminTableBase implements OnInit {
  destroyRef = inject(DestroyRef);

  applyFilterStatus$: Subject<{ event: Event; column: string }> = new Subject();
  usersList: User[] = [];
  selectedUser = new UntypedFormControl();
  filteredUsers: Observable<User[]>;
  selectUserForm: UntypedFormGroup;

  eventTypeFilter = [
    'Log In Success',
    'Log In Failure',
    'Password Changed',
    'Requesting Password Reset',
    'Email Sent',
    'Trulioo Attempt',
    'Profile Updated',
    'Payment Made',
    'Payment Received',
    'ERT Adjustment',
    'Payment Adjustment',
  ];
  readonly PAGE_NAME: string = 'user-activity';

  filterValues = {
    event: '',
    user_id: '',
  };
  displayedColumns: string[] = ['userName', 'event', 'eventDetail', 'eventTime'];
  sortBy = {
    event_time: this.sortStates.untouched,
  };
  dataSource: MatTableDataSource<UserActivity>;
  defaultSortQuery = '&sort=event_time(desc)';
  sortQuery = this.defaultSortQuery;
  constructor(
    readonly store: Store<fromApp.AppState>,
    readonly route: ActivatedRoute,
    readonly router: Router,
    readonly userService: UserService,
    readonly spinnerService: SpinnerService,
    readonly utilitiesService: UtilitiesService
  ) {
    super();
  }

  ngOnInit() {
    this.initializeFilters();
    this.applyFilterStatus$
      .pipe(debounceTime(this.FILTER_DELAY), takeUntilDestroyed(this.destroyRef))
      .subscribe((eventObj: { event: any; column: string }) => {
        this.filterByEvent(eventObj.event.value);
      });

    this.selectUserForm = new UntypedFormGroup({
      selectedUser: this.selectedUser,
    });
    this.store
      .select(selectCurrentUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(currentUser => {
        if (currentUser) {
          if (currentUser.isLandowner) {
            // Remove UserName for Landowner view
            this.displayedColumns.splice(0, 1);
          } else {
            this.userService.getUsers().subscribe(users => {
              this.usersList = users;

              this.filteredUsers = this.selectedUser.valueChanges.pipe(
                startWith(''),
                map((value: string) => this._filter(value))
              );
            });
          }
          this.queryItems();
        }
      });
  }

  clearFilters(): void {
    if (this.getBaseQueryString() !== this.getFullQueryString()) {
      super.clearFilters();
      this.filterValues = {
        event: '',
        user_id: '',
      };
      this.selectedUser.setValue('');
      this.queryItems();
    }
  }
  private _filter(value: string | User): User[] {
    let filterValue = '';
    if (isString(value)) {
      filterValue = String(value).toLowerCase();
    } else {
      filterValue = new User(value).getDisplayName();
    }

    return this.usersList.filter(user => {
      return (
        user.getDisplayName().toLowerCase().includes(filterValue) || user.email.toLowerCase().includes(filterValue)
      );
    });
  }
  getFullQueryString(): string {
    return this.getBaseQueryString() + this.sortQuery + this.filterQuery;
  }
  queryItems(): void {
    this.spinnerService.show('activity-spinner');
    this.userService.getUserActivity(this.getFullQueryString()).subscribe(activityResults => {
      this.dataSource = new MatTableDataSource(activityResults.results);
      this.totalItems = activityResults.total;
      this.setAllPages();
      this.setStartEndIndexes();
      this.spinnerService.hide('activity-spinner');
    });
  }

  private filterByEvent(value) {
    if (value) {
      // must encode value if we want to support special characters like ; # &
      const filterValue: string = this.utilitiesService.preprocessFilterString(value);
      this.filterQuery = filterValue.length !== 0 ? `&event=${filterValue}` : '';
    } else {
      this.filterQuery = '';
    }

    this.store.dispatch(
      setDashboardFilter({
        filter: 'filterQuery',
        value: this.filterQuery,
        page: this.PAGE_NAME,
      })
    );

    this.resetOffsetAndCurrentPage();
    this.queryItems();
  }
}
