import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import * as ReportActions from './report.actions';

export const reportFeatureKey = 'report';

export interface ReportState {
  periodicReports: any;
  disturbanceReports: any;
  overview: any;
}

export const initialState: ReportState = {
  periodicReports: null,
  disturbanceReports: null,
  overview: null,
};

const reportReducer = createReducer(
  initialState,
  on(ReportActions.clearAllReports, state => ({
    ...state,
    periodicReports: null,
    disturbanceReports: null,
    overview: null,
  })),
  on(ReportActions.getReportList, state => ({
    ...state,
    overview: null,
  })),
  on(ReportActions.getReportListSuccess, (state, data) => ({
    ...state,
    overview: data.data,
  })),
  on(ReportActions.getDisturbanceReportSuccess, (state, data) => {
    const disturbanceReports = cloneDeep(state.disturbanceReports || []);
    const idx = disturbanceReports.findIndex(r => r.id === data.data.id);
    if (idx > -1) {
      disturbanceReports[idx] = data.data;
    } else {
      disturbanceReports.push(data.data);
    }
    return {
      ...state,
      disturbanceReports,
    };
  }),
  on(ReportActions.getPeriodicReportSuccess, (state, data) => {
    const periodicReports = cloneDeep(state.periodicReports || []);
    const idx = periodicReports.findIndex(r => r.id === data.data.id);
    if (idx > -1) {
      periodicReports[idx] = data.data;
    } else {
      periodicReports.push(data.data);
    }
    return {
      ...state,
      periodicReports,
    };
  }),
  on(
    ReportActions.getDisturbanceReportFailure,
    ReportActions.getPeriodicReportFailure,
    ReportActions.getReportListFailure,
    (state, data) => {
      let error = 'There was an error';
      if (data.error) {
        if (data.error.error && data.error.error.detail) {
          error = data.error.error.detail;
        } else if (data.error.message) {
          error = data.error.message;
        }
      }
      return {
        ...state,
        error,
      };
    }
  )
);

export function reducer(state: ReportState | undefined, action: Action) {
  return reportReducer(state, action);
}
