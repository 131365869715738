import { Base } from './base.model';
import { User } from './user.model';

import { Type } from '../decorators/type.decorator';

export class SiteNote extends Base {
  public siteId: number;
  public userId: number;
  @Type(User) public user?: User;
  public note: string;
  hideFromLimitedAdmins: boolean;
  public createDate?: Date;
}
