export enum StatusEnum {
  // Note: These are just constants that are used in the frontend code
  // All status values are stored in the database as strings
  // This list DOES NOT include all possible status values
  UNSUBMITTED = 'Unsubmitted',
  REQUIRES_LANDOWNER_REVISION = 'Requires Landowner Revision',
  CONTRACT_REJECTED = 'Contract Rejected',
  APPROVED = 'Approved',
  APPROVED_CONDITIONALLY = 'Approved Conditionally',
  CONTRACT_SENT = 'Contract Sent',
  CONTRACT_SIGNED = 'Contract Signed',
  DOCUMENT_REQUIRED = 'Document Required',
  ACTIVE = 'Active',
  REPORTING_REQUIRED = 'Reporting Required',
  DISTURBANCE_REPORTED = 'Disturbance Reported',
  PAYMENT_READY = 'Payment Ready',
  UNDER_REVIEW = 'Under Review',
  REQUIRES_LANDOWNER_APPROVAL = 'Requires Landowner Approval',
  LANDOWNER_CONTRACT_SIGNED = 'Landowner Signed Contract',
}
