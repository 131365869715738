import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { debounceTime, filter, finalize, switchMap, tap } from 'rxjs/operators';

import { CohortService } from '../../../core/api/cohort.service';
import { ConstantsService } from '../../../core/api/constants.service';
import { CountyInfo, GisService } from '../../../core/api/gis.service';
import { FormatHelperService } from '../../../core/helper/format-helper.service';
import * as fromApp from '../../../store';
import { selectAppConfigState, selectIsMobileView } from '../../../store/app-config/app-config.selectors';
import { selectActAsUser, selectCurrentUser } from '../../../store/auth/auth.selectors';
import { setPreferredBuyer } from '../../../store/buyer/buyer.actions';
import { selectPreferredBuyer } from '../../../store/buyer/buyer.selectors';
import { setStepOne } from '../../../store/parcel/parcel.actions';
import { DialogCountyAvailabilityComponent } from '../../dialogs/dialog-county-availability/dialog-county-availability.component';
import { DialogPremiumAcreageComponent } from '../../dialogs/dialog-premium-acreage/dialog-premium-acreage.component';
import { Buyer, Cohort, User } from '../../models';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
  selector: 'fc-new-site-step-one',
  templateUrl: './new-site-step-one.component.html',
  styleUrls: ['./new-site-step-one.component.scss'],
})
export class NewSiteStepOneComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  newSiteForm: UntypedFormGroup;

  isLoading = false;
  loggedIn = false;
  isMobile = false;
  preferredBuyer: Buyer = null;
  currentUser: User = null;
  filteredCounties: Array<CountyInfo>;
  estimatePrice = null;

  readonly ACREAGE_OPTIONS = ConstantsService.ACREAGE_OPTIONS;

  constructor(
    public dialog: MatDialog,
    protected route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    readonly spinnerService: SpinnerService,
    readonly formatHelper: FormatHelperService,
    readonly store: Store<fromApp.AppState>,
    readonly gisService: GisService,
    readonly cohortService: CohortService
  ) {
    this.createNewSiteForm();
  }

  ngOnInit() {
    this.store
      .select(selectAppConfigState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(appConfig => {
        this.estimatePrice = JSON.parse(appConfig.config['estimate_price']);
      });
    if (this.route.snapshot.queryParamMap.get('referrer_code')) {
      this.store.dispatch(
        setPreferredBuyer({
          referrerCode: parseInt(this.route.snapshot.queryParamMap.get('referrer_code')),
        })
      );

      this.router.navigate([], {
        queryParams: {
          referrer_code: null,
        },
        queryParamsHandling: 'merge',
      });
    }

    this.store
      .select(selectIsMobileView)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(mobileViewMode => {
        this.isMobile = mobileViewMode;
      });

    this.store
      .select(selectPreferredBuyer)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(buyer => {
        this.preferredBuyer = buyer;
      });

    this.store
      .select(selectActAsUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(actAsUser => {
        if (actAsUser && actAsUser.email && this.newSiteForm.get('email')) {
          this.newSiteForm.get('email').setValue(actAsUser.email);
          this.newSiteForm.get('email').disable();
        }
      });

    this.store
      .select(selectCurrentUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(currentUser => {
        this.loggedIn = currentUser !== null;
        if (this.loggedIn) {
          this.currentUser = currentUser;
          if (this.currentUser && this.currentUser.preferredBuyer) {
            this.preferredBuyer = this.currentUser.preferredBuyer;
          }
        }
      });

    this.newSiteForm
      .get('county')
      .valueChanges.pipe(
        debounceTime(500),
        filter(value => value.length >= 2),
        tap(() => {
          this.filteredCounties = [];
          this.isLoading = true;
        }),
        switchMap(value =>
          this.gisService.getCountyFilteredList(value, 50).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((data: Array<CountyInfo>) => {
        if (data) {
          this.filteredCounties = data;
        } else {
          this.filteredCounties = [];
        }
      });
  }

  createNewSiteForm(): void {
    this.newSiteForm = this.formBuilder.group({
      county: ['', [Validators.required, this.isCountyObjectValidator()]],
      acres: ['', Validators.required],
      hiddenCheck: null,
    });
  }

  checkAcreageAndProceed(): void {
    const county_string = this.newSiteForm.get('county').value.county;
    const state_string = this.newSiteForm.get('county').value.state;
    const acres = this.newSiteForm.get('acres').value;
    if (this.newSiteForm.value.acres === this.ACREAGE_OPTIONS[3].value) {
      this.dialog.open(DialogPremiumAcreageComponent, {
        panelClass: 'fc-premium-acreage-dialog',
        data: {
          referrerCode: this.preferredBuyer ? this.preferredBuyer.referrerCode : null,
          county: county_string,
          state: state_string,
        },
      });
    } else {
      this.spinnerService.show('app-spinner');
      this.cohortService
        .getCohortsByCounty(this.newSiteForm.get('county').value.gid)
        .pipe(
          finalize(() => this.spinnerService.hide('app-spinner')),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((cohorts: Cohort[] | null) => {
          if (cohorts && cohorts.length > 0) {
            this.newSiteForm.markAsPristine();
            if (this.loggedIn) {
              this.store.dispatch(setStepOne({ data: { email: this.currentUser.email, acres } }));
              this.router.navigate(['/site/new-site/step-one-and-a-half']);
            } else {
              let priceString = null;
              if (this.estimatePrice && this.estimatePrice.length > 0) {
                let countyAverage = this.newSiteForm.get('county').value.averageErt;
                let prices = [];
                prices.push(this.estimatePrice[0] * countyAverage);
                if (this.estimatePrice[1]) {
                  prices.push(this.estimatePrice[1] * countyAverage);
                }
                priceString = this.formatHelper.formatMinMaxValuation(prices);
              }
              this.dialog
                .open(DialogCountyAvailabilityComponent, {
                  panelClass: 'fc-dialog-county-availability',
                  data: {
                    priceString: priceString,
                    goodNews: true,
                    referrerCode: this.preferredBuyer ? this.preferredBuyer.referrerCode : null,
                    size: this.newSiteForm.value.acres,
                  },
                })
                .afterClosed()
                .subscribe(result => {
                  if (result) {
                    this.router.navigate(['/create-account'], {
                      queryParams: {
                        username: result,
                      },
                    });
                  }
                });
            }
          } else {
            this.dialog.open(DialogCountyAvailabilityComponent, {
              panelClass: 'fc-dialog-county-availability',
              data: {
                goodNews: false,
                referrerCode: this.preferredBuyer ? this.preferredBuyer.referrerCode : null,
                size: this.newSiteForm.value.acres,
                county: county_string,
                state: state_string,
              },
            });
          }
        });
    }
  }

  displayFn = (item: CountyInfo): string => {
    return item ? item.county + ' - ' + item.state : '';
  };

  isCountyObjectValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      typeof control.value !== 'string'
        ? null
        : {
            isCounty: { value: control.value },
          };
  }

  // Form getters
  get email(): AbstractControl {
    return this.newSiteForm.get('email');
  }

  get acres(): AbstractControl {
    return this.newSiteForm.get('acres');
  }

  get county(): AbstractControl {
    return this.newSiteForm.get('county');
  }

  get isFormDirty(): boolean {
    return this.acres.dirty || this.county.dirty;
  }
}
