import { createAction, props } from '@ngrx/store';

import { Account, User } from '../../shared/models';

export const setToken = createAction('[Auth] Set Token', props<{ idToken: string; exp: number | null }>());

export const setCurrentUser = createAction('[Auth] Set Current User', props<{ data: User }>());

export const setActAsUser = createAction('[Auth] Set Act As User', props<{ data: User }>());

export const setCurrentUserAndAccount = createAction(
  '[Auth] Set Current User And Account',
  props<{ data: { user: User; account: Account } }>()
);

export const setCurrentAccount = createAction('[Auth] Set Current Account', props<{ data: Account }>());

export const setReturnUrl = createAction('[Auth] SetReturnUrl', props<{ data: any }>());

export const clearAuthData = createAction('[Auth] Clear Auth Data');

export const login = createAction('[Auth] Login User', props<{ data: any }>());

export const loginSuccess = createAction(
  '[Auth] Login User Success',
  props<{ user: User; returnUrl: string; myAccount: Account }>()
);

export const loginFailure = createAction('[Auth] Login User Failure', props<{ error: any }>());

export const logout = createAction('[Auth] Logout User');

export const setDelegate = createAction('[Auth] Set setDelegate', props<{ data: User }>());
