import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ErrorHelperService } from 'src/app/core/helper/error-helper.service';

import { RegexConstants } from '../../constants/regex.constants';

export interface DialogData {
  email: string;
}

@Component({
  selector: 'fc-dialog-coming-soon',
  templateUrl: './dialog-coming-soon.component.html',
  styleUrls: ['./dialog-coming-soon.component.scss'],
})
export class DialogComingSoonComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  comingSoonForm: UntypedFormGroup;
  emailError: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogComingSoonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: UntypedFormBuilder,
    readonly errorHelperService: ErrorHelperService
  ) {}

  ngOnInit() {
    this.comingSoonForm = this.formBuilder.group({
      email: [null, [Validators.maxLength(254), Validators.pattern(RegexConstants.EMAIL), Validators.required]],
      hiddenCheck: null,
    });
    this.comingSoonForm.get('email').setValue(this.data.email);

    this.comingSoonForm
      .get('email')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.emailError = this.errorHelperService.getEmailErrorMessage(this.email);
      });
  }

  onNoClick(): void {
    this.dialogRef.close({ email: null, hiddenCheck: null });
  }

  get email(): AbstractControl {
    return this.comingSoonForm.get('email');
  }
}
