import { Base } from './base.model';
import { User } from './user.model';

import { Type } from '../decorators/type.decorator';

export class Document extends Base {
  public siteId: number;
  public documentTypeId: number;
  public name: string;
  public identifier: string;
  public description: string;
  public userId: number;
  @Type(User) public user: User;
  public createDate: Date;
  public comments: string;
}
