import { Base } from './base.model';

export class StripeAccount extends Base {
  public stripeUserIdentifier: string;
  public stripeAccountCreationDate: Date;
  public isStripeAccountIssue: boolean;
  public stripeAccountCreationToken: string;
  public userId: number;
  public buyerId: number;
}
