import { Base } from './base.model';
import { User } from './user.model';

import { Type } from '../decorators/type.decorator';

export class SiteReviewCheck extends Base {
  public status?: string;
  public type?: string;
  public siteId?: number;
  public updatedAt?: string;
  @Type(User) public updatedBy?: User;
}
