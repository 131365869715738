import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  public static FOREST_TYPE_UNKNOWN = 0;
  public static FOREST_TYPE_HARDWOOD = 1;
  public static FOREST_TYPE_MOSTLY_HARD = 2;
  public static FOREST_TYPE_MOSTLY_SOFT = 3;
  public static FOREST_TYPE_SOFTWOOD = 4;

  public static FOREST_TYPE = [
    { id: 0, longName: 'Unknown', shortName: 'N/A' },
    { id: 1, longName: 'Hardwood', shortName: 'Hardwood' },
    { id: 2, longName: 'Mixed / Mostly Hardwood', shortName: 'Mixed / HW' },
    { id: 3, longName: 'Mixed / Mostly Softwood', shortName: 'Mixed / SW' },
    { id: 4, longName: 'Softwood', shortName: 'Softwood' },
  ];

  public static ACREAGE_OPTIONS = [
    { value: '40-500', label: '40-500 acres' },
    { value: '500-2000', label: '500-2000 acres' },
    { value: '2000-5000', label: '2000-5000 acres' },
    { value: '5000+', label: 'More than 5000 acres' },
  ];

  public static MIN_STAND_ACRES = 1;
}
