import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';

import * as fromApp from '../../../store';
import { selectAppConfigState } from '../../../store/app-config/app-config.selectors';

@Component({
  selector: 'fc-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss'],
})
export class HelpPageComponent {
  destroyRef = inject(DestroyRef);
  contactEmail: string = '';
  constructor(readonly store: Store<fromApp.AppState>) {
    this.store
      .select(selectAppConfigState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(appConfig => {
        this.contactEmail = appConfig.config['fc_contact_email'];
      });
  }
}
