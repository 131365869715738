<div mat-dialog-title>
  <div>Verify One-Time Password</div>
  <div (click)="closeDialog()" class="close-icon-container">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<form [formGroup]="otpForm" class="body">
  <p class="p5">
    A text with a 6-digit one-time passcode should have been sent to the
    {{ mode === 'verify-email' ? 'email' : 'phone number' }}
    that we have on file for you. Please enter the code below to verify your identity.
  </p>
  <mat-form-field class="otp-input">
    <mat-label>Verification Code</mat-label>
    <input formControlName="otp" matInput maxlength="6" placeholder="Verification Code" regexFilter="\d" type="text" />
  </mat-form-field>
  <mat-error *ngIf="errorMessage && errorMessage !== ''">
    {{ errorMessage }}
  </mat-error>
  <p *ngIf="mode === 'verify-email'">
    If you did not receive an email with a passcode, please wait up to a minute, check your spam folder, then click
    <a (click)="sendOtp()" class="link">here</a>
    to resend.
  </p>
  <p *ngIf="mode !== 'verify-email'">
    If you did not receive a text message with a passcode, please wait up to a minute, then click
    <a (click)="sendOtp()" class="link">here</a>
    to resend.
  </p>
</form>
<div class="buttons">
  <button (click)="closeDialog()" color="primary" data-cy="button-cancel-dialog" mat-stroked-button>Cancel</button>
  <button
    (click)="submitOtp()"
    [disabled]="otpForm.invalid || !verificationCodeSent"
    color="primary"
    data-cy="button-confirm-dialog"
    mat-flat-button>
    Submit
  </button>
</div>
