import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import * as AdminActions from './admin.actions';

import { YieldCurve } from '../../shared/models';

export const adminFeatureKey = 'admin';

export interface AdminState {
  regionUpload: {
    kmlGeoJSON: string;
    fileName: string;
  };
  yieldCurveUpload: {
    yieldCurveData: Array<YieldCurve>;
    fileName: string;
  };
  pageFilters: [
    {
      page: string;
      filters: {
        filterQuery: string;
        ownerQuery: string;
        delegateQuery: string;
        statusQuery: string;
        showTerminatedQuery: string;
        showAssignedToMeQuery: string;
        sortQuery: string;
        regionQuery: string;
        roleQuery: string;
        verifiedQuery: string;
        fullNameQuery: string;
        assignedAdminQuery: string;
      };
      offset: number;
      currentPage: number;
    }
  ];
}

export const initialState: AdminState = {
  regionUpload: {
    kmlGeoJSON: null,
    fileName: null,
  },
  yieldCurveUpload: {
    yieldCurveData: null,
    fileName: null,
  },
  pageFilters: [
    {
      page: null,
      filters: {
        filterQuery: null,
        ownerQuery: null,
        delegateQuery: null,
        statusQuery: null,
        showTerminatedQuery: null,
        showAssignedToMeQuery: null,
        sortQuery: null,
        regionQuery: null,
        roleQuery: null,
        verifiedQuery: null,
        fullNameQuery: null,
        assignedAdminQuery: null,
      },
      offset: 0,
      currentPage: 1,
    },
  ],
};

const adminReducer = createReducer(
  initialState,

  on(AdminActions.loadAdmins, state => state),
  on(AdminActions.loadAdminsSuccess, (state, action) => state),
  on(AdminActions.loadAdminsFailure, (state, action) => state),
  on(AdminActions.addRegionKMLContent, (state, geoJSONContent) => ({
    ...state,
    regionUpload: {
      ...state.regionUpload,
      fileName: geoJSONContent.data.fileName,
      kmlGeoJSON: geoJSONContent.data.kmlGeoJSON,
    },
  })),
  on(AdminActions.addYieldCurveContent, (state, yieldCurveData) => ({
    ...state,
    yieldCurveUpload: {
      ...state.yieldCurveUpload,
      fileName: yieldCurveData.data.fileName,
      yieldCurveData: yieldCurveData.data.yieldCurveData,
    },
  })),
  on(AdminActions.clearRegionYieldCurveContent, (state, geoJSONContent) => ({
    ...state,
    regionUpload: {
      fileName: null,
      kmlGeoJSON: null,
    },
    yieldCurveUpload: {
      fileName: null,
      yieldCurveData: null,
    },
  })),
  on(AdminActions.setDashboardFilter, (state, action) => {
    const pageFilters = cloneDeep(state.pageFilters);
    let page = getPageFilters(pageFilters, action.page);
    page.filters[action.filter] = action.value;
    return {
      ...state,
      pageFilters,
    };
  }),
  on(AdminActions.setDashboardPage, (state, action) => {
    const pageFilters = cloneDeep(state.pageFilters);
    let page = getPageFilters(pageFilters, action.page);
    page.offset = action.offset;
    page.currentPage = action.currentPage;
    return {
      ...state,
      pageFilters,
    };
  }),
  on(AdminActions.clearDashboardFilters, (state, action) => {
    const pageFilters = cloneDeep(state.pageFilters);
    let page = getPageFilters(pageFilters, action.page);
    page.filters = {};
    page.offset = 0;
    page.currentPage = 1;
    return {
      ...state,
      pageFilters,
    };
  })
);

export function reducer(state: AdminState | undefined, action: Action) {
  return adminReducer(state, action);
}

function getPageFilters(pageFilters: any, pageName: string) {
  let page = pageFilters.find(p => p.page === pageName);
  if (!page) {
    page = cloneDeep(initialState.pageFilters[0]);
    page.page = pageName;
    pageFilters.push(page);
  }
  return page;
}
