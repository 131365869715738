<div class="toast--container">
  <div
    class="toast--body"
    *ngFor="let m of messages; let i = index"
    [ngClass]="{
      'toast--info': m.level === 'info',
      'toast--warn': m.level === 'warn',
      'toast--success': m.level === 'success'
    }">
    <div class="message--info">
      <div *ngIf="m.heading">
        <strong>{{ m.heading }}</strong>
      </div>
      <div data-cy="toast-message">
        {{ m.message }}
      </div>
    </div>
    <div class="button--dismiss" (click)="deleteMsg(m.timeStamp)">Dismiss</div>
  </div>
</div>
