import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import * as AuthActions from './auth.actions';

import { Account, User } from '../../shared/models';

export const authFeatureKey = 'auth';

export interface AuthState {
  currentUser: User;
  actAsUser: User;
  exp: number;
  token: string;
  returnUrl: string;
  currentAccount: Account;
  error: string;
}

export const initialState: AuthState = {
  currentUser: null,
  actAsUser: null,
  exp: null,
  token: null,
  returnUrl: null,
  currentAccount: null,
  error: null,
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.setCurrentUser, (state, data) => {
    if (state.actAsUser && state.actAsUser.id === data.data.id) {
      return {
        ...state,
        actAsUser: data.data,
      };
    } else {
      return {
        ...state,
        currentUser: data.data,
      };
    }
  }),
  on(AuthActions.setActAsUser, (state, data) => ({
    ...state,
    actAsUser: data.data,
  })),
  on(AuthActions.setCurrentUserAndAccount, (state, data) => {
    return {
      ...state,
      currentUser: data.data.user,
      currentAccount: data.data.account,
    };
  }),
  on(AuthActions.setCurrentAccount, (state, data) => ({
    ...state,
    currentAccount: data.data,
  })),
  on(AuthActions.setDelegate, (state, data) => {
    const currentUser = cloneDeep(state.currentUser);
    if (currentUser) {
      currentUser.delegate = data.data;
      currentUser.delegateId = data.data ? data.data.id : null;
    }
    return {
      ...state,
      currentUser,
    };
  }),
  on(AuthActions.setReturnUrl, (state, data) => ({
    ...state,
    returnUrl: data.data,
  })),
  on(AuthActions.clearAuthData, state => {
    return {
      ...state,
      currentUser: null,
      actAsUser: null,
      exp: null,
      token: null,
      returnUrl: null,
      currentAccount: null,
    };
  }),
  on(AuthActions.setToken, (state, data) => {
    return {
      ...state,
      token: data.idToken,
      exp: data.exp,
    };
  }),
  on(AuthActions.login, (state, data) => {
    return {
      ...state,
      exp: data.data.tokenExp,
      token: data.data.idToken,
      returnUrl: data.data?.returnUrl,
    };
  }),
  on(AuthActions.loginSuccess, (state, { user, returnUrl, myAccount }) => {
    return {
      ...state,
      currentUser: user,
      currentAccount: myAccount,
      returnUrl,
      error: null,
    };
  }),
  on(AuthActions.loginFailure, (state, error) => {
    return {
      ...state,
      error: error.error.error.detail,
    };
  }),
  on(AuthActions.logout, state => ({
    ...state,
    exp: null,
    token: null,
    currentUser: null,
    actAsUser: null,
    returnUrl: null,
    currentAccount: null,
    error: null,
  }))
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
