import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SuccessResponse } from './site-verification-visits.service';

import { environment } from '../../../environments/environment';
import { SiteNote } from '../../shared/models';
import { deserialize, serialize } from '../../shared/models/base-helper';

@Injectable({
  providedIn: 'root',
})
export class SiteNoteService {
  apiUrl = environment.apiUrl + '/site_notes/';

  constructor(readonly http: HttpClient) {}

  getSiteNotes(siteId: number): Observable<SiteNote[]> {
    return this.http
      .get<any[]>(`${this.apiUrl}?site_id=${siteId}`)
      .pipe(map(notes => notes.map((serializedSiteNote: any) => deserialize(serializedSiteNote, SiteNote))));
  }

  createSiteNote(siteId: number, note: string, hideFromLimitedAdmins: boolean) {
    const newNote = {
      site_id: siteId,
      note,
      hide_from_limited_admins: hideFromLimitedAdmins,
    };
    return this.http
      .post<any>(`${this.apiUrl}?site_id=${siteId}`, newNote)
      .pipe(map((serializedSiteNote: any) => deserialize(serializedSiteNote, SiteNote)));
  }

  updateSiteNote(body: SiteNote) {
    return this.http
      .patch<any>(`${this.apiUrl}${body.id}`, serialize(body))
      .pipe(map((serializedSiteNote: any) => deserialize(serializedSiteNote, SiteNote)));
  }

  deleteSiteNote(noteId: number) {
    return this.http.delete<SuccessResponse>(`${this.apiUrl}${noteId}`);
  }
}
