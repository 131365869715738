import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { DocumentService } from '../../../core/api/document.service';
import { GisService } from '../../../core/api/gis.service';
import { KmlForestryUploadService } from '../../../core/api/kml-forestry-upload.service';
import { SiteService } from '../../../core/api/site.service';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import * as fromApp from '../../../store';
import { setForestryKml } from '../../../store/parcel/parcel.actions';
import { Document, Site } from '../../models';
import { ToastLevel } from '../../models/toast-message.module';
import { ToastService } from '../../services/toast.service';
import { DialogYesNoComponent } from '../dialog-yes-no/dialog-yes-no.component';

export interface DialogData {
  site: Site;
}

@Component({
  selector: 'fc-dialog-import-kml',
  templateUrl: './dialog-import-kml.component.html',
  styleUrls: ['./dialog-import-kml.component.scss'],
})
export class DialogImportKmlComponent implements OnInit {
  @ViewChild('kmlFileUpload', { static: false }) kmlFileUpload: ElementRef;
  kmlDocSelectForm: UntypedFormGroup;
  errorMessage = null;
  activeSite?: Site;
  documents: Document[] = null;
  fileUpload = null;
  kmlParsedUpload: any;
  constructor(
    public dialogRef: MatDialogRef<DialogImportKmlComponent>,
    readonly http: HttpClient,
    public extraDialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    readonly siteService: SiteService,
    readonly documentService: DocumentService,
    readonly spinnerService: SpinnerService,
    readonly gisService: GisService,
    readonly kmlForestryUploadService: KmlForestryUploadService,
    readonly store: Store<fromApp.AppState>,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.activeSite = data.site;
    this.kmlDocSelectForm = this.formBuilder.group({
      kmlDocSelect: [''],
      uploadKmlFileName: '',
    });
  }

  ngOnInit() {
    let kmlDocType = this.documentService.getDocTypeId('Site KML');
    this.documentService.getDocuments(this.activeSite.identifier).subscribe(documents => {
      this.documents = documents.filter(doc => doc.documentTypeId == kmlDocType);
    });
  }

  validateKMLFile = (file: any) => {
    const fileExtension = file.name.substring(file.name.length - 4);
    this.spinnerService.show('app-spinner', true, 'Validating KML');
    switch (fileExtension) {
      case '.kml':
        this.kmlForestryUploadService
          .parseKmlDocument(file, false)
          .then(parsedKml => {
            this.kmlParsedUpload = parsedKml;
          })
          .catch((err: any) => {
            this.uploadKmlFileNameControl.setValue('');
          })
          .finally(() => {
            this.spinnerService.hide('app-spinner');
          });
        break;
      case '.kmz':
        this.kmlForestryUploadService
          .parseKmzDocument(file, false)
          .then(parsedKml => {
            this.kmlParsedUpload = parsedKml;
          })
          .catch((err: any) => {
            this.uploadKmlFileNameControl.setValue('');
          })
          .finally(() => {
            this.spinnerService.hide('app-spinner');
          });
        break;
      default:
        this.toastService.sendMessage('Only .kml and .kmz files are supported.', ToastLevel.WARN);
        this.uploadKmlFileNameControl.setValue('');
        this.spinnerService.hide('app-spinner');
    }
  };

  onClickKmlUpload(): void {
    const kmlFileUpload = this.kmlFileUpload.nativeElement;
    kmlFileUpload.onchange = () => {
      if (kmlFileUpload.files.length === 1) {
        this.fileUpload = kmlFileUpload.files[0];
        this.validateKMLFile(this.fileUpload);
        this.uploadKmlFileNameControl.setValue(this.fileUpload.name);
      }
    };
    kmlFileUpload.click();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  applyKML() {
    const dialogRef = this.extraDialog.open(DialogYesNoComponent, {
      panelClass: 'fc-yes-no-dialog',
      data: {
        title: 'Warning',
        line1:
          'Importing stands from a KML will remove all previously created stands. Are you sure you want to continue?',
        buttonTextYes: 'Yes, Continue',
        buttonTextNo: 'Cancel',
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (this.kmlDocSelectForm.get('uploadKmlFileName').value !== '') {
          this.kmlForestryUploadService.uploadKMLToSite(this.activeSite, this.fileUpload);
          this.store.dispatch(setForestryKml({ data: this.kmlParsedUpload.features }));
          this.dialogRef.close(false);
        } else {
          this.documentService
            .downloadKMLData(this.activeSite.identifier, this.kmlDocSelectForm.get('kmlDocSelect').value)
            .subscribe(document => {
              this.kmlForestryUploadService.extractAndParseKML('blah', document);
              this.dialogRef.close(true);
            });
        }
      } else {
        this.dialogRef.close(false);
      }
    });
  }

  get uploadKmlFileName(): string {
    return this.kmlDocSelectForm.get('uploadKmlFileName').value;
  }

  get uploadKmlFileNameControl(): AbstractControl {
    return this.kmlDocSelectForm.get('uploadKmlFileName');
  }

  isValid() {
    if (this.kmlDocSelectForm.get('kmlDocSelect').value == '' && this.uploadKmlFileName !== '') {
      return true;
    } else if (this.kmlDocSelectForm.get('kmlDocSelect').value !== '') {
      return true;
    } else {
      return false;
    }
  }
}
