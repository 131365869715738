import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { finalize, take } from 'rxjs/operators';

import { ErrorHelperService } from 'src/app/core/helper/error-helper.service';

import { BuyerService } from '../../../core/api/buyer.service';
import { UserService } from '../../../core/api/user.service';
import * as fromApp from '../../../store';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { RegexConstants } from '../../constants/regex.constants';
import { RoleEnum } from '../../constants/role.enums';
import { Buyer, User, UserAdminBuyer } from '../../models';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
  selector: 'fc-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss'],
})
export class AddUserDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('email') emailInputElement: ElementRef<HTMLInputElement>;

  destroyRef = inject(DestroyRef);

  errorMessage: string;
  isSuperAdmin: boolean;
  buyers: Buyer[];
  userForm: UntypedFormGroup;
  emailErrorMessage: string = '';
  buyerId: number[] | number = null;
  RoleEnum = RoleEnum;
  title: string = 'Invite User';
  primaryButtonText: string = 'INVITE';
  adminRoles: RoleEnum[] = [RoleEnum.Admin, RoleEnum.SuperAdmin, RoleEnum.FinanceAdmin];
  buyerSelectLabel: string = 'Preferred Buyer';
  disableUserRoleOptions: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddUserDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private buyerService: BuyerService,
    readonly store: Store<fromApp.AppState>,
    readonly errorHelperService: ErrorHelperService,
    private cdr: ChangeDetectorRef,
    readonly spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) {}

  ngOnInit() {
    this.store
      .select(selectCurrentUser)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(currentUser => {
        this.isSuperAdmin = currentUser.isSuperAdmin;
      });

    this.buyerService.getAllBuyers().subscribe(buyers => {
      this.buyers = buyers;
    });

    const roles = !isEmpty(this.data) || !isEmpty(this.data.roles) ? this.data.roles[0].getName() : null;

    this.userForm = this.formBuilder.group({
      email: [
        this.data?.email || null,
        [Validators.maxLength(254), Validators.pattern(RegexConstants.EMAIL), Validators.required],
      ],
      role: [roles || RoleEnum.Landowner, Validators.required],
      buyers: [this.getBuyerIds(), this.buyerConditionallyRequired],
      isActive: [true, Validators.required],
    });

    // set data when in edit mode
    if (this.data?.roles) {
      if (this.adminRoles.includes(this.data?.roles[0]?.getName() as RoleEnum)) {
        // disable the user role options if the user is an admin
        this.disableUserRoleOptions = true;
      } else {
        // disable the role field if the user is not a admin
        this.userForm.get('role').disable();
      }
    }

    if (this.data.id) {
      this.title = 'Edit User Permissions';
      this.userForm.get('email').disable();
      this.primaryButtonText = 'UPDATE USER';
      this.userForm.get('isActive').setValue(this.data.isActive);
      this.buyerId = this.getBuyerIds();
    }

    if ((this.data && this.data.roles && (this.data?.roles[0]?.getName() as RoleEnum)) === RoleEnum.LimitedAdmin) {
      this.buyerSelectLabel = 'Buyers';
    }

    this.userForm
      .get('role')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        this.userForm.get('buyers').updateValueAndValidity();
        if (value == RoleEnum.LimitedAdmin) {
          this.buyerSelectLabel = 'Buyers';
        } else {
          this.buyerSelectLabel = 'Preferred Buyer';
        }
      });

    this.userForm
      .get('email')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.emailErrorMessage = this.errorHelperService.getEmailErrorMessage(this.userForm.controls.email);

        if (this.errorMessage) {
          // if the email is updated, remove the error message until they submit again
          this.errorMessage = '';
          this.userForm.get('email').setErrors({ notUnique: false });
        }
      });
  }

  ngAfterViewInit() {
    // Focus on the email input when the dialog is opened and tell angular to check for changes
    this.emailInputElement.nativeElement.focus();
    this.cdr.detectChanges();
  }

  getBuyerIds(): null | number[] | number {
    const buyerAdminIds = !isEmpty(this.data)
      ? this.data.adminBuyers?.map((adminBuyer: UserAdminBuyer) => adminBuyer.buyerId)
      : [];

    let returnedBuyerIds = null;
    if (this.data?.preferredBuyerId) {
      returnedBuyerIds = this.data.preferredBuyerId;
    } else if (buyerAdminIds.length > 0) {
      returnedBuyerIds = buyerAdminIds;
    } else {
      returnedBuyerIds = null;
    }
    return returnedBuyerIds;
  }

  buyerConditionallyRequired(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get('role').value == RoleEnum.LimitedAdmin) {
      return Validators.required(formControl);
    }
    return null;
  }

  addBuyerId({ value }: { value: number | number[] }): void {
    // multiple buyers can now be selected for a buyer admin
    if (
      !value ||
      (typeof value === 'object' && value.includes(undefined)) ||
      (typeof value === 'object' && value.length === 0)
    ) {
      this.buyerId = null;
      this.userForm.get('buyers').setValue(null);
    } else if (typeof value === 'number' || (value && typeof value === 'object' && value.length > 0)) {
      this.buyerId = value;
    }
  }

  submitUser(): void {
    this.spinnerService.show('app-spinner');
    const userData = this.userForm.getRawValue();
    this.userService
      .inviteUpdateUser(userData.email.toLowerCase(), userData.role, this.buyerId, userData.isActive, this.data?.id)
      .pipe(finalize(() => this.spinnerService.hide('app-spinner')))
      .subscribe(
        (user: User) => {
          this.dialogRef.close(user);
        },
        error => {
          if (error.status === 400) {
            // need to set the state of the control to invalid to display the error message
            this.errorMessage = error.error.detail;
            this.userForm.get('email').setErrors({ notUnique: true });
          }
        }
      );
  }
}
