import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DialogData {
  title: string;
  question?: string;
  redText?: string;
  infoBox?: string;
  deductionPerYear?: string;
  buttonTextNo: string;
  buttonTextYes: string;
}

@Component({
  selector: 'fc-dialog-eligibility-question',
  templateUrl: './dialog-eligibility-question.component.html',
  styleUrls: ['./dialog-eligibility-question.component.scss'],
})
export class DialogEligibilityQuestionComponent {
  infoBoxPlacement = 0;

  constructor(
    public dialogRef: MatDialogRef<DialogEligibilityQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    if (data.infoBox) {
      this.infoBoxPlacement = data.redText ? 2 : 1;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
