import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SitePayment } from '../../../shared/models';

export interface DialogData {
  title: string;
  line1: string;
  sitePayments: Array<SitePayment>;
  buttonTextNo: string;
  buttonTextYes: string;
}

@Component({
  selector: 'fc-dialog-site-payment-results',
  templateUrl: './dialog-site-payment-results.component.html',
  styleUrls: ['./dialog-site-payment-results.component.scss'],
})
export class DialogSitePaymentResultsComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogSitePaymentResultsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
