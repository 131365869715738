import { Action, createReducer, on } from '@ngrx/store';

import * as NotificationActions from './notification.actions';

export const notificationFeatureKey = 'notification';

export interface NotificationState {
  landOwner: {
    userId: number;
    paymentsAccountRequiresSetup: number;
    stripeAccountRequiresAction: boolean;
  };
  admin: {
    failedVerificationUsersCount: number;
  };
}

export const initialState: NotificationState = {
  landOwner: {
    userId: null,
    paymentsAccountRequiresSetup: 0,
    stripeAccountRequiresAction: false,
  },
  admin: {
    failedVerificationUsersCount: 0,
  },
};

const notificationReducer = createReducer(
  initialState,

  on(NotificationActions.setLandOwnerNotifications, (state, action) => ({
    ...state,
    landOwner: {
      paymentsAccountRequiresSetup: action.data.count,
      stripeAccountRequiresAction: action.data.required,
      userId: action.data.userId,
    },
  })),
  on(NotificationActions.resetStripeAccountRequiresAction, (state, action) => ({
    ...state,
    landOwner: {
      ...state.landOwner,
      stripeAccountRequiresAction: false,
      userId: null,
    },
  })),
  on(NotificationActions.resetLandOwnerPaymentsAccountRequiresSetup, (state, action) => ({
    ...state,
    landOwner: {
      ...state.landOwner,
      paymentsAccountRequiresSetup: 0,
      userId: null,
    },
  })),
  on(NotificationActions.resetNotifications, (state, action) => ({
    ...initialState,
  })),
  on(NotificationActions.getAdminNotificationsSuccess, (state, action) => ({
    ...state,
    admin: {
      failedVerificationUsersCount: action.data.failedVerificationUsersCount,
    },
  }))
);

export function reducer(state: NotificationState | undefined, action: Action) {
  return notificationReducer(state, action);
}
