import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IneligibleAreaUser } from 'src/app/shared/models/ineligible-area-user.model';

import { CSVResponse } from './premium-acreage-user.service';

import { environment } from '../../../environments/environment';
import { deserialize } from '../../shared/models/base-helper';

@Injectable({
  providedIn: 'root',
})
export class IneligibleAreaUserService {
  apiUrl = environment.apiUrl + '/ineligible_area_users/';

  constructor(readonly http: HttpClient) {}

  createIneligibleAreaUser(newIneligibleAreaUser: any): Observable<IneligibleAreaUser> {
    return this.http
      .post(this.apiUrl, newIneligibleAreaUser)
      .pipe(map((ineligibleAreaUser: any) => deserialize(ineligibleAreaUser, IneligibleAreaUser)));
  }

  getIneligibleUserCSV(): Observable<string> {
    return this.http.get<CSVResponse>(`${this.apiUrl}csv`).pipe(map((response: CSVResponse) => response.csv_string));
  }
}
