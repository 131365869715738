import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  // encodes filter values for query_and_refine filtering
  // removes opening parentheses '(' which do not get appropriately processed by query_and_refine
  preprocessFilterString(str: string) {
    return encodeURIComponent(str).replace(/\(/g, '');
  }

  preprocessFilterStrings(filters: Array<string>): string {
    return encodeURIComponent(filters.join()).replace(/\(/g, '');
  }
}
