<div class="heading">
  <div *ngIf="showTotals" class="acres-container">
    <div class="top">
      {{
        totalSitesAcres.toLocaleString(undefined, {
          maximumFractionDigits: 0
        })
      }}
    </div>
    <div class="divider"></div>
    <div class="bottom">TOTAL ACRES</div>
  </div>
  <div *ngIf="showTotals" class="acres-container">
    <div class="top">
      {{ totalSitesValue | currency: 'USD' }}
    </div>
    <div class="divider"></div>
    <div class="bottom">Estimated Annual Revenue</div>
  </div>
</div>
