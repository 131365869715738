import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fc-dialog-out-of-region',
  templateUrl: './dialog-out-of-region.component.html',
  styleUrls: ['./dialog-out-of-region.component.scss'],
})
export class DialogOutOfRegionComponent {
  parcels: string[] = [];

  constructor(public dialogRef: MatDialogRef<DialogOutOfRegionComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.parcels = data.parcels;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
