import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DiagnosticsService {
  apiUrl = environment.apiUrl;

  constructor(readonly http: HttpClient) {}

  getBackendHealthcheck() {
    return this.http.get<any>(`${this.apiUrl}/healthcheck`);
  }
}
