import _ from 'lodash';

import { Account } from './account.model';
import { AdjustmentType } from './adjustment-type.model';
import { Adjustment } from './adjustment.model';
import { Buyer } from './buyer.model';
import { Cohort } from './cohort.model';
import { DisturbanceReport } from './disturbance-report.model';
import { DocumentType } from './document-type.model';
import { Document } from './document.model';
import { EmailTemplate } from './email-template.model';
import { IneligibleAreaUser } from './ineligible-area-user.model';
import { Parcel } from './parcel.model';
import { PaymentPeriod } from './payment-period.model';
import { PeriodicReport } from './periodic-report.model';
import { PremiumAcreageUser } from './premium-acreage-user.model';
import { Program } from './program.model';
import { ProspectiveUser } from './prospective-user.model';
import { Region } from './region.model';
import { Report } from './report.model';
import { Role } from './role.model';
import { SiteNote } from './site-note.model';
import { SitePayment } from './site-payment.model';
import { SiteStatusLog } from './site-status-log';
import { SiteVerificationVisit } from './site-verification-visit.model';
import { Site } from './site.model';
import { SiteReviewCheck } from './site_review_check.model';
import { SiteReviewNote } from './site_review_note.model';
import { Stand } from './stand.model';
import { Strata } from './strata.model';
import { StripeAccount } from './stripe-account.model';
import { UserActivity } from './user-activity.model';
import { UserAdminBuyer } from './user-admin-buyer.model';
import { User } from './user.model';
import { Version } from './version.model';
import { YieldCurve } from './yield-curve.model';

import { CountyInfo } from '../../core/api/gis.service';
export const mappedClasses = [
  Account,
  Adjustment,
  AdjustmentType,
  Buyer,
  Cohort,
  CountyInfo,
  DisturbanceReport,
  Document,
  DocumentType,
  EmailTemplate,
  IneligibleAreaUser,
  Parcel,
  PaymentPeriod,
  PeriodicReport,
  PremiumAcreageUser,
  Program,
  ProspectiveUser,
  Region,
  Report,
  Role,
  Site,
  SiteNote,
  SitePayment,
  SiteStatusLog,
  SiteVerificationVisit,
  SiteReviewCheck,
  SiteReviewNote,
  Stand,
  Strata,
  StripeAccount,
  User,
  UserActivity,
  UserAdminBuyer,
  Version,
  YieldCurve,
];

export function deserialize(obj: any, targetClass: any) {
  if (Array.isArray(obj)) {
    return obj.map(item => deserialize(item, targetClass));
  }
  if (obj !== null && obj.constructor === Object) {
    let result = {};
    for (let [key, value] of Object.entries(obj)) {
      const camelKey = _.camelCase(key);
      const propType = Reflect.getMetadata('design:type', targetClass.prototype, camelKey);

      if (propType && mappedClasses.includes(propType)) {
        if (Array.isArray(value)) {
          result[camelKey] = value.map(v => deserialize(v, propType));
        } else {
          result[camelKey] = deserialize(value, propType);
        }
      } else {
        result[camelKey] = value;
      }
    }
    return new targetClass(result);
  }
  return obj;
}

export function serialize(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(item => serialize(item));
  }
  if (obj !== null && Object.entries(obj).length > 0) {
    let result = {};
    for (let [key, value] of Object.entries(obj)) {
      const snakeKey = key.replace(/([a-z])([A-Z0-9])/g, '$1_$2').toLowerCase();
      if (Array.isArray(value)) {
        result[snakeKey] = value.map(item => serialize(item));
      } else if (value instanceof Object) {
        result[snakeKey] = serialize(value);
      } else {
        result[snakeKey] = value;
      }
    }
    return result;
  }
  return obj;
}
