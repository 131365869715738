import { Directive, HostListener, Self } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[fcFormUnloadWarning]',
})
export class FormUnloadWarningDirective {
  constructor(@Self() private formGroup: FormGroupDirective) {}

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload() {
    return !this.formGroup.dirty;
  }
}
