import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][currencyMask]',
})
export class CurrencyMaskDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('blur', ['$event'])
  onBlur(event: { target: HTMLInputElement }): void {
    this.onInputBlur(event.target.value);
  }

  @HostListener('focus', ['$event'])
  onFocus(event: { target: HTMLInputElement }): void {
    this.onInputFocus(event.target.value);
  }

  onInputBlur(value: string): void {
    let newVal = value.replace(/[^.\d]/g, '');

    if (newVal !== '') {
      newVal = Number(parseFloat(newVal).toFixed(2)).toLocaleString('en', {
        minimumFractionDigits: 2,
      });
      this.ngControl.control?.setValue(`$${newVal}`);
    } else {
      this.ngControl.control?.setValue('');
    }
  }

  onInputFocus(value: string): void {
    const newVal = value.replace(/[^.\d]/g, '');

    if (newVal !== '') {
      this.ngControl.control?.setValue(`${newVal}`);
    } else {
      this.ngControl.control?.setValue('');
    }
  }
}
