import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormatHelperService {
  removeCurrency(value?: string) {
    return parseFloat((value || '0').replace(/[^.0-9]/g, ''));
  }

  formatCurrency(value?: string | number): string {
    let newVal = '';

    if (value !== undefined) {
      if (typeof value === 'number') {
        newVal = value.toString();
      } else if (value && typeof value === 'string') {
        newVal = value;
      }
    }
    newVal = newVal.replace(/[^.0-9]/g, '');

    if (newVal !== '') {
      newVal = Number(parseFloat(newVal).toFixed(2)).toLocaleString('en', {
        minimumFractionDigits: 2,
      });
      return `$${newVal}`;
    } else {
      return '';
    }
  }

  formatMinMaxValuation(prices: any[], includeAsterisk: boolean = false): string {
    let valuation = '$0';
    if (prices) {
      const minValue = Math.min(...prices);
      const maxValue = Math.max(...prices);
      valuation = this.formatCurrencyLocal(minValue);
      if (minValue !== maxValue) {
        valuation += ' - ' + this.formatCurrencyLocal(maxValue) + (includeAsterisk ? '*' : '');
      }
    }
    return valuation;
  }

  formatCurrencyLocal(value: number) {
    if (value === null) {
      value = 0;
    }
    return value.toLocaleString('us-EN', {
      style: 'currency',
      currency: 'USD',
    });
  }

  formatDatetimeUTCtoLocal(isoUtcDatetime: string) {
    return formatDate(
      isoUtcDatetime.endsWith('Z') ? isoUtcDatetime : `${isoUtcDatetime}Z`,
      'MMM d, y, h:mm a',
      'en-US'
    );
  }

  formatFourDigits(value: number) {
    if (value === null) {
      value = 0;
    }
    return value.toLocaleString(undefined, {
      maximumFractionDigits: 4,
    });
  }
}
