import { Base } from './base.model';
import { UserRoleType } from './user-role-type.model';

import { Type } from '../decorators/type.decorator';

export class Role extends Base {
  public userId: number;
  public userRoleTypeId: number;
  @Type(UserRoleType) public userRoleType: UserRoleType;

  getName() {
    if (this.userRoleType) {
      return this.userRoleType.name;
    }
    return null;
  }
}
