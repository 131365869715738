import { Base } from './base.model';

export class IneligibleAreaUser extends Base {
  public email: string;
  public address: string;
  public coordinates: string;
  public parcelId: string;
  public siteSize: string;
  public submittedDate: Date;
  public contacted: boolean;
  public referrerCode: number;
  public state: string;
  public county: string;
}
