import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fc-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss'],
})
export class TablePaginationComponent {
  @Input() totalPages: number;
  @Input() currentPage: number;
  @Input() showPages: [];
  @Output() goToPage: EventEmitter<number> = new EventEmitter<number>();

  changePage(pageNum: number) {
    this.goToPage.emit(pageNum);
  }
}
