import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { isEmpty } from 'lodash';
import moment, { unix } from 'moment';
import { forkJoin, from, Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { AuthService } from './auth.service';

import { environment } from '../../../environments/environment';
import { serialize } from '../../shared/models/base-helper';
import * as fromApp from '../../store';
import { logout, setToken } from '../../store/auth/auth.actions';

/**
 * Add token to all HttpClient request headers
 */
@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  private authService: AuthService = this.injector.get(AuthService);

  constructor(readonly injector: Injector, readonly store: Store<fromApp.AppState>) {}

  setRequestHeaders(request: HttpRequest<any>, token: string, actAsUser: string): HttpRequest<any> {
    if (!isEmpty(token)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    if (actAsUser) {
      request = request.clone({
        setHeaders: {
          'X-FC-ActAs': actAsUser,
        },
      });
    }
    return request;
  }

  logout() {
    this.store.dispatch(logout());
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.apiUrl)) {
      request = request.clone({ body: serialize(request.body) });
    }
    if (this.authService !== null) {
      const token = this.authService.getToken();
      const exp = this.authService.getExp();
      const actAsUser = this.authService.getActAsUser();
      if (Boolean(request.headers.get('X-FC-Noauth'))) {
        // Continue if no auth is required
        return next.handle(request);
      } else if (exp && unix(exp).isBefore(moment())) {
        // If token has expired, request a new token from Cognito
        return forkJoin([Auth.currentSession(), Auth.currentAuthenticatedUser()]).pipe(
          mergeMap(([session, user]) => {
            // Refresh the current session and token
            return from(
              new Promise((resolve, reject) => {
                user.refreshSession(session.getRefreshToken(), (err: any, result: any) => {
                  if (err) {
                    console.log('Error refreshing session', err);
                    reject(err);
                  } else {
                    console.log('Resolving session', result);
                    resolve(result);
                  }
                });
              })
            ).pipe(
              mergeMap((data: any) => {
                // Set the refreshed token and update the expiration date
                const refreshToken = data.idToken.jwtToken;
                this.authService.token = refreshToken;
                this.authService.exp = data.idToken.payload.exp;
                this.injector.get(Store).dispatch(
                  setToken({
                    idToken: refreshToken,
                    exp: this.authService.exp,
                  })
                );
                return next.handle(this.setRequestHeaders(request, refreshToken, actAsUser));
              }),
              catchError(error => {
                // Log the user out if there was an error refreshing the session token
                console.log(`There was an error refreshing the session token: ${error}`);
                this.logout();
                return next.handle(request);
              })
            );
          }),
          catchError(error => {
            // If the current session or current authenticated user is unable to be retrieved,
            // the refresh token has likely expired and the user must reauthenticate.
            console.log(
              `There was an error retrieving the current session and/or current authenticated user: ${error}`,
              'Refresh token has likely expired. Logging you out.'
            );
            this.logout();
            return next.handle(request);
          })
        );
      } else {
        return next.handle(this.setRequestHeaders(request, token, actAsUser));
      }
    }
    return next.handle(request);
  }
}
