import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isEmpty } from 'lodash';

export interface DialogData {
  currentValue: number;
  newValue: number;
  siteName: string;
}

@Component({
  selector: 'fc-adjust-value-dialog',
  templateUrl: './adjust-value-dialog.component.html',
  styleUrls: ['./adjust-value-dialog.component.scss'],
})
export class AdjustValueDialogComponent {
  reason = '';
  constructor(
    public dialogRef: MatDialogRef<AdjustValueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  get yesButtonDisabled(): boolean {
    return isEmpty(this.reason);
  }
}
