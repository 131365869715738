import { Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { SiteService } from '../../../core/api/site.service';
import { FormatHelperService } from '../../../core/helper/format-helper.service';
import * as fromApp from '../../../store';
import { PaymentPeriod } from '../../models';
import { serialize } from '../../models/base-helper';

@Component({
  selector: 'fc-dialog-edit-payment-period',
  templateUrl: './dialog-edit-payment-period.component.html',
  styleUrl: './dialog-edit-payment-period.component.scss',
})
export class DialogEditPaymentPeriodComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  updatePaymentForm: UntypedFormGroup;
  paymentPeriod: PaymentPeriod;

  constructor(
    readonly store: Store<fromApp.AppState>,
    public dialogRef: MatDialogRef<DialogEditPaymentPeriodComponent>,
    public dialog: MatDialog,
    public siteService: SiteService,
    private formBuilder: UntypedFormBuilder,
    private formatHelper: FormatHelperService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.paymentPeriod = cloneDeep(data.paymentPeriod);
  }

  ngOnInit(): void {
    this.updatePaymentForm = this.formBuilder.group({
      ppt: [this.formatHelper.formatCurrency(this.paymentPeriod.ppt), Validators.required],
      netPayoutAdjustment: [
        this.formatHelper.formatCurrency(this.paymentPeriod.netPayoutAdjustment),
        Validators.required,
      ],
      isVariablePeriod: [this.paymentPeriod.isVariablePeriod, Validators.required],
      comments: [null, Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onSubmit() {
    this.paymentPeriod.ppt = this.formatHelper.removeCurrency(this.updatePaymentForm.value.ppt);
    this.paymentPeriod.netPayoutAdjustment = this.formatHelper.removeCurrency(
      this.updatePaymentForm.value.netPayoutAdjustment
    );
    this.paymentPeriod.isVariablePeriod = this.updatePaymentForm.value.isVariablePeriod;
    this.dialogRef.close({
      payment_period: serialize(this.paymentPeriod),
      comments: this.updatePaymentForm.value.comments,
    });
  }
}
