import { Component } from '@angular/core';

import { FAQ } from 'src/app/shared/constants/faq.constants';
import { LegalConstants } from 'src/app/shared/constants/legal.constants';

@Component({
  selector: 'fc-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  faqData = FAQ;
  stripeDisclaimer = LegalConstants.STRIPE_DISCLAIMER;
}
