import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { CohortService } from '../../../core/api/cohort.service';
import * as fromApp from '../../../store';
import { selectIsMobileView } from '../../../store/app-config/app-config.selectors';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { setMapControlDrawMode } from '../../../store/map-control/map-control.actions';
import { User } from '../../models';

@Component({
  selector: 'fc-new-site-step-one-and-a-half',
  templateUrl: './new-site-step-one-and-a-half.component.html',
  styleUrls: ['./new-site-step-one-and-a-half.component.scss'],
})
export class NewSiteStepOneAndAHalfComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  newSiteForm: UntypedFormGroup;

  currentUser: User = null;
  isMobile = false;

  constructor(
    public dialog: MatDialog,
    protected route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    readonly store: Store<fromApp.AppState>,
    readonly cohortService: CohortService
  ) {
    this.createNewSiteForm();
  }

  ngOnInit() {
    this.store
      .select(selectIsMobileView)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(mobileViewMode => {
        this.isMobile = mobileViewMode;
      });

    this.store
      .select(selectCurrentUser)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((currentUser: User) => (this.currentUser = currentUser));

    this.store.dispatch(setMapControlDrawMode({ active: true }));
    window.scrollTo(0, 300);
  }

  createNewSiteForm(): void {
    this.newSiteForm = this.formBuilder.group({});
  }

  goToDash(): void {
    const url = '/account/dashboard';
    this.router.navigateByUrl(url);
  }

  confirmInitialEligibility(): void {
    this.router.navigate(['/site/new-site/step-two']);
  }
}
