import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { selectCurrentUser } from 'src/app/store/auth/auth.selectors';

import * as fromApp from '../../../store';
import { selectDocumentTypes } from '../../../store/document/document.selectors';
import { User } from '../../models';

export interface DialogData {
  filename: string;
  description: string;
  docTypeId: number;
  comments: string;
}

@Component({
  selector: 'fc-dialog-upload-doc',
  templateUrl: './dialog-upload-doc.component.html',
  styleUrls: ['./dialog-upload-doc.component.scss'],
})
export class DialogUploadDocComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  docUploadForm: UntypedFormGroup;
  docTypes = [];
  isSiteDetailsScreen: boolean = false;
  isCurrentUserAdmin: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogUploadDocComponent>,
    private formBuilder: UntypedFormBuilder,
    readonly store: Store<fromApp.AppState>,
    readonly router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.docUploadForm = this.formBuilder.group({
      docType: [data.docTypeId, Validators.required],
      description: data.description,
      comments: data.comments,
    });
  }

  ngOnInit() {
    this.store
      .select(selectDocumentTypes)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(docTypes => {
        this.docTypes = docTypes
          ? docTypes.filter(t => !t.isPrivate).map(t => ({ value: t.id, viewValue: t.name }))
          : null;
      });

    this.isSiteDetailsScreen = !this.router.url.includes('information-documentation');

    this.store
      .select(selectCurrentUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (user: User) => {
          if (user) {
            this.isCurrentUserAdmin = user.isAdmin;
          }
        },
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveDocument() {
    this.dialogRef.close({
      description: this.docUploadForm.controls.description.value,
      docTypeId: this.docUploadForm.controls.docType.value,
      comments: this.docUploadForm.controls.comments.value,
    });
  }
}
