import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  line1: string;
  line1Color: string;
  line2: string;
  line2Color: string;
  line3: string;
  line3Color: string;
  buttonTextNo: string;
  buttonStyleNo: string;
  buttonTextYes: string;
  buttonStyleYes: string;
}

@Component({
  selector: 'fc-dialog-yes-no',
  templateUrl: './dialog-yes-no.component.html',
  styleUrls: ['./dialog-yes-no.component.scss'],
})
export class DialogYesNoComponent {
  constructor(public dialogRef: MatDialogRef<DialogYesNoComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
