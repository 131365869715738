import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as BuyerActions from './buyer.actions';

import { BuyerService } from '../../core/api/buyer.service';
import { AppState } from '../index';

@Injectable()
export class BuyerEffects {
  getBuyer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuyerActions.getBuyer),
      concatMap(action => {
        return this.buyerService.getBuyer(action.referrerCode).pipe(
          map(data => BuyerActions.getBuyerSuccess({ data })),
          catchError(error => of(BuyerActions.getBuyerFailure({ error })))
        );
      })
    );
  });

  getAllBuyers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuyerActions.getAllBuyers),
      concatMap(action => {
        return this.buyerService.getAllBasicBuyers().pipe(
          map(data => BuyerActions.getAllBuyersSuccess({ data })),
          catchError(error => of(BuyerActions.getAllBuyersFailure({ error })))
        );
      })
    );
  });

  setPreferredBuyer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BuyerActions.setPreferredBuyer),
      concatMap(action => {
        return this.buyerService.getBuyer(action.referrerCode).pipe(
          map(data => BuyerActions.setPreferredBuyerSuccess({ data })),
          catchError(error => of(BuyerActions.setPreferredBuyerFailure({ error })))
        );
      })
    );
  });

  constructor(readonly actions$: Actions, readonly buyerService: BuyerService, readonly store: Store<AppState>) {}
}
