import { Action, createReducer, on } from '@ngrx/store';

import * as CohortActions from './cohort.actions';

import { Cohort } from '../../shared/models';

export const cohortFeatureKey = 'cohort';

export interface CohortState {
  cohorts: Array<Cohort>;
  error: string;
}

export const initialState: CohortState = {
  cohorts: [],
  error: null,
};

const cohortReducer = createReducer(
  initialState,
  on(CohortActions.getCohorts, state => {
    return {
      ...state,
      cohorts: [],
      error: null,
    };
  }),
  on(CohortActions.getCohortsSuccess, (state, action) => {
    return {
      ...state,
      cohorts: action.data.results,
      error: null,
    };
  }),
  on(CohortActions.getCohortsFailure, (state, data) => {
    let error = 'There was an error';
    if (data.error) {
      if (data.error.error && data.error.error.detail) {
        error = data.error.error.detail;
      } else if (data.error.message) {
        error = data.error.message;
      }
    }
    return {
      ...state,
      error,
    };
  })
);

export function reducer(state: CohortState | undefined, action: Action) {
  return cohortReducer(state, action);
}
