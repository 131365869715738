<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title data-cy="payments-header">Payments</mat-panel-title>
  </mat-expansion-panel-header>

  <ng-container *ngIf="showStripeLink">
    <div *ngIf="!stripeIsActive; else stripeIsSetUp" class="stripe-message">
      Please sign up for a Stripe™ account to start receiving payments and viewing payment history.
      <div (click)="doStripeConnect()" class="fc-link">
        <fa-icon [icon]="['fas', 'arrow-up-right-from-square']" class="blue download-icon"></fa-icon>
        <span>Set Up A Stripe™ Payment Account with {{ buyerName }}</span>
      </div>
    </div>
    <ng-template #stripeIsSetUp>
      <div class="payment-info">
        <p>
          Payments will continue provided that reporting is completed on time, disturbances are accurately recorded, and
          the property remains in good standing. All payments are securely made through Stripe.
        </p>
        <p>You can use the link below to access your payment history.</p>
        <div (click)="getLoginLink()" class="fc-link">
          <fa-icon [icon]="['fas', 'arrow-up-right-from-square']" class="blue download-icon"></fa-icon>
          <span>Visit Stripe™ Account with {{ buyerName }}</span>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <div *ngFor="let pp of nextPaymentPeriods; index as i">
    <div class="payment-wrapper" *ngIf="i < numShownPayments">
      <div class="adjustment-header">
        Next Projected Payment (~{{ formatPaymentDate(pp.endDate) }})
        <fa-icon
          *ngIf="isSuperAdmin"
          #tooltip="matTooltip"
          (click)="editPeriod(pp)"
          [icon]="['far', 'pen-to-square']"
          class="blue edit-icon"
          matTooltip="Edit Payment Period"
          matTooltipClass="fc-tooltip"
          matTooltipPosition="right"></fa-icon>
      </div>
      <table aria-describedby="Adjustment Table" class="payments" role="presentation">
        <tr class="base-payment-generation">
          <td>Base Generation</td>
          <td>{{ pp.netErt - pp.deductionErt | number: '1.0-4' }} ERT</td>
        </tr>
        <tr *ngFor="let adjustment of pp.adjustments" class="payment-adjustment">
          <td>
            {{ getAdjustmentViewName(adjustment.adjustmentType.shortName) }}
          </td>
          <td>{{ adjustment.value }} ERT</td>
        </tr>
        <tr class="payment-net-ert">
          <td>Net ERT</td>
          <td>{{ pp.netErt }} ERT x {{ pp.ppt | currency: 'USD' }} / ERT</td>
        </tr>
        <tr *ngIf="pp.netPayoutAdjustment > 0" class="bonus-adjustment">
          <td>Payment Adjustment</td>
          <td>{{ pp.netPayoutAdjustment | currency: 'USD' }}</td>
        </tr>
        <tr class="payment-summary">
          <td>Total Projected Payment</td>
          <td>
            {{ pp.netPayout | currency: 'USD' }}
            <span *ngIf="pp.isVariablePeriod">
              <fa-icon
                #tooltip1="matTooltip"
                (click)="tooltip1.toggle()"
                [icon]="['far', 'circle-info']"
                [matTooltip]="
                  'Variable ERT: This price is subject to carbon offset market rates and is subject to change.'
                "
                class="blue"
                matTooltipClass="fc-tooltip"
                matTooltipPosition="right"></fa-icon>
              Variable
            </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div *ngIf="totalPaymentPeriods > numShownPayments" class="morePaymentPeriods">
    <button (click)="viewMorePayments()" color="primary" id="more-payment-link" mat-stroked-button>See More</button>
  </div>
  <mat-divider></mat-divider>

  <div class="section-content payment-history">
    <div class="title">Payment History</div>
    <div class="section-header">Total Payments to Date</div>
    <div class="payments-to-date">{{ totalPayments }}</div>
    <div class="section-header">Payments</div>
    <ul class="payment-list">
      <li *ngFor="let payment of payments">
        <div class="payment-date">
          {{ payment.createDate | date: 'shortDate' }}
        </div>
        <div class="payment-amount">
          {{ getPaymentDisplay(payment) }}
        </div>
        <div *ngIf="!payment.error; else inError" class="payment-message">
          {{ getPaymentMessage(payment) }}
        </div>
        <ng-template #inError>
          <div [title]="payment.error" class="payment-message payment-error">
            {{ payment.error }}
          </div>
        </ng-template>
        <div [title]="payment.getRecordedBy()" class="recorded-by">
          {{ payment.getRecordedBy() }}
        </div>
      </li>
    </ul>
  </div>
  <mat-divider></mat-divider>
  <div *ngIf="canPaySites" class="section-content">
    <div class="val-adj-section-header spaced">Payment Management</div>
    <div>
      <div *ngIf="!showManualPayment && !showAdjustValue" class="payment-actions">
        <button (click)="addManualPayment(false)" class="submit-adj-value" color="primary" mat-stroked-button>
          Record External Payment
        </button>
        <button
          (click)="addManualPayment(true)"
          [disabled]="!hasStripe"
          class="submit-adj-value"
          color="primary"
          mat-stroked-button>
          Make Manual Payment
        </button>
        <button
          (click)="adjustValue(null)"
          *ngIf="isSuperAdmin"
          class="submit-adj-value"
          color="primary"
          mat-stroked-button>
          Adjust Payment Revenue
        </button>
      </div>
      <div *ngIf="showAdjustValue">
        <form [formGroup]="valueAdjustmentForm" class="value-adj-form">
          <div class="value-adjustment-description">
            You can adjust the landowner's ERT valuation here.
            <br />
            <p>Current Payment Valuation: {{ valuation }}</p>
          </div>
          <div class="adjustment-amount">
            <label>
              <fa-icon
                #tooltip="matTooltip"
                (click)="tooltip.toggle()"
                [icon]="['far', 'circle-info']"
                class="blue"
                matTooltip="The ERT adjustment, in decimal form. Enter a (-) for a negative adjustment. e.g. -12.3456"
                matTooltipClass="fc-tooltip"
                matTooltipPosition="right"></fa-icon>
            </label>
            <mat-form-field>
              <mat-label>Add or Subtract an Amount</mat-label>
              <input formControlName="amount" id="amount" matInput type="number" />
            </mat-form-field>
          </div>
        </form>
        <div class="payment-adjustment-button-container">
          <button
            (click)="cancelAdjustValue()"
            class="payment-button"
            color="primary"
            data-cy="button-cancel"
            mat-stroked-button>
            Cancel
          </button>
          <button
            (click)="submitAdjustValue()"
            [disabled]="!valueAdjustmentForm.valid"
            class="submit-adj-value"
            color="primary"
            mat-raised-button>
            Submit Payment Adjustment
          </button>
        </div>
      </div>
      <div *ngIf="showManualPayment">
        <form [formGroup]="newManualPayment">
          <div class="form-container">
            <div class="payment-field-row">
              <div class="payment-data">
                <fc-datepicker
                  formControlName="paymentDate"
                  formFieldClass="paymentDate-container"
                  inputID="paymentDate"
                  label="Payment Date"></fc-datepicker>
              </div>
              <div class="payment-data">
                <mat-form-field>
                  <mat-label>Payment Amount {{ maxPaymentLabel }}</mat-label>
                  <input formControlName="paymentAmount" id="paymentAmount" matInput required type="number" />
                </mat-form-field>
              </div>
            </div>
            <div class="payment-field-row">
              <mat-form-field class="w-100-1">
                <mat-label>Payment Note</mat-label>
                <textarea formControlName="paymentNote" id="paymentNote" matInput required rows="4"></textarea>
              </mat-form-field>
            </div>
          </div>
        </form>
        <button (click)="handleNewPaymentButton(false)" class="payment-button" color="primary" mat-stroked-button>
          Cancel
        </button>
        <button
          (click)="handleNewPaymentButton(true)"
          class="payment-button"
          color="primary"
          disabled="{{ !newManualPayment.valid }}"
          mat-raised-button>
          {{ expressPayment ? 'Submit' : 'Record' }} Payment
        </button>
        <div class="error">{{ error }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="showStripeLink" class="legal-disclaimer">
    {{ stripeDisclaimer }}
  </div>
</mat-expansion-panel>
