import { createAction, props } from '@ngrx/store';

export const getSiteNotes = createAction('[SiteNote] Get SiteNotes', props<{ siteId: number }>());

export const getSiteNotesSuccess = createAction('[SiteNote] Get SiteNotes Success', props<{ data: any }>());

export const getSiteNotesFailure = createAction('[SiteNote] Get SiteNotes Failure', props<{ error: any }>());

export const createSiteNote = createAction(
  '[SiteNote] Create SiteNote',
  props<{ siteId: number; note: string; hideFromLimitedAdmins: boolean }>()
);

export const createSiteNoteSuccess = createAction('[SiteNote] Create SiteNote Success', props<{ data: any }>());

export const createSiteNoteFailure = createAction('[SiteNote] Create SiteNote Failure', props<{ error: any }>());
