import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  line1: string;
  line1Color: string;
  line2: string;
  line2Color: string;
  line3: string;
  line3Color: string;
  buttonTextNo: string;
  buttonStyleNo: string;
  buttonTextYes: string;
  buttonStyleYes: string;
}

@Component({
  selector: 'fc-dialog-down-for-maintenance',
  templateUrl: './dialog-down-for-maintenance.component.html',
  styleUrls: ['./dialog-down-for-maintenance.component.scss'],
})
export class DialogDownForMaintenanceComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogDownForMaintenanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    // this.dialogRef.close();
  }
}
