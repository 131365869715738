import { Action, createReducer, on } from '@ngrx/store';

import * as SiteStatusActions from './site-status.actions';

import { SiteStatus } from '../../shared/models/site-status';

export const siteStatusFeatureKey = 'siteStatus';

export interface SiteStatusState {
  statuses: Array<SiteStatus>;
}

export const initialState: SiteStatusState = {
  statuses: [],
};

const siteStatusReducer = createReducer(
  initialState,
  on(SiteStatusActions.getSiteStatusesSuccess, (state, data) => {
    return {
      ...state,
      statuses: data.data,
    };
  }),
  on(SiteStatusActions.getSiteStatusesFailure, (state, data) => {
    let error = 'There was an error getting site statuses';
    if (data.error?.error?.detail) {
      error = data.error.error.detail;
    } else if (data.error?.message) {
      error = data.error.message;
    }
    return {
      ...state,
      statuses: [],
      error,
    };
  })
);

export function reducer(state: SiteStatusState | undefined, action: Action) {
  return siteStatusReducer(state, action);
}
