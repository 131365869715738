import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { GisService } from '../../../core/api/gis.service';
import { SiteService } from '../../../core/api/site.service';
import * as fromApp from '../../../store';
import { setActiveSiteSuccessWithAvailableCohorts } from '../../../store/site/site.actions';
import { serialize } from '../../models/base-helper';

@Component({
  selector: 'fc-dialog-recalculate-site',
  templateUrl: './dialog-recalculate-site.component.html',
  styleUrls: ['./dialog-recalculate-site.component.scss'],
})
export class RecalculateSiteDialogComponent {
  site: any;

  constructor(
    public dialogRef: MatDialogRef<RecalculateSiteDialogComponent>,
    readonly store: Store<fromApp.AppState>,
    readonly siteService: SiteService,
    readonly gisService: GisService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.site = data.site;
  }

  onProceedClick() {
    let site = cloneDeep(this.site);
    site.versions[0].stands.forEach(stand => {
      stand.stratas = null;
    });
    const update_site = {
      id: site.id,
      account_id: site.accountId,
      name: site.name,
      parcels: site.versions[0].parcels.map(p => serialize(p)),
      available_cohorts: site.availableCohorts.map(c => serialize(c)),
      initial_lat: site.initialLat,
      initial_lng: site.initialLng,
      programs: site.programs.map(p => serialize(p)),
      other_programs: site.otherPrograms,
      has_restrictions: site.hasRestriction,
    };

    this.siteService.updateSite(site.id, update_site).subscribe(updated_proj => {
      this.store.dispatch(
        setActiveSiteSuccessWithAvailableCohorts({
          activeSite: updated_proj,
          availableCohorts: updated_proj.availableCohorts,
        })
      );
      this.dialogRef.close(true);
    });
  }
}
