import { Base } from './base.model';

export class ProspectiveUser extends Base {
  public firstName: string;
  public lastName: string;
  public phone: string;
  public email: string;
  public zipCode: string;
  public acreage: string;
  public userType: string;
  public otherText: string;
  public howDidYouHearAbout: string;
}
