import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

export interface AppConfigModel {
  id: number;
  key: string;
  value: string;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  apiUrl = environment.apiUrl + '/app_config/';

  constructor(readonly http: HttpClient) {}

  getAllAppConfig(): Observable<AppConfigModel[]> {
    return this.http.get<AppConfigModel[]>(this.apiUrl);
  }

  updateAppConfig(appConfig: AppConfigModel): Observable<AppConfigModel> {
    return this.http.put(`${this.apiUrl}`, appConfig).pipe(map((response: AppConfigModel) => response));
  }
}
