import { HttpClient } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { User } from '../../shared/models';
import * as fromApp from '../../store';
import { selectActAsUser } from '../../store/auth/auth.selectors';
import { resetNotifications, setLandOwnerNotifications } from '../../store/notification/notification.actions';

export interface NotificationPaymentSetupRequired {
  required: boolean;
  count: number;
}

export interface NotificationsAdmin {
  failedVerificationUsersCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  destroyRef = inject(DestroyRef);
  apiUrl = environment.apiUrl;
  notificationsUrl = `${this.apiUrl}/notifications`;

  constructor(readonly http: HttpClient, readonly store: Store<fromApp.AppState>) {}

  initNotificationChecks = () => {
    this.store
      .select(selectActAsUser)
      .pipe(
        mergeMap((user: User) => {
          if (user) {
            const paymentsRequire: Observable<NotificationPaymentSetupRequired> = this.getPaymentSetupRequired();
            const stripeAccountRequiresAction = of(
              user.stripeAccounts.filter(sa => sa.isStripeAccountIssue).length > 0
            );
            return forkJoin([paymentsRequire, stripeAccountRequiresAction, of(user.id)]);
          } else {
            return of([]);
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((resultList: any) => {
        if (resultList && resultList.length > 0) {
          // Add multiple Actions here to an array
          this.store.dispatch(
            setLandOwnerNotifications({
              data: {
                count: resultList[0].count,
                required: resultList[1],
                userId: resultList[2],
              },
            })
          );
        } else {
          // If user isn't logged in, reset all notifications to initial state
          this.store.dispatch(resetNotifications());
        }
      });
  };

  /**
   * Checks if any sites are in or passed approval state and stripe account is not set up
   */
  getPaymentSetupRequired(): Observable<NotificationPaymentSetupRequired> {
    return this.http.get<NotificationPaymentSetupRequired>(`${this.apiUrl}/payments/payment_setup_required`);
  }

  getAdminNotifications(): Observable<NotificationsAdmin> {
    return this.http.get<NotificationsAdmin>(`${this.notificationsUrl}/admin`);
  }
}
