<div mat-dialog-title class="change-site-state">
  Split Site
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p>
    Please upload a KML that will be used to for ONE of the new sites. The other site will encompass everything NOT
    found in this KML:
  </p>
  <form [formGroup]="kmlDocSelectForm" class="site-form">
    <div class="kml-upload-button-container">
      <ng-container>
        <fc-datepicker
          [minDate]="minEffectiveDate"
          [maxDate]="maxEffectiveDate"
          formControlName="effectiveDate"
          formFieldClass="w-100-1"
          inputID="effectiveDate"
          label="Effective Date"></fc-datepicker>
      </ng-container>
      <ng-container>
        <button
          (click)="onClickKmlUpload()"
          class="kml-button"
          data-cy="kml-upload-button"
          color="primary"
          mat-raised-button
          type="button">
          {{ uploadKmlFileName ? 'Re-' : '' }}Upload KML
        </button>
      </ng-container>
      <ng-container *ngIf="uploadKmlFileName">
        <p class="kml-file-name">Selected file: {{ uploadKmlFileName }}</p>
      </ng-container>
    </div>
    <input
      #kmlFileUpload
      accept=".kml,.kmz"
      id="kmlFileUpload"
      name="kmlFileUpload"
      style="display: none"
      type="file" />
  </form>
</div>
<div mat-dialog-actions>
  <button
    data-cy="button-yes"
    mat-raised-button
    color="primary"
    [disabled]="!this.kmlDocSelectForm.valid"
    class="green-shadow-button"
    (click)="applyKML()">
    Apply
  </button>
</div>
