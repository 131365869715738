<fc-progress-bar currentStep="0"></fc-progress-bar>
<div class="container">
  <div class="main">
    <div class="form-title">
      <span class="title-text">BIG OPPORTUNITIES&nbsp;</span>
      <span class="small-landowners">FOR SMALL LANDOWNERS</span>
    </div>

    <div class="landowners-subtitle">
      Now, landowners with as little as 40 acres will be able to participate in the growing carbon offset market
    </div>

    <router-outlet></router-outlet>
  </div>
</div>
