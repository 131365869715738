<div mat-dialog-title class="change-site-state">
  Document Details
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p>Document to upload: {{ data.filename }}</p>
  <form [formGroup]="docUploadForm" class="doc-form">
    <mat-form-field>
      <mat-select class="doc-type-select" formControlName="docType" placeholder="Document type">
        <mat-option *ngFor="let docType of docTypes" [value]="docType.value">
          {{ docType.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="description">
      <input type="text" placeholder="Brief Description" matInput formControlName="description" />
    </mat-form-field>
    @if (isSiteDetailsScreen && !isCurrentUserAdmin) {
    <mat-form-field class="comments w-100-1">
      <textarea matInput placeholder="Comments" formControlName="comments" rows="4"></textarea>
    </mat-form-field>
    }
  </form>
</div>
<div mat-dialog-actions>
  <button color="primary" mat-stroked-button [mat-dialog-close]="false" data-cy="doc-cancel">Cancel</button>
  <button
    color="primary"
    mat-raised-button
    (click)="saveDocument()"
    [disabled]="!docUploadForm.valid"
    data-cy="doc-save">
    Save
  </button>
</div>
