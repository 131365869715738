import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';

import { IneligibleAreaUserService } from '../../../core/api/ineligible-area-user.service';
import { UserService } from '../../../core/api/user.service';
import * as fromApp from '../../../store';
import { clearPreferredBuyer } from '../../../store/buyer/buyer.actions';
import { RegexConstants } from '../../constants/regex.constants';
import { SpinnerService } from '../../spinner/spinner.service';
import { DialogYesNoComponent } from '../dialog-yes-no/dialog-yes-no.component';

@Component({
  selector: 'fc-dialog-county-availability',
  templateUrl: './dialog-county-availability.component.html',
  styleUrls: ['./dialog-county-availability.component.scss'],
})
export class DialogCountyAvailabilityComponent {
  errorMessage?: string;
  county: string = '';
  priceString: string = null;
  goodNews: boolean = false;
  ineligibleAreaUserCreate: boolean = false;
  referrerCode: number = null;
  returnEmail: string = null;
  signupForm: UntypedFormGroup;
  size: string = '';
  state: string = '';

  constructor(
    readonly userService: UserService,
    readonly ineligibleAreaUserService: IneligibleAreaUserService,
    readonly store: Store<fromApp.AppState>,
    readonly spinnerService: SpinnerService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogCountyAvailabilityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.signupForm = this.formBuilder.group({
      email: [data.email, [Validators.maxLength(254), Validators.pattern(RegexConstants.EMAIL), Validators.required]],
    });
    this.goodNews = data.goodNews;
    this.size = data.size;
    this.state = data.state;
    this.county = data.county;
    this.priceString = data.priceString;
    this.referrerCode = data.referrerCode;
  }

  handleNewUser(email: string) {
    if (this.goodNews) {
      // Create a user account if the user's property is in an eligible area
      this.userService
        .requestUserOpen(email, this.referrerCode)
        .pipe(finalize(() => this.spinnerService.hide('app-spinner')))
        .subscribe(() => {
          this.returnEmail = email;
        });
    } else if (this.ineligibleAreaUserCreate) {
      // The user's property is in an ineligible area, so create an ineligible area user
      this.spinnerService.hide('app-spinner');
      this.dialogRef.close(null);
    } else {
      const ineligibleAreaUser = {
        email,
        address: '',
        coordinates: '',
        parcel_id: '',
        site_size: this.size,
        state: this.state,
        county: this.county,
        referrer_code: this.referrerCode,
      };
      this.ineligibleAreaUserService
        .createIneligibleAreaUser(ineligibleAreaUser)
        .pipe(finalize(() => this.spinnerService.hide('app-spinner')))
        .subscribe(
          () => this.store.dispatch(clearPreferredBuyer()),
          () => console.log('ERROR creating ineligible area user'),
          () => {}
        );
      this.ineligibleAreaUserCreate = true;
    }
  }

  onYesClick(): void {
    this.spinnerService.show('app-spinner');
    const email = this.signupForm.get('email').value;
    this.errorMessage = undefined;
    this.userService.exists(email).subscribe(
      (exists: boolean) => {
        if (exists) {
          this.spinnerService.hide('app-spinner');
          this.dialog
            .open(DialogYesNoComponent, {
              panelClass: 'fc-yes-no-dialog',
              data: {
                title: 'A user with this email already exists',
                buttonTextYes: 'Login',
                buttonTextNo: 'Cancel',
              },
            })
            .afterClosed()
            .subscribe(result => {
              if (result) {
                this.dialogRef.close(null);
                this.router.navigate(['/auth'], { queryParams: { email } });
              }
            });
        } else {
          this.handleNewUser(email);
        }
      },
      () => {
        this.errorMessage = 'An invalid email address was submitted';
        this.spinnerService.hide('app-spinner');
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close(this.returnEmail);
  }
}
