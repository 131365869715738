import { Component, Input } from '@angular/core';

@Component({
  selector: 'fc-dashboard-banner',
  templateUrl: './dashboard-banner.component.html',
  styleUrls: ['./dashboard-banner.component.scss'],
})
export class DashboardBannerComponent {
  @Input()
  totalSitesAcres: number = 0;

  @Input()
  totalSitesValue: number = 0;

  @Input()
  showTotals: boolean = true;
}
