import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(fromAuth.authFeatureKey);

export const selectCurrentUser = createSelector(selectAuthState, auth => auth.currentUser);

export const selectActAsUser = createSelector(selectAuthState, auth =>
  auth.actAsUser ? auth.actAsUser : auth.currentUser
);

export const selectAuthError = createSelector(selectAuthState, auth => auth.error);
