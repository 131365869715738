<div class="fc-back-link" routerLink="/">
  <fa-icon [icon]="['far', 'arrow-left']" class="gray"></fa-icon>
  <span>Back</span>
</div>
<div class="page-container container">
  <div class="page-title">Contact Us</div>
  <div class="contact-us">
    For any questions on using the platform, please contact us here:
    <a href="mailto:{{ contactEmail }}" target="_blank" rel="noopener noreferrer">
      {{ contactEmail }}
    </a>
  </div>
  <div class="page-title">FAQ</div>
  <div class="questions-container">
    <fc-faq></fc-faq>
  </div>
</div>
