import { isEmpty } from 'lodash';

import { Base } from './base.model';
import { Buyer } from './buyer.model';
import { Role } from './role.model';
import { StripeAccount } from './stripe-account.model';
import { UserAdminBuyer } from './user-admin-buyer.model';

import { RoleEnum } from '../constants/role.enums';
import { Type } from '../decorators/type.decorator';

export class User extends Base {
  @Type(UserAdminBuyer) public adminBuyers: UserAdminBuyer[];
  public adminInvite: boolean;
  public city: string;
  public cognitoSubject: string;
  public createdAt: Date;
  public dateOfBirth: Date;
  @Type(User) public delegate: User;
  @Type(User) public landowners: User[];
  public delegateId: number;
  public driversLicenseNum: string;
  public driversLicenseState: string;
  public email: string;
  public firstName: string;
  public company: string;
  public title: string;
  public howDidYouHearAbout: string;
  public identifier: string;
  public isActive: boolean;
  public isVerified: boolean;
  public landownerId: number;
  public lastLogin: Date;
  public lastName: string;
  public mfaEnabled: boolean;
  public needsTruliooVerification: boolean;
  public numSubmittedSites: number;
  public submittedStatus: string;
  public phone: string;
  @Type(Buyer) public preferredBuyer: Buyer;
  public preferredBuyerId: number;
  @Type(User) public rejectedBy: User;
  @Type(Role) public roles: Role[];
  public ssn: string;
  public state: string;
  public street1: string;
  public street2: string;
  @Type(StripeAccount) public stripeAccounts: StripeAccount[];
  public truliooAttempts: number;
  public truliooRecordId: string;
  public validationAttemptDate: Date;
  @Type(User) public verifiedBy: User;
  public zip: string;
  public flaggedProfile: boolean;

  hasRole(role: string) {
    if (this.roles) {
      return this.roles.filter(r => r.getName() === role).length > 0;
    }
    return false;
  }

  getDisplayName({ reverse = false, initials = false, includeEmail = false } = {}) {
    if (this.email) {
      if (initials) {
        if (this.firstName == null) {
          return this.email.substring(0, 2).toUpperCase();
        } else if (this.lastName == null) {
          return this.firstName.substring(0, 2).toUpperCase();
        } else {
          return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
        }
      }
      let name = null;
      if (this.firstName == null) {
        name = '--';
      } else if (this.lastName == null) {
        name = this.firstName;
      } else {
        name = reverse ? `${this.lastName}, ${this.firstName}` : `${this.firstName} ${this.lastName}`;
      }
      return includeEmail ? `${name} <${this.email}>` : name;
    }
    return '';
  }

  hasStripe(buyerId: number): boolean {
    if (buyerId && this.stripeAccounts) {
      const account = this.stripeAccounts.find(sa => sa.buyerId === buyerId);
      return !isEmpty(account) && !isEmpty(account.stripeAccountCreationDate);
    }
    return false;
  }

  getAccountFromToken(token: string): StripeAccount {
    if (this.stripeAccounts) {
      const accounts = this.stripeAccounts.filter(sa => sa.stripeAccountCreationToken === token);
      return accounts.length > 0 ? accounts[0] : null;
    }
    return null;
  }

  get isLimitedAdmin() {
    return this.hasRole(RoleEnum.LimitedAdmin) || this.hasRole(RoleEnum.FinanceAdmin);
  }

  get isAdmin() {
    return this.hasRole(RoleEnum.Admin) || this.hasRole(RoleEnum.SuperAdmin);
  }

  get isSuperAdmin(): boolean {
    return this.hasRole(RoleEnum.SuperAdmin);
  }

  get isLandowner(): boolean {
    return this.hasRole(RoleEnum.Landowner);
  }

  get canPaySites() {
    return this.hasRole(RoleEnum.FinanceAdmin) || this.hasRole(RoleEnum.SuperAdmin);
  }

  get isManuallyVerified(): boolean {
    return this.isVerified && this.verifiedBy !== null;
  }

  get isSystemVerified(): boolean {
    return this.isVerified && this.truliooRecordId && this.verifiedBy == null;
  }
}
