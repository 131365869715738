/**
 * This model was autogenerated by generate_ts_model.py
 **/
/* eslint-disable max-len */

import { Base } from './base.model';

export class EmailTemplate extends Base {
  public body?: string;
  public description?: string;
  public name?: string;
  public subject?: string;
  public requiredSubstitution?: string[];
  public optionalSubstitution?: string[];
}
