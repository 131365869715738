<div class="stand-item-container" (click)="centerOnStand()">
  <div class="stand-layout {{ clickable ? 'clickable' : '' }}">
    <div class="stand-svg-container" #dataContainer></div>
    <div class="stand-data">
      <div class="tiny-text">Stand {{ index + 1 }}</div>
      <div class="value-text">
        Estimated Annual Value
        <span class="value-amount">{{ getValuation() }}</span>
      </div>
      <div class="med-text">Forested Acres: {{ acreage }}</div>
    </div>
  </div>
  <mat-divider *ngIf="allowRemove"></mat-divider>
  <div *ngIf="allowRemove" (click)="deleteStand()" class="remove-stand tiny-text">
    <fa-icon [icon]="['far', 'trash']" class="gray size-12"></fa-icon>
    Remove
  </div>
</div>
