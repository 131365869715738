import { createAction, props } from '@ngrx/store';

import { MapCenter } from './parcel.reducer';

import { Parcel, Stand } from '../../shared/models';

export const loadParcels = createAction('[Parcel] Load Parcels');

export const loadParcelsSuccess = createAction('[Parcel] Load Parcels Success', props<{ data: any }>());

export const loadParcelsFailure = createAction('[Parcel] Load Parcels Failure', props<{ error: any }>());

export const setMapCenter = createAction('[Parcel] Set map center lat/lng', props<{ data: MapCenter }>());

export const setSelectedParcels = createAction('[Parcel] Set selected parcels', props<{ data: any }>());

export const setUnsavedChanges = createAction(
  '[Parcel] Set unsaved changes',
  props<{ data: { unsavedChanges: boolean } }>()
);

export const addSelectedStand = createAction('[Parcel] Add selected stand', props<{ data: { stand: Stand } }>());

export const removeParcel = createAction('[Parcel] Remove A Parcel', props<{ gid: number }>());

export const resetParcels = createAction(
  '[Parcel] Reset All Data',
  props<{
    data: {
      parcels: Array<Parcel>;
      stands: Array<Stand>;
      unsavedChanges: boolean;
    };
  }>()
);

export const resetStands = createAction('[Parcel] Reset Selected Stands', props<{ data: { stands: Array<Stand> } }>());

export const setStepOne = createAction(
  '[Parcel] Set step one: email and acres',
  props<{ data: { email: string; acres: string } }>()
);

export const updateSelectedStand = createAction('[Parcel] Update selected stand', props<{ data: any }>());

export const deleteSelectedStand = createAction('[Parcel] Delete selected stand', props<{ data: any }>());

export const setForestryKml = createAction('[Parcel] Set selected forestry KML', props<{ data: Array<any> }>());
export const deleteForestryKml = createAction('[Parcel] Delete selected forestry KML');

export const clearAll = createAction('[Parcel] Clear All');

export const setSelectedRegion = createAction('[Parcel] Set selected region', props<{ data: any }>());

export const setLocationType = createAction('[Parcel] Set The Parcel Location Type', props<{ data: any }>());

export const selectAllStands = createAction('[Parcel] Select All Stands', props<{ data: any }>());

export const selectAllStandsSuccess = createAction('[Parcel] Select All Stands Success', props<{ data: any }>());

export const removeRedFlagSuccess = createAction(
  '[Parcel] Remove All Redflag Success',
  props<{ stands: Array<any> }>()
);

export const removeRedFlagFailure = createAction('[Parcel] Remove All Redflag Failure', props<{ error: any }>());

export const selectAllStandsFailure = createAction('[Parcel] Select All Stands Failure', props<{ error: any }>());

export const clearCreateSiteStepTwo = createAction('[Parcel] Clear Create Site Step Two');

export const clearCreateSiteStepThree = createAction('[Parcel] Clear Create Site Step Three');

export const toggleParcelSelectLock = createAction(
  '[Parcel] Toggle the Parcel Select Lock',
  props<{ lock: boolean }>()
);
