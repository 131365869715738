import { createAction, props } from '@ngrx/store';

export const getAllDocumentTypes = createAction('[Document] Get All Document Types');

export const getAllDocumentTypesSuccess = createAction(
  '[Document] Get All Document Types Success',
  props<{ data: any }>()
);

export const getAllDocumentTypesFailure = createAction(
  '[Document] Get All Document Types Failure',
  props<{ error: any }>()
);

export const clearAllDocument = createAction('[Document] Clear All Document');

export const getDocuments = createAction('[Document] Get Documents', props<{ identifier: string }>());

export const getDocumentsSuccess = createAction('[Document] Get Documents Success', props<{ data: any }>());

export const getDocumentsFailure = createAction('[Document] Get Documents Failure', props<{ error: any }>());

export const createDocument = createAction(
  '[Document] Create Document',
  props<{ siteIdentifier: string; document: any; file: File }>()
);

export const createDocumentSuccess = createAction('[Document] Create Document Success', props<{ data: any }>());

export const createDocumentFailure = createAction('[Document] Create Document Failure', props<{ error: any }>());

export const saveDocument = createAction('[Document] Save Documents', props<{ document: any }>());

export const saveDocumentSuccess = createAction('[Document] Save Documents Success', props<{ data: any }>());

export const saveDocumentFailure = createAction('[Document] Save Documents Failure', props<{ error: any }>());

export const deleteDocument = createAction(
  '[Document] Delete Document',
  props<{ siteIdentifier: string; identifier: string }>()
);

export const deleteDocumentSuccess = createAction('[Document] Delete Documents Success', props<{ data: any }>());

export const deleteDocumentFailure = createAction('[Document] Delete Documents Failure', props<{ error: any }>());
