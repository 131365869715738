import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  submitTimes: number;
}

@Component({
  selector: 'fc-dialog-manual-id',
  templateUrl: './dialog-manual-id.component.html',
  styleUrls: ['./dialog-manual-id.component.scss'],
})
export class DialogManualIDComponent {
  submitTimes: number = 0;

  constructor(
    public dialogRef: MatDialogRef<DialogManualIDComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.submitTimes = data.submitTimes;
  }

  onButtonClick(value: string): void {
    this.dialogRef.close(value);
  }
}
