import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

export interface SpinnerInterface {
  color?: ThemePalette;
  fullScreen?: boolean;
  mode?: ProgressSpinnerMode;
  overlay?: boolean;
  strokeWidth?: number;
  value?: number;
  zIndex?: number;
  enhanced?: boolean;
  text?: string;
}

export class Spinner {
  color: ThemePalette;
  fullScreen: boolean;
  mode?: ProgressSpinnerMode;
  name: string;
  overlay: boolean;
  show: boolean;
  strokeWidth?: number;
  value?: number;
  zIndex: number = 10000;
  enhanced: boolean = false;
  text: string = '';

  constructor(spinner?: Partial<Spinner>) {
    Object.assign(this, spinner);
  }

  static create(spinner?: Partial<Spinner>): Spinner {
    return new Spinner(spinner);
  }
}
