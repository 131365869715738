import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as turf from '@turf/turf';
import geojson2svg from 'geojson-to-svg';
import { startCase } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { ConstantsService } from './constants.service';

import { environment } from '../../../environments/environment';
import { Parcel, Stand, Strata } from '../../shared/models';
import { deserialize } from '../../shared/models/base-helper';
import { Base } from '../../shared/models/base.model';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { FormatHelperService } from '../helper/format-helper.service';

export interface CustomAreaValuationStandReq {
  region_id: number;
  geometry: string;
  min_stand_acres: number;
  site_id: number;
  current_stands: Array<Stand>;
}

export interface CustomStand {
  id: number | string;
  type: 'Feature';
  geometry: {
    type: string;
    coordinates: Array<any>;
  };
  properties: any;
}

export interface CustomAreaValuationStandArrayBackendResp {
  results: Array<CustomStand>;
  error: string;
}

export interface CustomAreaValuationStandArrayServiceResp {
  results: Array<Stand>;
  error: string;
}

export interface ParcelNumberList {
  gid: number;
  parcelnumb: string;
  center: {
    type: string;
    coordinates: [number, number];
  };
}

export interface ParcelNumberListDeSerialized {
  id: number;
  parcelNumber: string;
  coordinates: [number, number];
}

export class CountyInfo extends Base {
  county: string;
  gid: number;
  state: string;
  averageErt: string;
}

@Injectable({
  providedIn: 'root',
})
export class GisService {
  apiUrl = environment.apiUrl + '/gis/';

  constructor(
    readonly http: HttpClient,
    readonly spinnerService: SpinnerService,
    public formatHelper: FormatHelperService
  ) {}

  getParcelListLikeParcelNumber(parcelNumber: string, limit: number): Observable<Array<ParcelNumberListDeSerialized>> {
    let filters = new HttpParams();

    filters = filters.append('parcel_number', parcelNumber);
    if (limit) {
      filters = filters.append('limit', limit.toString());
    }

    return this.http
      .get<Array<ParcelNumberList>>(`${this.apiUrl}get_parcel_list_like_parcel_number`, { params: filters })
      .pipe(
        map((response: Array<ParcelNumberList>) =>
          response.map((parcelItem: any) => {
            return {
              id: parcelItem.gid,
              parcelNumber: parcelItem.parcelnumb,
              coordinates: [parcelItem.center.coordinates[0], parcelItem.center.coordinates[1]],
              county: startCase(parcelItem.county),
            };
          })
        )
      );
  }

  getCountyFilteredList(county: string, limit: number): Observable<Array<CountyInfo>> {
    let filters = new HttpParams();

    filters = filters.append('county', county);
    if (limit) {
      filters = filters.append('limit', limit.toString());
    }

    return this.http
      .get<Array<CountyInfo>>(`${this.apiUrl}get_county_filtered_list`, {
        params: filters,
      })
      .pipe(map((response: Array<CountyInfo>) => deserialize(response, CountyInfo)));
  }

  getOverlappingStands(siteId: number) {
    let filters = new HttpParams();
    filters = filters.append('site_id', siteId.toString());
    return this.http.get<string[]>(`${this.apiUrl}get_stand_overlap`, { params: filters }).toPromise();
  }

  correctOverlappingStands(site_id: number): Observable<CustomAreaValuationStandArrayServiceResp> {
    return this.http.post<any>(`${this.apiUrl}crop_stand_overlap/${site_id}`, {}).pipe(
      map((response: any) => {
        let standsList = [];
        if (response.results) {
          standsList = response.results.map(stand => {
            stand.feature.id = stand.custom_id;
            return stand;
          });
        }
        return {
          results: standsList,
          error: response.error,
        };
      })
    );
  }

  getAreaValuationAndGeoJsonStandsCroppedByParcel(
    lng: string,
    lat: string,
    id: number,
    regionId: number,
    siteId: number,
    currentStands: Stand[] = []
  ): Observable<CustomAreaValuationStandArrayServiceResp> {
    let filters = new HttpParams();

    filters = filters.append('lng', lng.toString());
    filters = filters.append('lat', lat.toString());
    filters = filters.append('region_id', regionId.toString());
    filters = filters.append('min_stand_acres', ConstantsService.MIN_STAND_ACRES.toString());
    let paramStand = [];
    currentStands.forEach(stand => {
      paramStand.push({
        custom_id: stand.customId,
        area: stand.area,
        valuation: stand.valuation.toString().split(',')[0],
      });
    });
    filters = filters.append('current_stands', JSON.stringify(paramStand));
    filters = filters.append('site_id', siteId);

    return this.http
      .get<CustomAreaValuationStandArrayBackendResp>(`${this.apiUrl}crop_stand_by_parcel/${id}`, { params: filters })
      .pipe(
        map((response: CustomAreaValuationStandArrayBackendResp) => {
          let standList = [];
          if (response.results) {
            standList = response.results.map(s => this.constructStand(s));
          }
          return {
            results: standList,
            error: response.error,
          };
        }),
        catchError(error => {
          return of(error);
        })
      );
  }

  getCustomAreaValuationOfStand(
    regionId: number,
    geometry: any,
    siteId: number,
    currentStands: Stand[] = []
  ): Observable<CustomAreaValuationStandArrayServiceResp> {
    let paramStand = [];
    currentStands.forEach(stand => {
      let clonedStand = new Stand();
      clonedStand.customId = stand.customId;
      clonedStand.area = stand.area;
      clonedStand.valuation = stand.valuation.toString().split(',')[0];
      paramStand.push(clonedStand);
    });
    const body: CustomAreaValuationStandReq = {
      region_id: regionId,
      geometry: JSON.stringify(geometry),
      min_stand_acres: ConstantsService.MIN_STAND_ACRES,
      site_id: siteId,
      current_stands: paramStand,
    };

    return this.http
      .post<CustomAreaValuationStandArrayBackendResp>(`${this.apiUrl}get_custom_polygon_valuation`, body)
      .pipe(
        map((response: CustomAreaValuationStandArrayBackendResp) => {
          let standList = [];
          if (response.results) {
            standList = response.results.map(s => this.constructStand(s));
          }
          return {
            results: standList,
            error: response.error,
          };
        })
      );
  }

  constructStand(customStand: CustomStand): Stand {
    const properties = customStand.properties;

    const newCustomId = uuidv4().toLowerCase();
    customStand.id = newCustomId;

    const strataList: Array<string> = properties.strata_list.split(',');
    const areaList: Array<number> = properties.area_list.split(',').map(v => parseFloat(v));
    const valuationList: Array<number> = properties.valuation_list.split(',').map(v => parseFloat(v));

    const stratas: Array<Strata> = [];
    let stand: Stand = null;
    for (let index = 0; index < strataList.length; index++) {
      const strata: Strata = new Strata({
        strata: strataList[index],
        area: areaList[index],
        valuation: valuationList[index],
      });
      stratas.push(strata);
      delete customStand.properties.strata_list;
      delete customStand.properties.area_list;
      delete customStand.properties.valuation_list;

      stand = new Stand({
        id: newCustomId,
        customId: newCustomId,
        parcelId: properties.parcel_id,
        parcelGid: properties.parcel_gid,
        area: properties.area,
        valuation: properties.valuation,
        pricingData: properties.simulated_financials,
        priceValuation: this.formatHelper.formatMinMaxValuation(
          properties.simulated_financials.annual_valuation.map(obj => obj.valuation)
        ),
        feature: customStand,
        stratas,
      });
      stand.svg = this.getGeomSVG(stand.feature);
    }
    delete stand.feature.properties.simulated_financials;
    return stand;
  }

  getGeomSVG(feature: any) {
    const boundingBox = turf.bbox(feature.geometry);
    const boundingBoxSquare = turf.square(boundingBox);
    return geojson2svg()
      .styles({
        MultiPolygon: { fill: '#F5BD02' },
        Polygon: { fill: '#F5BD02' },
      })
      .extent(boundingBoxSquare)
      .data(feature)
      .render();
  }

  cutAreaFromSelectedStands(
    regionId: number,
    geometry: any,
    siteId: number,
    currentGeom: any
  ): Observable<CustomAreaValuationStandArrayServiceResp> {
    let paramStand = [];
    paramStand.push({
      feature: JSON.stringify(currentGeom),
    });
    const body: CustomAreaValuationStandReq = {
      region_id: regionId,
      geometry: JSON.stringify(geometry),
      min_stand_acres: ConstantsService.MIN_STAND_ACRES,
      site_id: siteId,
      current_stands: paramStand,
    };

    return this.http
      .post<CustomAreaValuationStandArrayBackendResp>(`${this.apiUrl}cut_area_from_selected_stands`, body)
      .pipe(
        map((response: CustomAreaValuationStandArrayBackendResp) => {
          let standList = [];
          if (response.results) {
            standList = response.results.map(s => this.constructStand(s));
          }
          return {
            results: standList,
            error: response.error,
          };
        })
      );
  }

  selectAllStands(regionId: number, siteId: number): Observable<CustomAreaValuationStandArrayServiceResp> {
    const body = {
      region_id: regionId,
      site_id: siteId,
    };
    this.spinnerService.show('app-spinner', true, 'Please Wait While All Of Your Property Is Calculated');
    return this.http.post<CustomAreaValuationStandArrayBackendResp>(`${this.apiUrl}select_all_stands`, body).pipe(
      map((response: CustomAreaValuationStandArrayBackendResp) => {
        let standList = [];
        if (response.results) {
          standList = response.results.map(s => this.constructStand(s));
        }
        this.spinnerService.hide('app-spinner');
        return {
          results: standList,
          error: response.error,
        };
      }),
      catchError(error => {
        this.spinnerService.hide('app-spinner');
        return of(error);
      })
    );
  }

  removePublicOverlap(site_id: number): Observable<CustomAreaValuationStandArrayServiceResp> {
    return this.http.post<any>(`${this.apiUrl}remove_public_overlap/${site_id}`, {}).pipe(
      map((response: CustomAreaValuationStandArrayBackendResp) => {
        let standsList = [];
        if (response.results) {
          standsList = response.results.map(s => this.constructStand(s));
        }
        return {
          results: standsList,
          error: response.error,
        };
      })
    );
  }

  getParcelsByLatLng(lat: number = null, lng: number = null): Observable<Parcel> {
    let params = new HttpParams();

    if (lat && lng) {
      params = params.append('lat', lat.toString());
      params = params.append('lng', lng.toString());
    }

    return this.http.get<any>(`${this.apiUrl}get_parcel_by_lat_lng`, { params }).pipe(
      map(parcel => deserialize(parcel, Parcel)),
      catchError(error => {
        return of(error);
      })
    );
  }

  getFullPotentialValuation(siteId: number) {
    return this.http.get<any>(`${this.apiUrl}get_potential_valuation/${siteId}`).pipe(
      map((response: any) => {
        return response.results;
      })
    );
  }
}
