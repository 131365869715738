import { Base } from './base.model';
import { User } from './user.model';

import { Type } from '../decorators/type.decorator';

export class DisturbanceReport extends Base {
  siteId: number;
  disturbanceType: string;
  cause: string;
  impactPercentage: string;
  description: string;
  reviewedDate: Date;
  reviewedById: number;
  @Type(User) reviewedBy: User;
  createDate: Date;
}
