import { Base } from './base.model';
import { User } from './user.model';

import { Type } from '../decorators/type.decorator';

export class UserActivity extends Base {
  public event: string;
  public eventDetails: string;
  public eventTime: Date;
  public userId: number;
  @Type(User) public user: User;
}
