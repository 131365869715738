import { createAction, props } from '@ngrx/store';

export const clearAllReports = createAction('[Report] Clear All Reports');

export const getReportList = createAction('[Report] Get List Of Reports For A Site', props<{ siteId: number }>());

export const getReportListSuccess = createAction(
  '[Report] Get List Of Reports For A Site Success',
  props<{ data: any }>()
);

export const getReportListFailure = createAction(
  '[Report] Get List Of Reports For A Site Failure',
  props<{ error: any }>()
);

// Periodic Report

export const getPeriodicReport = createAction('[Report] Get Periodic Report', props<{ id: number }>());

export const getPeriodicReportSuccess = createAction('[Report] Get Periodic Report Success', props<{ data: any }>());

export const getPeriodicReportFailure = createAction('[Report] Get Periodic Report Failure', props<{ error: any }>());

export const getPeriodicReports = createAction('[Report] Get Periodic Reports For A Site', props<{ siteId: number }>());

export const getPeriodicReportsSuccess = createAction(
  '[Report] Get Periodic Reports For A Site Success',
  props<{ data: any }>()
);

export const getPeriodicReportsFailure = createAction(
  '[Report] Get Periodic Reports For A Site Failure',
  props<{ error: any }>()
);

export const submitPeriodicReport = createAction(
  '[Report] Submit A Periodic Report For A Site',
  props<{ siteId: number; report: any }>()
);

export const submitPeriodicReportSuccess = createAction(
  '[Report] Submit A Periodic Report For A Site Success',
  props<{ data: any }>()
);

export const submitPeriodicReportFailure = createAction(
  '[Report] Submit A Periodic Report For A Site Failure',
  props<{ error: any }>()
);

// Disturbance Report

export const getDisturbanceReport = createAction('[Report] Get Disturbance Report', props<{ id: number }>());

export const getDisturbanceReportSuccess = createAction(
  '[Report] Get Disturbance Report Success',
  props<{ data: any }>()
);

export const getDisturbanceReportFailure = createAction(
  '[Report] Get Disturbance Report Failure',
  props<{ error: any }>()
);

export const getDisturbanceReports = createAction(
  '[Report] Get Disturbance Reports For A Site',
  props<{ siteId: number }>()
);

export const getDisturbanceReportsSuccess = createAction(
  '[Report] Get Disturbance Reports For A Site Success',
  props<{ data: any }>()
);

export const getDisturbanceReportsFailure = createAction(
  '[Report] Get Disturbance Reports For A Site Failure',
  props<{ error: any }>()
);

export const submitDisturbanceReport = createAction(
  '[Report] Submit A Disturbance Report For A Site',
  props<{ siteId: number; report: any }>()
);

export const submitDisturbanceReportSuccess = createAction(
  '[Report] Submit A Disturbance Report For A Site Success',
  props<{ data: any }>()
);

export const submitDisturbanceReportFailure = createAction(
  '[Report] Submit A Disturbance Report For A Site Failure',
  props<{ error: any }>()
);
