<div mat-dialog-title>
  <div>YOU MAY QUALIFY FOR A DIFFERENT PRODUCT</div>
  <div (click)="onNoClick()" class="close-icon-container">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <form
    *ngIf="!premiumAcreageUserCreate; else showConfirmation"
    (keydown.enter)="$event.preventDefault()"
    [formGroup]="signupForm">
    <p class="line-1">
      Based on the information you provided, your property is not eligible for CORE Carbon, but may be eligible for a
      program dedicated to large landowners.
    </p>
    <p>Would you like us to contact you and discuss your options in participating in a forest carbon site?</p>
    <div class="email-label">Email</div>
    <mat-form-field class="full-width">
      <input formControlName="email" id="email" matInput type="email" />
    </mat-form-field>
    <fc-email-form-errors [emailFormControl]="signupForm.controls.email"></fc-email-form-errors>
  </form>
  <ng-template #showConfirmation>
    <p class="line-1">
      Thank you for your information, our team will be in touch with you soon about additional opportunities.
    </p>
  </ng-template>

  <div mat-dialog-actions>
    <button
      *ngIf="!premiumAcreageUserCreate"
      (click)="onNoClick()"
      color="primary"
      data-cy="button-no"
      mat-stroked-button>
      No, Thank You
    </button>
    <button
      (click)="onYesClick()"
      [disabled]="!signupForm.get('email').value"
      color="primary"
      data-cy="button-yes"
      mat-raised-button>
      {{ premiumAcreageUserCreate ? 'Close' : 'Yes, Contact Me' }}
    </button>
  </div>
</div>
