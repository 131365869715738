import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Account } from '../../shared/models';
import { deserialize } from '../../shared/models/base-helper';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  apiUrl = environment.apiUrl + '/accounts/';

  constructor(readonly http: HttpClient) {}

  getMyAccount(): Observable<Account> {
    return this.http.get<any>(`${this.apiUrl}me`).pipe(
      map((account: any) => {
        return deserialize(account, Account);
      })
    );
  }
}
