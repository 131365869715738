import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConnectivityComponent } from './connectivity/connectivity.component';

import { SharedModule } from '../../shared';

@NgModule({
  declarations: [ConnectivityComponent],
  imports: [CommonModule, SharedModule],
})
export class DiagnosticsModule {}
