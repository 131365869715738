<mat-stepper [linear]="true" #stepper [selectedIndex]="currentStep">
  <mat-step [editable]="false" [completed]="stepsComplete[0]">
    <ng-template matStepLabel>Site Definition</ng-template>
  </mat-step>
  <mat-step [editable]="false" [completed]="stepsComplete[1]">
    <ng-template matStepLabel>Parcel Selection</ng-template>
  </mat-step>
  <mat-step [editable]="false" [completed]="stepsComplete[2]">
    <ng-template matStepLabel>Stand Selection</ng-template>
  </mat-step>
  <mat-step [editable]="false" [completed]="stepsComplete[3]">
    <ng-template matStepLabel>Eligibility</ng-template>
  </mat-step>
  <mat-step [editable]="false" [completed]="stepsComplete[4]">
    <ng-template matStepLabel>Offer</ng-template>
  </mat-step>
  <mat-step [editable]="false" [completed]="stepsComplete[5]">
    <ng-template matStepLabel>Documentation</ng-template>
  </mat-step>
  <mat-step [editable]="false" [completed]="false">
    <ng-template matStepLabel>Submit</ng-template>
  </mat-step>
</mat-stepper>
