<div class="container">
  <div class="create-account-side-panel"></div>
  <div class="main">
    <div class="create-account">
      <div class="header">Create Your Account</div>
      <div class="login-info-text">
        Please enter the temporary password from your confirmation email and then create and verify a new password to
        create your account.
      </div>

      <form (keydown.enter)="$event.preventDefault()" [formGroup]="createAccountForm">
        <mat-form-field class="w-100-1">
          <mat-label>Enter Temporary Password</mat-label>
          <input formControlName="tempPassword" id="tempPassword" matInput required type="password" />
        </mat-form-field>
        <mat-form-field class="w-100-1">
          <mat-label>Create New Password</mat-label>
          <input formControlName="password" id="password" matInput required type="password" />
        </mat-form-field>
        <mat-form-field class="w-100-1">
          <mat-label>Verify New Password</mat-label>
          <input formControlName="verifyPassword" id="verifyPassword" matInput required type="password" />
        </mat-form-field>
        @if (errorMessage) {
        <div class="message-error">
          {{ errorMessage }}
        </div>
        }
        <div class="password-rules">
          Your password should be at least 8 letters and contain at least one uppercase and a number
        </div>

        <div>
          <div class="add-a-delegate-header">Want to Add a Delegate to Your Account?</div>
          <div class="add-a-delegate-text">
            If you would like to delegate a forester or someone else to manage your offset sites on your behalf, you
            will be able to do so on your dashboard once your identity is verified.
          </div>
        </div>

        <mat-divider class="w-100-1"></mat-divider>
        <div class="t-and-c-text">To continue, please accept CORE Carbon's Terms and Conditions</div>
        <div class="t-and-c-section">
          <span>
            <mat-checkbox class="tac-check" formControlName="tacClicked">I have read and agree to the</mat-checkbox>
            <a
              class="fc-link tac-link"
              rel="noopener noreferrer"
              routerLink="/legal/terms-and-conditions"
              target="_blank">
              Terms and Conditions
            </a>
          </span>
          <span [style.visibility]="tacNeedsConfirmation ? 'visible' : 'hidden'" class="t-and-c-error">
            You must agree to the Terms and Conditions before continuing
          </span>
        </div>
        <mat-divider class="w-100-1"></mat-divider>
        <div class="w-100-1 button-container">
          <button (click)="goBack()" color="primary" mat-stroked-button>Cancel</button>
          <button (click)="onSubmit()" [disabled]="!createAccountForm.valid" color="primary" mat-raised-button>
            Next
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
