import { Base } from './base.model';

export class YieldCurve extends Base {
  strata: string;
  regionId: number;
  yearArray: string;
  version: number;

  getYearArrayAsArray(): Array<string> {
    if (this.yearArray) {
      return this.yearArray.split(',');
    }
  }
}
