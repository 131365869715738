import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'fc-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  stepsComplete = [false, false, false, false, false, false];

  @Input()
  currentStep: number;

  ngOnInit() {
    if (this.currentStep > 0) {
      for (var x = this.currentStep; x >= 0; x--) {
        this.stepsComplete[x] = true;
      }
    }
  }
}
