import { createAction, props } from '@ngrx/store';

import { AppConfigModel } from 'src/app/core/api/app-config.service';

export const mobileViewMaxScreenWidth = 834;

export const getAppConfig = createAction('[AppConfig] Get App Config');

export const getAppConfigSuccess = createAction('[AppConfig] Get App Config Success', props<{ data: any }>());

export const getAppConfigFailure = createAction('[AppConfig] Get App Config Failure', props<{ error: any }>());

export const setMobileViewMode = createAction('[AppConfig] Set Mobile View Mode', props<{ mobileViewMode: boolean }>());

export const updateAppConfig = createAction('[AppConfig] Update AppConfig', props<{ appConfig: AppConfigModel }>());

export const updateAppConfigSuccess = createAction('[AppConfig] Update AppConfig Success', props<{ data: any }>());

export const updateAppConfigFailure = createAction('[AppConfig] Update AppConfig Failure', props<{ error: any }>());
