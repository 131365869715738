import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import * as fromApp from '../../../store';
import { selectAppConfigState } from '../../../store/app-config/app-config.selectors';

export interface DialogData {}

@Component({
  selector: 'fc-dialog-terminate-site',
  templateUrl: './dialog-terminate-site.component.html',
  styleUrls: ['./dialog-terminate-site.component.scss'],
})
export class DialogTerminateSiteComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  contactEmail: string = '';
  contactPhone: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogTerminateSiteComponent>,
    readonly store: Store<fromApp.AppState>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectAppConfigState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(appConfig => {
        this.contactEmail = appConfig.config['fc_contact_email'];
        this.contactPhone = appConfig.config['fc_contact_phone'];
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
