import { Base } from './base.model';
import { Strata } from './strata.model';

import { Type } from '../decorators/type.decorator';

export class Stand extends Base {
  public area: number;
  public feature: any;
  public valuation: any;
  public gid: number;
  public siteVersionId: number;
  public svg: string;
  public customId: string;
  public clickedStandId: number;
  public priceValuation: string;
  public pricingData: any;
  @Type(Strata) public stratas: Strata[];
}
