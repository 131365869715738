import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  programName: string;
  aboutText?: string;
  addressTo?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  phone?: string;
  fax?: string;
  website?: string;
  email?: string;
}

@Component({
  selector: 'fc-dialog-contact-info',
  templateUrl: './dialog-contact-info.component.html',
  styleUrls: ['./dialog-contact-info.component.scss'],
})
export class DialogContactInfoComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogContactInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
