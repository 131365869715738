import { Base } from './base.model';
import { User } from './user.model';

import { Type } from '../decorators/type.decorator';

export class SiteStatusLog extends Base {
  public siteId: number;
  public oldStatus: string;
  public status: string;
  public changeDate: string;
  public changedById: number;
  @Type(User) public changedBy: User;
}
