import { Base } from './base.model';
import { User } from './user.model';

import { Type } from '../decorators/type.decorator';

export class SitePayment extends Base {
  siteId: number;
  paidAmountInCents: number;
  transactionId: string;
  userId: number;
  @Type(User) user: User;
  createDate: Date;
  error: string;
  message: string;

  getRecordedBy() {
    return this.user ? `Recorded by ${this.user.getDisplayName()}` : null;
  }
}
