export const FAQ = [
  {
    heading: 'THE BASICS OF CORE CARBON',
    questions: [
      {
        question: 'What is CORE Carbon?',
        answer1:
          'It is an online platform designed to create, enroll, and monitor natural climate solutions using cutting-edge technology. CORE Carbon is a one stop workplace for landowners, foresters and other groups who seek to manage their sites from the comfort of their home or office. CORE Carbon allows landowners to earn money for managing their forests sustainably.',
      },
      {
        question: 'Who is CORE Carbon?',
        answer1:
          'The CORE Carbon platform is owned and operated by Finite Carbon, the leader in forest carbon offset development services. Over the past decade, Finite Carbon has delivered more than $800 million to landowners and generated over 86 million forest offsets.',
      },
      {
        question: 'Why is CORE Carbon important?',
        answer1:
          'The benefits of CORE Carbon include access to carbon markets for small family forest landowners and increased supply for corporations seeking to purchase high-quality offsets as part of their net-zero climate action plans, both of which lead to restored forests, clean water, and resilient ecosystems.',
      },
      {
        question: 'What site opportunities are available on CORE Carbon?',
        answer1:
          'Our first site type available will be forest carbon offsets from non-industrial private forestlands. The methodology is designed to quantify greenhouse gas (GHG) emission reductions and removals resulting from improved forest management activities on clustered ownerships of non-industrial private forestlands ranging from 40 to 5,000 forested acres. Additional site opportunities will be shown on the platform as they become available. Using the CORE Carbon platform, participants can learn about and compare the different opportunities and evaluate if they want to participate.',
      },
      {
        question: 'What is a carbon offset?',
        answer1:
          'The American Carbon Registry (ACR) brands the premium verified emissions reductions (VERs) issued against ACR standards as Emission Reduction Tonnes (ERTs). One (1) ERT represents the reduction or removal from the atmosphere equivalent to one metric tonne of carbon dioxide. ',
        answer2:
          'By participating in the voluntary carbon market, you are selling carbon offsets.  The unit of carbon trade is the "carbon offset" (aka "credit") = 1 mtCO2e (metric tonne of carbon dioxide equivalent).',
      },
      {
        question: 'Who makes the rules for the protocols?',
        answer2:
          'The Small Landowner site opportunities currently available on CORE Carbon use a carbon accounting methodology adopted by the American Carbon Registry (ACR). ACR is the leading carbon offset program founded in 1996 as the first private voluntary greenhouse gas (GHG) registry in the world and operates in the voluntary and regulated carbon markets. ACR has over two decades of experience in the development of environmentally rigorous, science-based offset methodologies, as well as operational experience in the oversight of offset site verification, registration, offset issuance, and retirement reporting through its online registry system.',
      },
      {
        question: 'Why can I not get paid for all the carbon I am growing?',
        answer1:
          'A forest carbon offset is created when a landowner’s voluntary actions result in the capture or the prevention of carbon dioxide from being released into the atmosphere. This additional carbon volume, which is quantified and assigned a value, is referred to as a carbon offset or a credit.',
        answer2:
          'Your forest management activities are already helping capture carbon and provide many more ecosystem services to society. In an ideal world you would be compensated for these services, but we are working slowly toward a world where those actions are given a market value. Additionality is part of the complex carbon accounting process.',
      },
    ],
  },
  {
    heading: 'WHAT IS THE SMALL LANDOWNER PROJECT OPPORTUNITY?',
    questions: [
      {
        question: 'What type of site opportunity is the Small Landowner program?',
        answer1:
          'The small landowner site opportunities are sites that aggregate small, non-industrial private forest landowners and pay them to maintain or increase the forest carbon stocks in their trees over the site life.',
      },
      {
        question: 'Who is eligible?',
        answer1:
          'CORE Carbon is for landowners who own between 40 and 5,000 forested acres within the United States. The area can be made up of several forest stands or areas. Participating sites must be under non-industrial private ownership and located in the United States. Sites with legal restrictions that prevent timber harvesting are not eligible.',
      },
      {
        question: 'Do I have to enroll my entire ownership?',
        answer1:
          'No. Landowners who qualify can enroll all or just a portion of their forested ownership; however, they must enroll at least 40 acres and no more than 5,000 acres.',
      },
      {
        question: 'What kind of compensation can landowners expect?',
        answer1:
          'Payments to landowners will range from $10-$20/acre/year. Payments will be disbursed at a regular interval, between monthly and annually, depending on the terms of your enrollment agreement. Payments are made through secure and convenient direct deposit.',
      },
      {
        question: 'What is the commitment term?',
        answer1:
          'Our first site opportunity for small landowners is deferred harvesting. The minimum site term is forty (40) years and is split into two crediting periods. Landowners must defer all commercial harvesting for the first crediting period of 20 years and have the option to continue deferral for the second crediting period.',
      },
      {
        question: 'What is a crediting period?',
        answer1:
          'A crediting period is the finite length of time for which the baseline scenario is valid and which a group of sites can generate offsets against its baseline. A crediting period consists of a 20-year duration. By splitting the contract term into two crediting periods, CORE Carbon affords landowners more flexibility in the way they manage their forests.',
      },
      {
        question: 'What if a site chooses not to renew its initial crediting period?',
        answer1:
          'If a site transitions to a "non-crediting site", the landowner forfeits payments for this period in exchange for the ability to engage in activities, such as commercial harvesting, provided their timber volume does not fall below the baseline that they ended the first crediting period with. Landowners must continue to complete reporting and notify Finite Carbon of any changes or disturbances for the duration of their enrollment agreement.',
      },
      {
        question: 'What happens if there is a breach or a request for early termination of the contract?',
        answer1:
          'An intentional breach of enrollment agreement or a landowner request to exit early can result in enrollment agreement termination and repayment of all the monies already paid to the landowner.',
      },
      {
        question: 'Is there any cost to enroll in the program?',
        answer1:
          'Participation and enrollment in CORE Carbon is at no-cost to the landowner – there is no charge for a landowner to create an account and enroll any number of carbon offset sites.',
      },
      {
        question: 'Do I need to pay for an inventory of my forests?',
        answer1:
          'No. There are no hidden costs or upfront fees. Finite Carbon leverages technology and many years of experience to create a forest inventory of your property.',
      },
      {
        question: 'Do I need to find a buyer for my offset credits?',
        answer1:
          'No. Finite Carbon is an end-to-end carbon developer which means we will find a buyer for the credits. The buyers are corporate entities looking to meet their emission reduction goals.',
      },
      {
        question: 'How is CORE Carbon different from cost-share programs?',
        answer1:
          'This is not a government program. It is a private market payment in exchange for carbon sequestration.',
      },
      {
        question: 'Can my forester help me start a carbon offset site?',
        answer1:
          'Yes. After a landowner creates their account, they can authorize a forester or family member to access the site on their behalf. This is called a delegate user in CORE Carbon.',
      },
      {
        question: 'What are the tax implications? Is the annual payment ordinary income?',
        answer1:
          'Finite Carbon cannot give you specific tax advice, please consult a tax professional to understand your specific situation. In general, the income will be ordinary.',
      },
    ],
  },
  {
    heading: 'WHAT ARE THE LANDOWNER COMMITMENTS? ',
    questions: [
      {
        question: 'What is the commitment(site term)?',
        answer1:
          'The minimal site term is forty (40) years. Landowners must suspend any commercial harvesting for the initial 20 years. At the end of the initial 20-year term, participating landowners can then choose to maintain their timber volume at that same level but may harvest growth or commit to defer harvest for another 20-year term.',
      },
      {
        question:
          'What are the allowances for personal firewood and small clearings? What if salvage cutting or disease mitigation is required?',
        answer1:
          'Landowners maintain the one-time option to create a small clearing for food plots, cabin sites, widened roads, turn around areas or the equivalent and the ability to cut firewood for personal use during the site life, although clearing trees for these permitted purposes may slightly reduce carbon payments over time. Landowners may not flood or drain forest areas during the site term. We do not restrict recreational uses in the enrolled site area.',
      },
      {
        question: 'Can I still harvest timber?',
        answer1:
          'Commercial timber harvests are prohibited under the Small Non-Industrial Private Forestlands Methodology, there is a subset of permitted management activities that must be monitored and accounted for in the site scenario. These include firewood harvesting for personal use, the one-time installation of small clearings, and salvage cutting and preventative silvicultural treatments to manage pest and disease outbreaks.',
      },
      {
        question: 'Can I carry out other commercial activities on the land?',
        answer1:
          'Aside from restrictions on the commercial harvesting of timber in enrolled stands, other commercial activities not affecting timber are permitted. Examples of such activities include leasing hunting rights, collection of non-timber forest products, or use of cultural sites.',
      },
      {
        question: 'What if there is a catastrophic event, such as a tornado or another event, I have no control over?',
        answer1:
          'If this occurs, log in to your CORE Carbon account or contact us via email as soon as possible. Take pictures if it is feasible and safe. Unintentional reversals of carbon stocks include, but are not limited to, natural events such as fires, hurricanes, floods, etc. The CORE Carbon platform is prepared to deal with these events and provides the landowner with the ability to report such disturbances.',
      },
      {
        question: 'Are you requiring the tax unit to be enrolled? Or can you subdivide?',
        answer1:
          'The portions of your property that you choose to enroll in are entirely up to you, provided they add up to at least 40 acres.',
      },
      {
        question: 'What are the reporting requirements?',
        answer1:
          'Landowners are required to report and periodically attest to the site status. Landowner reports must be submitted in order to receive payments. We will notify you via email when your reporting period is open.',
      },
      {
        question: 'What if I have a mortgage loan on the property?',
        answer1: 'Please consult with your lender. Enrolling in CORE Carbon does not require a deed or easement.   ',
      },
      {
        question: 'How does the landowner agreement differ from an easement or deed restriction?',
        answer1:
          'The landowner contract or enrollment agreement is valid between the parties without being recorded. For legal advice please seek the counsel of an attorney.',
      },
    ],
  },
  {
    heading: 'PRIVACY AND SECURITY OF PERSONAL INFORMATION ',
    questions: [
      {
        question: 'Is my information safe?',
        answer1:
          'Finite Carbon is committed to security. Your payments are processed through Stripe™, an industry standard that processes online payments for numerous well-known companies.  CORE Carbon does not store your bank information.',
        answer2:
          'Identity verification is done using Trulioo and agreement enrollment signatures are completed using Docusign. Additional information about these services can be found at <a href="https://www.stripe.com" target="_blank" rel="noopener noreferrer">Stripe.com</a>, <a href="https://www.trulioo.com" target="_blank" rel="noopener noreferrer">Trulioo.com</a>, and <a href="https://www.docusign.com" target="_blank" rel="noopener noreferrer">Docusign.com</a>.',
      },
      {
        question: 'What if I hit the wrong button when using the online tool? Can I back out?',
        answer1:
          'You will have multiple opportunities to review your site before submission and can even modify or withdraw your site after it is reviewed and accepted.  Until you sign an enrollment agreement there is no commitment for you to proceed.',
      },
      {
        question: 'How do you know if someone is posing as me?',
        answer1:
          'We use an independent industry leading identity verification service and verify property ownership for all submitted acreage.',
      },
      {
        question: 'Is personal information that I provide protected?',
        answer1:
          'Personal information stored in CORE Carbon is encrypted using industry standard best practices.  Any data exchange with required third-party service providers employs best security practices to protect your personal information.',
      },
    ],
  },
];
