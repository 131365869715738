<div mat-dialog-title>
  <div>OVERLAPPING STANDS</div>
  <div class="close-icon-container" (click)="buttonClick(false)">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p>Selected stands cannot overlap. Would you like to auto adjust them to eliminate overlap?</p>
  <p class="line dark">Selecting “Yes, Adjust” will combine all overlapping stands into one.</p>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button color="primary" (click)="buttonClick(false)">No, Don't Adjust</button>
  <button data-cy="overlapping-stand-yes" mat-raised-button color="primary" (click)="buttonClick(true)">
    Yes, Adjust
  </button>
</div>
