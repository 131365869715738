import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  previousOwner: string;
  newOwner: string;
  siteName: string;
}

@Component({
  selector: 'fc-confirm-owner-dialog',
  templateUrl: './confirm-owner-dialog.component.html',
  styleUrls: ['./confirm-owner-dialog.component.scss'],
})
export class ConfirmOwnerDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmOwnerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
