import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { GisService } from 'src/app/core/api/gis.service';

import * as ParcelActions from './parcel.actions';

import { AppState } from '../index';

@Injectable()
export class ParcelEffects {
  loadParcels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ParcelActions.loadParcels),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        EMPTY.pipe(
          map(data => ParcelActions.loadParcelsSuccess({ data })),
          catchError(error => of(ParcelActions.loadParcelsFailure({ error })))
        )
      )
    );
  });

  selectAllStands$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ParcelActions.selectAllStands),
      concatMap(action => {
        return this.gisService.selectAllStands(action.data.regionId, action.data.siteId).pipe(
          map(data => ParcelActions.selectAllStandsSuccess({ data })),
          catchError(error => of(ParcelActions.selectAllStandsFailure({ error })))
        );
      })
    );
  });

  constructor(private actions$: Actions, private gisService: GisService, readonly store: Store<AppState>) {}
}
