import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import * as SiteActions from './site.actions';

import { Cohort, Site } from '../../shared/models';

export const siteFeatureKey = 'site';

export interface SiteState {
  availableSites: Site[];
  activeSite: Site;
  lastSave: Date;
  error: string;
  lastSavePassedValidation: boolean;
  availableCohorts: Cohort[];
  readyForSubmit: boolean;
}

export const initialState: SiteState = {
  availableSites: null,
  activeSite: null,
  lastSave: null,
  lastSavePassedValidation: null,
  error: null,
  availableCohorts: [],
  readyForSubmit: false,
};

const siteReducer = createReducer(
  initialState,
  on(SiteActions.getAvailableSitesSuccess, (state, data) => {
    return {
      ...state,
      error: null,
      availableSites: data.data,
    };
  }),
  on(SiteActions.setActiveSiteSuccess, (state, data) => ({
    ...state,
    error: null,
    activeSite: data.data,
  })),
  on(SiteActions.setActiveSiteSuccessWithAvailableCohorts, (state, data) => ({
    ...state,
    error: null,
    activeSite: data.activeSite,
    availableCohorts: data.availableCohorts,
  })),
  on(SiteActions.setLastSaveIsValid, (state, data) => ({
    ...state,
    lastSavePassedValidation: data.isValid,
  })),
  on(SiteActions.clearActiveSite, state => {
    return {
      ...state,
      error: null,
      activeSite: null,
      lastSave: null,
      lastSavePassedValidation: null,
      availableCohorts: [],
    };
  }),
  on(SiteActions.setActiveSiteSelectedParcels, (state, data) => {
    const newParcels = data.parcels;
    const activeSite: Site = cloneDeep(state.activeSite);
    activeSite.versions[0].parcels = newParcels;

    return {
      ...state,
      error: null,
      activeSite,
    };
  }),
  on(SiteActions.setSelectedCohortBuyerAndNavigate, (state, data) => {
    return {
      ...state,
      error: null,
      activeSite: data.data,
    };
  }),
  on(SiteActions.setAvailableCohorts, (state, data) => {
    return {
      ...state,
      error: null,
      availableCohorts: data.cohorts,
    };
  }),
  on(SiteActions.clearAvailableCohorts, state => {
    return {
      ...state,
      error: null,
      availableCohorts: [],
    };
  }),
  on(SiteActions.createSite, SiteActions.updateSite, SiteActions.deleteSite, state => ({
    ...state,
    error: null,
  })),
  on(SiteActions.resetReadyForSubmit, state => ({
    ...state,
    readyForSubmit: false,
  })),
  on(
    SiteActions.createSiteSuccess,
    SiteActions.updateSelectParcelSiteSuccess,
    SiteActions.updateSubmitEligibilitySiteSuccessAndNavigate,
    SiteActions.updateSiteSuccess,
    SiteActions.submitSiteSuccess,
    (state, data) => {
      const availableSites = cloneDeep(state.availableSites || []);
      const idx = availableSites.findIndex(p => p.id === data.data.id);
      if (idx > -1) {
        availableSites[idx] = data.data;
      }
      return {
        ...state,
        error: null,
        availableSites,
        activeSite: data.data,
        lastSave: new Date(),
      };
    }
  ),
  on(SiteActions.updateNewImprovedSiteSuccess, (state, data) => {
    const availableSites = cloneDeep(state.availableSites || []);
    const idx = availableSites.findIndex(p => p.id === data.site.id);
    if (idx > -1) {
      availableSites[idx] = data.site;
    }
    return {
      ...state,
      error: null,
      availableSites,
      activeSite: data.site,
      readyForSubmit: data.readyForSubmit,
      lastSave: new Date(),
    };
  }),
  on(SiteActions.updateSelectParcelSiteSuccess, (state, data) => {
    const availableSites = cloneDeep(state.availableSites || []);
    const idx = availableSites.findIndex(p => p.id === data.data.id);
    if (idx > -1) {
      availableSites[idx] = data.data;
    }
    return {
      ...state,
      error: null,
      availableSites,
      activeSite: data.data,
      lastSave: new Date(),
    };
  }),
  on(SiteActions.sendContractSuccess, (state, data) => {
    const availableSites = cloneDeep(state.availableSites || []);
    const idx = availableSites.findIndex(p => p.id === data.data.id);
    if (idx > -1) {
      availableSites[idx] = data.data;
    }
    return {
      ...state,
      error: null,
      availableSites,
      lastSave: new Date(),
    };
  }),
  on(SiteActions.deleteSiteSuccess, (state, data) => {
    if (data && data.data) {
      const availableSites = cloneDeep(state.availableSites);
      if (availableSites) {
        const idx = availableSites.findIndex(p => p.id === data.data.id);
        if (idx !== -1) {
          availableSites.splice(idx, 1);
        }
      }
      return {
        ...state,
        availableSites,
      };
    }
    return state;
  }),
  on(SiteActions.getSiteSuccess, (state, data) => {
    const newState = cloneDeep(state);
    if (!newState.availableSites) {
      newState.availableSites = [data.data];
    } else {
      const idx = newState.availableSites.findIndex(p => p.id === data.data.id);
      if (idx > -1) {
        newState.availableSites[idx] = data.data;
      } else {
        newState.availableSites.push(data.data);
      }
    }
    return newState;
  }),
  on(
    SiteActions.getSiteFailure,
    SiteActions.getAvailableSitesFailure,
    SiteActions.createSiteFailure,
    SiteActions.setActiveSiteFailure,
    SiteActions.updateSiteFailure,
    SiteActions.deleteSiteFailure,
    SiteActions.submitSiteFailure,
    SiteActions.sendContractFailure,
    (state, data) => {
      let error = 'There was an error';
      if (data.error) {
        if (data.error.error && data.error.error.detail) {
          error = data.error.error.detail;
        } else if (data.error.message) {
          error = data.error.message;
        }
      }
      return {
        ...state,
        error,
      };
    }
  ),
  on(SiteActions.clearAllSites, SiteActions.clearAllSitesAndReturnToDash, () => {
    return initialState;
  })
);

export function reducer(state: SiteState | undefined, action: Action) {
  return siteReducer(state, action);
}
