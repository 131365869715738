import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Buyer } from '../../shared/models';
import { deserialize, serialize } from '../../shared/models/base-helper';

@Injectable({
  providedIn: 'root',
})
export class BuyerService {
  apiUrl = environment.apiUrl + '/buyers/';

  constructor(readonly http: HttpClient) {}

  getAllBuyers(): Observable<Buyer[]> {
    return this.http
      .get<any[]>(this.apiUrl)
      .pipe(map((buyers: any[]) => buyers.map(buyer => deserialize(buyer, Buyer))));
  }

  getAllBasicBuyers(): Observable<Buyer[]> {
    return this.http
      .get<any[]>(`${this.apiUrl}minimal`)
      .pipe(map((buyers: any[]) => buyers.map(buyer => deserialize(buyer, Buyer))));
  }

  getBuyer(referrerCode: number): Observable<Buyer> {
    return this.http
      .get<any>(`${this.apiUrl}referral/${referrerCode}`)
      .pipe(map((buyer: any) => deserialize(buyer, Buyer)));
  }

  getBuyerById(id: number): Observable<Buyer> {
    return this.http.get<any>(`${this.apiUrl}${id}`).pipe(map((buyer: any) => deserialize(buyer, Buyer)));
  }

  saveBuyer(buyer: Buyer): Observable<Buyer> {
    const body = serialize(buyer);
    return this.http.put<any>(`${this.apiUrl}${buyer.id}`, body).pipe(map((buyer: any) => deserialize(buyer, Buyer)));
  }

  static getOpenAssetURL(buyerName: string, fileName: string): string {
    return `${environment.openAssetBaseUrl}/buyers/${buyerName}/${fileName}`;
  }

  listWelcomeDocs(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}${id}/welcome-docs`).pipe(map(result => result));
  }

  deleteWelcomeDoc(id: number, name: string): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.apiUrl}${id}/welcome-docs/remove`, { name })
      .pipe(map((result: any) => result['deleted']));
  }

  uploadWelcomeDoc(id: number, file: File): Promise<any> {
    const fr = new FileReader();
    const fileReaderPromise = new Promise<any>(
      resolve =>
        (fr.onload = (): void => {
          if (fr.result) {
            const body = {
              data: fr.result,
              name: file.name,
              content_type: file.type,
            };
            this.http
              .post<any>(`${this.apiUrl}${id}/welcome-docs`, body)
              .pipe(map((response: any): any => response))
              .subscribe((newDoc: any) => {
                resolve(newDoc);
              });
          }
        })
    );
    fr.readAsDataURL(file);
    return fileReaderPromise;
  }
}
