import { createAction, props } from '@ngrx/store';

import { Cohort, Parcel, Site } from '../../shared/models';

export const getAvailableSites = createAction('[Site] Get Available Sites');

export const getUserAvailableSites = createAction('[Site] Get User Available Sites', props<{ userId: number }>());

export const getAvailableSitesSuccess = createAction('[Site] Get Available Sites Success', props<{ data: any }>());

export const getAvailableSitesFailure = createAction('[Site] Get Available Sites Failure', props<{ error: any }>());

export const setActiveSite = createAction('[Site] Set Active Site', props<{ id: number }>());

export const setActiveSiteSuccess = createAction('[Site] Set Active Site Success', props<{ data: Site }>());

export const setActiveSiteSuccessWithAvailableCohorts = createAction(
  '[Site] Set Active Site Success With Available Cohorts',
  props<{ activeSite: Site; availableCohorts: Cohort[] }>()
);

export const setActiveSiteFailure = createAction('[Site] Set Active Site Failure', props<{ error: any }>());

export const clearActiveSite = createAction('[Site] Clear Active Site');

export const createSite = createAction('[Site] Create New Site', props<{ site: any }>());

export const createSiteSuccess = createAction(
  '[Site - Start a Site] Create Initial Site Success',
  props<{ data: Site }>()
);

export const updateNewImprovedSiteSuccess = createAction(
  '[Site - New Improved Update] Update success',
  props<{ site: Site; readyForSubmit: boolean }>()
);

export const setSiteAndReadyForSubmit = createAction('[Site] Set site and ready for submit', props<{ data: Site }>());

export const updateSelectParcelSiteSuccess = createAction(
  '[Site - Update Select Parcel Site Success',
  props<{ data: Site }>()
);

export const updateSubmitEligibilitySiteSuccessAndNavigate = createAction(
  '[Site - Update Submit Eligibility Site Success and Navigate',
  props<{ data: Site }>()
);

export const updateSubmitDocumentationSiteSuccessAndNavigate = createAction(
  '[Site - Update Submit Documentation Site Success and Navigate',
  props<{ data: Site }>()
);
export const setSelectedCohortBuyerAndNavigate = createAction(
  '[Site - Update Selected Cohort/Buyer and Navigate',
  props<{ data: Site }>()
);

export const resetReadyForSubmit = createAction('[Site] Set Ready to Submit to false');

export const createSiteFailure = createAction('[Site] Create New Site Failure', props<{ error: any }>());

export const updateSite = createAction(
  '[Site] Update Site',
  props<{
    id: number;
    site: any | Site;
    eligibilityReport?: any;
  }>()
);

export const updateSiteStep = createAction(
  '[Site] Update Site Step',
  props<{
    id: number;
    step: string;
  }>()
);
export const updateSiteStepSuccess = createAction('[Site] Update Site Step Success', props<{ data: any }>());

export const updateSiteStepFailure = createAction('[Site] Update Site Step Failure', props<{ error: any }>());
export const updateSiteSuccess = createAction('[Site] Update Site Success', props<{ data: any }>());

export const updateSiteFailure = createAction('[Site] Update Site Failure', props<{ error: any }>());

export const deleteSite = createAction('[Site] Delete Site', props<{ id: number }>());

export const deleteSiteSuccess = createAction('[Site] Delete Site Success', props<{ data: any }>());

export const deleteSiteFailure = createAction('[Site] Delete Site Failure', props<{ error: any }>());

export const submitSiteSuccess = createAction('[Site] Submit Site Success', props<{ data: any }>());

export const submitSiteFailure = createAction('[Site] Submit Site Failure', props<{ error: any }>());

export const getSite = createAction('[Site] Get Specific Site', props<{ id: number }>());

export const getSiteSuccess = createAction('[Site] Get Specific Site Success', props<{ data: any }>());

export const getSiteFailure = createAction('[Site] Get Specific Site Failure', props<{ error: any }>());

export const clearAllSites = createAction('[Site] Clear All Sites');

export const setLastSaveIsValid = createAction('[Site] Set Last Save Is Valid', props<{ isValid: boolean }>());

export const sendContract = createAction('[Site] Send Contract For A Site', props<{ identifier: string }>());

export const sendContractSuccess = createAction('[Site] Send Contract For A Site Success', props<{ data: any }>());

export const sendContractFailure = createAction('[Site] Send Contract For A Site Failure', props<{ error: any }>());

export const setActiveSiteSelectedParcels = createAction(
  '[Site] Set Selected Parcels For Active Site And Dont Modify Active Site',
  props<{ parcels: Parcel[] }>()
);

export const setActiveSiteParcelsAndAvailableCohorts = createAction(
  '[Site] Set Active Site and Selected Parcels In One Call',
  props<{ siteId: number; parcels: Parcel[]; availableCohorts: Cohort[] }>()
);

export const setAvailableCohorts = createAction('[Site] Set Available Cohorts', props<{ cohorts: Cohort[] }>());

export const clearAvailableCohorts = createAction('[Site] Clear Available Cohorts');

export const clearAllSitesAndReturnToDash = createAction('[Site] Clear All Sites And Return To Dash');
