import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { NotificationService } from 'src/app/core/api/notification.service';

import * as NotificationActions from './notification.actions';

@Injectable()
export class NotificationEffects {
  getAdminNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationActions.getAdminNotifications),
      concatMap(action => {
        return this.notificationsService.getAdminNotifications().pipe(
          map(data => NotificationActions.getAdminNotificationsSuccess({ data })),
          catchError(error => of(NotificationActions.getAdminNotificationsFailure({ error })))
        );
      })
    );
  });

  constructor(private actions$: Actions, readonly notificationsService: NotificationService) {}
}
