import { Base } from './base.model';
import { Region } from './region.model';

import { Type } from '../decorators/type.decorator';

export class Program extends Base {
  public name: string;
  public restrictionLevel: number;
  @Type(Region) public regions: Region[];
  public isNational: boolean;
  public isActive: boolean;

  inRegion(id: number): boolean {
    return this.isNational || ((this.regions || []) && this.regions.find(r => r.id === id) !== null);
  }
}
