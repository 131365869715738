export enum StatusCategoryEnum {
  // Note: These are just constants that are used in the frontend code
  // All status values are stored in the database as strings
  // This list DOES NOT include all possible status values
  PENDING = 'Pending',
  REVIEW = 'Review',
  APPROVED = 'Approved',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
