import { Base } from './base.model';
import { User } from './user.model';

import { Type } from '../decorators/type.decorator';

export class Account extends Base {
  public isActive: boolean;
  public userId: number;
  public startDate: Date;
  public endDate: Date;
  @Type(User) public user: User;
}
