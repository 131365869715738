import { AdjustmentType } from './adjustment-type.model';
import { Base } from './base.model';

import { Type } from '../decorators/type.decorator';

export class Adjustment extends Base {
  public value: number;
  public paymentPeriodId: number;
  @Type(AdjustmentType) public adjustmentType: AdjustmentType;
  public adjustmentTypeId: number;
}
