import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { concat } from 'lodash';
import { skipWhile } from 'rxjs/operators';

import { SiteService } from '../../../core/api/site.service';
import { UserService } from '../../../core/api/user.service';
import { FormatHelperService } from '../../../core/helper/format-helper.service';
import { SiteHelperService } from '../../../core/helper/site.service';
import * as fromApp from '../../../store';
import { setMapControlDrawMode } from '../../../store/map-control/map-control.actions';
import { selectActiveSite } from '../../../store/site/site.selectors';
import { DialogEditParcelDataComponent } from '../../dialogs/dialog-edit-parcel-data/dialog-edit-parcel-data.component';
import { Parcel, Program, Site } from '../../models';

@Component({
  selector: 'fc-site-detail-information',
  templateUrl: './site-detail-information.component.html',
  styleUrls: ['./site-detail-information.component.scss'],
})
export class SiteDetailInformationComponent implements OnInit {
  site: Site;
  programs: Program[];
  parcelData: Parcel[] = [];
  destroyRef = inject(DestroyRef);

  @Input()
  isAdmin = false;

  @Input()
  isSuperAdmin = false;

  @Input()
  isLimitedAdmin = false;

  constructor(
    readonly store: Store<fromApp.AppState>,
    private router: Router,
    public dialog: MatDialog,
    readonly userService: UserService,
    readonly siteService: SiteService,
    readonly siteHelper: SiteHelperService,
    readonly formatHelper: FormatHelperService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.store
      .select(selectActiveSite)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        skipWhile(site => site === null)
      )
      .subscribe(site => {
        this.site = site;
        this.parcelData = this.site.versions[0].parcels;

        this.programs = this.site.programs;
        if (this.site.otherPrograms) {
          this.programs = concat(
            this.programs,
            this.site.otherPrograms.split(',').map(
              o =>
                new Program({
                  name: o.trim(),
                  restrictionLevel: -1,
                  isActive: true,
                })
            )
          );
        }
      });
  }

  doOpenSite(): void {
    this.store.dispatch(setMapControlDrawMode({ active: false }));
    const url =
      this.isAdmin || this.isLimitedAdmin ? `admin/${this.site.id}/map-site` : `site/${this.site.id}/map-site`;
    this.router.navigateByUrl(url);
  }

  getBooleanString(value: any): string {
    if (value === undefined || value == null) return '';
    if (typeof value === 'string') {
      if (value.toLowerCase() !== 'true' && value.toLowerCase() !== 'false') {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      } else {
        value = value.toLowerCase() === 'true';
      }
    }
    return value ? 'Yes' : 'No';
  }

  flagQuestion(question: string): boolean {
    switch (question) {
      case 'hasRestrictions':
        return this.site.hasRestrictions;
      case 'programs':
        return this.site.programs && this.site.programs.length > 0;
      case 'hasLeakage':
        return this.site.periodicReports[0]?.activityLeakage;
      default:
        return false;
    }
  }

  getQuestionClass(question: string): string {
    return `indented eligibility-question ${this.flagQuestion(question) && this.isAdmin ? 'flagged' : ''}`;
  }

  editParcel(parcel: Parcel) {
    this.dialog.open(DialogEditParcelDataComponent, {
      data: {
        parcel: parcel,
        siteId: this.site.id,
      },
    });
  }
}
