<div mat-dialog-title>
  <div>COMING SOON!</div>
  <div class="close-icon-container" (click)="onNoClick()">
    <fa-icon [icon]="['far', 'xmark']" class="gray"></fa-icon>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <p class="line-1">We’re sorry, we are not yet available in your area, but may be coming there soon.</p>
  <p class="line-2">Would you like us to let you know when your area is eligible for our program?</p>
  <form [formGroup]="comingSoonForm" class="fields">
    <mat-form-field class="w-100-1">
      <mat-label>Email</mat-label>
      <input matInput id="email" formControlName="email" required />
      <mat-error>{{ emailError }}</mat-error>
    </mat-form-field>
    <input class="hidden-check" tabindex="-1" formControlName="hiddenCheck" autocomplete="current-password" />
  </form>
</div>
<div mat-dialog-actions>
  <button
    [disabled]="!comingSoonForm.valid"
    [mat-dialog-close]="comingSoonForm.value"
    color="primary"
    mat-raised-button>
    Let Me Know When I'm Eligible
  </button>
</div>
