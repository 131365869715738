import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

import * as SiteStatusActions from './site-status.actions';

import { SiteService } from '../../core/api/site.service';

@Injectable()
export class SiteStatusEffects {
  getSiteStatuses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteStatusActions.getSiteStatuses),
      concatMap(_action => {
        return this.siteService.getAllSiteStatuses().pipe(
          map(data => SiteStatusActions.getSiteStatusesSuccess({ data })),
          catchError(error => of(SiteStatusActions.getSiteStatusesFailure({ error })))
        );
      })
    );
  });

  constructor(readonly actions$: Actions, readonly siteService: SiteService, readonly spinnerService: SpinnerService) {}
}
