import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isEmpty } from 'lodash';

export interface DialogData {}

@Component({
  selector: 'fc-dialog-resubmit-note',
  templateUrl: './dialog-resubmit-note.component.html',
  styleUrls: ['./dialog-resubmit-note.component.scss'],
})
export class DialogResubmitNoteComponent {
  why = '';

  constructor(
    public dialogRef: MatDialogRef<DialogResubmitNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    dialogRef.disableClose = true;
  }

  onYesClick(): void {
    this.dialogRef.close(this.why);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get yesButtonDisabled(): boolean {
    return isEmpty(this.why);
  }
}
