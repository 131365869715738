import { Base } from './base.model';
import { YieldCurve } from './yield-curve.model';

import { Type } from '../decorators/type.decorator';

export class Region extends Base {
  public name: string;
  public startYear: number;
  public startQuarter: number;
  public activeDate: Date;
  public inactiveDate: Date;
  public boundary: string;
  @Type(YieldCurve) public yieldCurves: YieldCurve[];
  public lastUpdated: Date;
  public refreshDate: Date;

  private readonly latestYieldCurveVersion: number;

  versionedYieldCurves(version: number): Array<YieldCurve> {
    return this.yieldCurves.filter(yc => yc.version === version);
  }

  projectionInfoText(year: string, includeMarketPerformance: boolean = false): string {
    return `This is your total projected revenue through ${year}. This value can change based on your responses to periodic reporting${
      includeMarketPerformance ? ' or on market performance' : ''
    }.`;
  }
}
