import { Component, DestroyRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { SiteNoteService } from '../../../core/api/site-note.service';
import { SuccessResponse } from '../../../core/api/site-verification-visits.service';
import * as fromApp from '../../../store';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { createSiteNote, getSiteNotes } from '../../../store/site-note/site-note.actions';
import { DialogYesNoComponent } from '../../dialogs/dialog-yes-no/dialog-yes-no.component';
import { Site, SiteNote, User } from '../../models';

@Component({
  selector: 'fc-site-detail-notes',
  templateUrl: './site-detail-notes.component.html',
  styleUrls: ['./site-detail-notes.component.scss'],
})
export class SiteDetailNotesComponent implements OnInit {
  @ViewChild('matExpansionPanel', { static: false })
  matExpansionPanel: MatExpansionPanel;

  destroyRef = inject(DestroyRef);

  @Input()
  site: Site;

  @Input()
  isAdmin = false;

  @Input()
  isLimitedAdmin = false;

  SiteNotesSubscription: Subscription;
  siteNotes: SiteNote[] = [];
  currentUser: User = null;

  noteForm: FormGroup;

  constructor(
    readonly store: Store<fromApp.AppState>,
    readonly siteNoteService: SiteNoteService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.resetForm();

    this.store.dispatch(getSiteNotes({ siteId: this.site.id }));

    this.store
      .select('siteNote', 'siteNotes')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(siteNotes => {
        if (siteNotes) {
          this.siteNotes = siteNotes.map(d => cloneDeep(d)).sort((a, b) => b.id - a.id);
        }
      });

    this.store
      .select(selectCurrentUser)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(currentUser => (this.currentUser = currentUser));
  }

  resetForm() {
    this.noteForm = new FormGroup({
      note: new FormControl(''), // For the note input field
      hideFromLimitedAdmins: new FormControl(true), // For the checkbox, defaulting to false
    });
  }

  updateVisibility(site: SiteNote) {
    this.siteNoteService
      .updateSiteNote(
        new SiteNote({
          id: site.id,
          hideFromLimitedAdmins: !site.hideFromLimitedAdmins,
        })
      )
      .subscribe(any => {
        this.store.dispatch(getSiteNotes({ siteId: this.site.id }));
      });
  }

  createNewNote() {
    const noteValue = this.noteForm.value.note;
    const isHideFromLimitedAdmins = this.noteForm.value.hideFromLimitedAdmins;
    if (noteValue) {
      this.store.dispatch(
        createSiteNote({
          siteId: this.site.id,
          note: noteValue,
          hideFromLimitedAdmins: isHideFromLimitedAdmins,
        })
      );
    }
    this.resetForm();
  }

  deleteNote(id: number): void {
    const dialogRef = this.dialog.open(DialogYesNoComponent, {
      panelClass: 'fc-yes-no-dialog',
      data: {
        title: 'Confirm Delete!',
        line1: 'Are you sure you want to delete this site note?',
        buttonTextYes: 'Yes, Delete',
        buttonTextNo: 'Cancel',
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result === true) {
        this.siteNoteService.deleteSiteNote(id).subscribe((response: SuccessResponse) => {
          this.store.dispatch(getSiteNotes({ siteId: this.site.id }));
        });
      }
    });
  }
}
