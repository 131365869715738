<div mat-dialog-title>
  <div>{{ data.title }}</div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <ng-container *ngIf="data.line1">
    <p class="line-1" [ngStyle]="data.line1Color && { color: data.line1Color }">
      {{ data.line1 }}
    </p>
  </ng-container>
  <ng-container *ngIf="data.line2">
    <p class="line-2" [ngStyle]="data.line2Color && { color: data.line2Color }">
      {{ data.line2 }}
    </p>
  </ng-container>
  <ng-container *ngIf="data.line3">
    <p class="line-3" [ngStyle]="data.line3Color && { color: data.line3Color }">
      {{ data.line3 }}
    </p>
  </ng-container>
</div>
